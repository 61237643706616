import React from "react";
import { SearchOutlined } from "@ant-design/icons";
// reactstrap components
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import Config from "../../config";
import ListLoading from "../../layouts/ListLoading";
import Project from "../../components/project";
import { Skills } from "../../constants/vars";
import { Menu, Input, Pagination, Button } from "antd";
// import { Button as ReactStrapButton } from "reactstrap";
import { Helmet } from "react-helmet";

class ConfirmEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: null,
      loading: true,
      success: false,
      skip: 1,
      title: "",
      tag: null,
      errors: [],
      projects: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getServicesByTags = async () => {
    this.setState({ loading: true });
    var { tag, skip } = this.state;
    try {
      console.log({ tag, skip });
      var res = await axios.post(Config.hostName + "/api/project/tags", {
        tag,
        skip: (skip - 1) * 10,
      });

      console.log(res.data);
      this.setState({ loading: false, projects: res.data });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  };

  getProjects = async (e) => {
    if (this.state.tag) {
      this.getServicesByTags();
    } else {
      let { title, skip } = this.state;

      this.setState({ loading: true });

      try {
        var res = await axios.post(Config.hostName + "/api/project/search", {
          title,
          skip: (skip - 1) * 10,
        });

        console.log(res.data);
        this.setState({ loading: false, projects: res.data });
      } catch (err) {
        console.log(err);
        this.setState({ loading: false });
      }
    }
  };

  handleSearch = async () => {
    this.setState({ tag: null, skip: 1 }, () => this.getProjects());
  };

  handleTags = async () => {
    this.setState({ title: "", skip: 1 }, () => this.getServicesByTags());
  };

  componentWillMount() {
    this.getProjects();
  }

  onChange = (checked) => {
    this.setState({ loading: !checked });
  };

  render() {
    // if (this.state.loading) return <Spinner />;
    const { loading } = this.state;
    return (
      <>
        <Helmet>
          <title>Zapplabs | Projects</title>
          <meta name="description" content="reactjs nodejs." />
        </Helmet>
        <div className="jumbotron bg-white container pb-3 mb-2">
          <div className=" pb-3 mb-2">
            <section className=" mb-3 bg-white rounded p-3 text-left">
              {/* <div className="h4 title ">Search Projects</div> */}
              <div className="row no-gutters">
                <div
                  className="form-group col-md-12 pr-md-1 p-2"
                  style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                >
                  <Input
                    style={{ border: "none" }}
                    loading={loading}
                    prefix={<SearchOutlined style={{ marginTop: 10 }} />}
                    suffix={
                      <Button
                        size="sm"
                        style={{
                          borderRadius: 10,
                          background: "rgba(54, 106, 239, 0.99)",
                          color: "white",
                        }}
                        onClick={() => this.handleSearch()}
                      >
                        Search Now
                      </Button>
                    }
                    onChange={(event) =>
                      this.setState({ title: event.target.value })
                    }
                  />

                  {/* <Search

                    style={{ border: 'none' }}
                    loading={loading}
                    onSearch={(value) => this.handleSearch()}
                    enterButton="Search now"
                    prefix={<SearchOutlined />}
                    size="large"
                    onChange={(event) =>
                      this.setState({ title: event.target.value })
                    }
                  /> */}
                </div>
              </div>
            </section>
            <div>
              <Menu
                theme="light"
                mode="horizontal"
                defaultSelectedKeys={[this.state.tag]}
                style={{ zIndex: 999 }}
              >
                <Menu.Item
                  key="All"
                  onClick={() => {
                    this.setState({ tag: null, skip: 1 }, () =>
                      this.getProjects()
                    );
                  }}
                >
                  All
                </Menu.Item>
                {Skills.map((tech) => (
                  <Menu.Item
                    key={tech}
                    onClick={() => {
                      this.setState({ tag: tech, skip: 1 }, () =>
                        this.handleTags()
                      );
                    }}
                    type="link"
                  >
                    {tech}
                  </Menu.Item>
                ))}
              </Menu>
            </div>
          </div>

          <div className={"text-center"}>
            <Pagination
              current={this.state.skip}
              onChange={(page) => {
                this.setState({ skip: page }, () => this.getProjects());
              }}
              simple
              defaultCurrent={this.state.skip}
              total={500}
            />
          </div>
          {loading ? (
            <ListLoading />
          ) : (
            <div className="row pt-3">
              {this.state.projects.length === 0 ? (
                <div className="h5 title p-3">No Data</div>
              ) : (
                this.state.projects.map((profile) => (
                  <div className="col-md-3 col-sm-6">
                    <Project
                      getServicesByTags={this.getServicesByTags}
                      project={profile}
                      user={profile.user}
                      profile={profile._id}
                    />
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

ConfirmEmail.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(ConfirmEmail);
