import React from "react";
import SimpleReactValidator from "simple-react-validator";
import Config from "../../config";
import Dropzone from "react-dropzone";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import { setAlert } from "../../actions/alert.actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentProfile } from "../../actions/profile";
import { Link } from "react-router-dom";
import Alert from "../../layouts/Alert";
import Spinner from "../../layouts/Spinner";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      isEditable: false,
      password: "",
      phone: "",
      isUpdating: false,
      isLoading: true,
      youtube: "",
      style: {},
    };
    this.validator = new SimpleReactValidator();
  }

  async componentWillMount() {
    this.props.profile &&
      this.props.profile.about &&
      (await this.setState({
        resume: this.props.profile.about.resume,
        workType: this.props.profile.about.workType,
        currentSalary: this.props.profile.about.currentSalary,
        expectedSalary: this.props.profile.about.expectedSalary,
        noticePeriod: this.props.profile.about.noticePeriod,
        workLocation: this.props.profile.about.workLocation,
      }));
  }

  async onDrop({ files, name }) {
    let formData = new FormData();
    formData.append("images", files[0]);

    await axios
      .post(Config.hostName + "/api/profile/images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (res) => {
        this.setState({ resume: res.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  async handleSubmitButtonClick(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      // get form data from react state object
      let {
        resume,
        workType,
        currentSalary,
        expectedSalary,
        noticePeriod,
        workLocation,
      } = this.state;

      let body = {
        userid: this.props.userid,
        resume,
        workType,
        currentSalary,
        expectedSalary,
        noticePeriod,
        workLocation,
      };
      //make user signup request
      await this.setState({ isUpdating: true });
      console.log(body);
      try {
        const res = await axios.put(
          Config.hostName + "/api/profile/about",
          body
        );
        console.log(res.data);
        this.props.getProfile();

        await this.setState({ isUpdating: false, isEditable: false });
      } catch (err) {
        const errors =
          err.response && err.response.data && err.response.data.errors;
        await this.setState({ isUpdating: false });
        this.props.getProfile();
        if (errors) {
          errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }
    return (
      <>
        <div
          className="content bg-white shadow"
          style={{
            marginTop: 28,
          }}
        >
          <div className="p-3 ">
            {this.state.isEditable === false && (
              <Row>
                <Col md="10" xs="7">
                  About
                </Col>
                <Col md="2" xs="4" className="text-right">
                  {this.props.profile &&
                    this.props.auth.user.id === this.props.profile.user._id && (
                      <Link
                        style={{ color: "blue" }}
                        onClick={async (e) =>
                          await this.setState({ isEditable: true })
                        }
                      >
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/img/landing-page/editicon.svg"
                          }
                          width="20%"
                          className="rounded"
                        />
                        {/* <i className="fa fa-edit" /> Edit */}
                      </Link>
                    )}
                </Col>
              </Row>
            )}
          </div>
          <Row>
            {this.state.isEditable === false && (
              <Col md="12">
                <div className="border p-4">
                  <CardBody>
                    <div className="row ">
                      <div className="col-xs-12">
                        <div>
                          <p>
                            {this.state.workType && (
                              <div className="row">
                                <div className="col-md-3 col-6 text-secondary mt-2 ">
                                  Work Type
                                </div>
                                <div className="col-md-9 col-6 mt-2">
                                  <span>{this.state.workType}</span>
                                </div>
                                <div className="col-md-3 col-6 text-secondary mt-2">
                                  Work Location
                                </div>
                                <div className="col-md-9 col-6 mt-2">
                                  <span>{this.state.workLocation}</span>
                                </div>
                                <div className="col-md-3 col-6 text-secondary mt-2">
                                  Notice Period
                                </div>
                                <div className="col-md-9 col-6 mt-2">
                                  <span>{this.state.noticePeriod}</span>
                                </div>
                                <div className="col-md-3 col-6 text-secondary mt-2">
                                  Current Salary
                                </div>
                                <div className="col-md-9 col-6 mt-2">
                                  <span>
                                    {parseInt(
                                      this.state.currentSalary
                                    ).toLocaleString()}{" "}
                                    K
                                  </span>
                                </div>
                                <div className="col-md-3 col-6 text-secondary mt-2">
                                  Expected Salary
                                </div>
                                <div className="col-md-9 col-6 mt-2">
                                  <span>
                                    {parseInt(
                                      this.state.expectedSalary
                                    ).toLocaleString()}{" "}
                                    K
                                  </span>
                                </div>
                                {this.state.resume && (
                                  <>
                                    <div className="col-md-3 col-6 text-secondary mt-2">
                                      Resume
                                    </div>
                                    <div className="col-md-9 col-6 mt-2">
                                      <a
                                        href={this.state.resume}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                      >
                                        Resume
                                      </a>
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </div>
              </Col>
            )}
            {this.state.isEditable && (
              <Col md="12">
                <Card className="card-user ">
                  <CardHeader>
                    <Row>
                      <Col xs="8">
                        <CardTitle tag="h5">About</CardTitle>
                      </Col>
                      <Col xs="4" className="text-right">
                        <Button
                          className="btn primary"
                          // color="primary"
                          onClick={async (e) =>
                            await this.setState({ isEditable: false })
                          }
                        >
                          CANCEL
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <form onSubmit={(e) => this.handleSubmitButtonClick(e)}>
                      <Alert />
                      <div className="row">
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label">Work Type</Label>
                            <select
                              className="form-control"
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              type="select"
                              name="workType"
                              value={this.state.workType}
                              placeholder="Company"
                              onChange={(event) =>
                                this.setState({
                                  workType: event.target.value,
                                })
                              }
                            >
                              <option value={""}>Select</option>
                              <option value={"Full Time"}>Full Time</option>
                              <option value={"Part Time"}>Part Time</option>
                            </select>

                            <div className="text-danger">
                              {this.validator.message(
                                "workType",
                                this.state.workType,
                                "required"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">
                              Work Location
                            </Label>
                            <select
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="select"
                              name="workLocation"
                              value={this.state.workLocation}
                              placeholder="Company"
                              onChange={(event) =>
                                this.setState({
                                  workLocation: event.target.value,
                                })
                              }
                            >
                              <option value={""}>Select</option>
                              <option value={"Remote"}>Remote</option>
                              <option value={"On Location"}>On Location</option>
                            </select>

                            <div className="text-danger">
                              {this.validator.message(
                                "workLocation",
                                this.state.workLocation,
                                "required"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">
                              Notice Period
                            </Label>
                            <Input
                              className="form-control "
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              type="text"
                              name="noticePeriod"
                              value={this.state.noticePeriod}
                              placeholder="15 Days"
                              onChange={(event) =>
                                this.setState({
                                  noticePeriod: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "noticePeriod",
                                this.state.noticePeriod,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label">
                              Current Salary
                            </Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="text"
                              name="currentSalary"
                              value={this.state.currentSalary}
                              placeholder="In Thousands"
                              onChange={(event) =>
                                this.setState({
                                  currentSalary: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "currentSalary",
                                this.state.currentSalary,
                                "required|integer"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">
                              Expected Salary
                            </Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="text"
                              name="expectedSalary"
                              value={this.state.expectedSalary}
                              placeholder="In Thousands"
                              onChange={(event) =>
                                this.setState({
                                  expectedSalary: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "expectedSalary",
                                this.state.expectedSalary,
                                "required|integer"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Resume</Label>
                            <div
                              className="mt--5"
                              style={{ cursor: "pointer", marginTop: -10 }}
                            >
                              <Dropzone
                                accept="application/pdf"
                                multiple={false}
                                onDrop={(acceptedFiles) => {
                                  this.onDrop({
                                    files: acceptedFiles,
                                    name: "resume",
                                  });
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section>
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <Button
                                        onClick={(e) => e.preventDefault()}
                                        className="primary"
                                      >
                                        Upload Resume
                                      </Button>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                              {this.state.resume && (
                                <a
                                  style={{ color: "blue" }}
                                  href={this.state.resume}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  Resume
                                </a>
                              )}
                            </div>
                          </FormGroup>
                        </div>
                      </div>

                      <Row>
                        <div className="update ml-auto mr-auto">
                          <Button className="primary" type="submit">
                            {this.state.isUpdating ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </div>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      </>
    );
  }
}

Profile.propTypes = {
  setAlert: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setAlert,
  getCurrentProfile,
})(Profile);
