// dev config variables name
const dev = {
  hostName: "https://pe7ug80hmd.execute-api.us-east-2.amazonaws.com/prod",
  // hostName: "http://localhost:5001",
  razorPayKey: "rzp_live_rP60BVVlIjHOc8",
  cookie_expiration: 7200,
};

// production variables name
const prod = {
  hostName: "https://pe7ug80hmd.execute-api.us-east-2.amazonaws.com/prod",
  // hostName: "http://localhost:5001",
  razorPayKey: "rzp_live_rP60BVVlIjHOc8",
  cookie_expiration: 7200,
};

const config = process.env.REACT_APP_STAGE === "production" ? prod : dev;

// export the default configuration
export default {
  ...config,
};
