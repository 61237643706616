import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { message } from "antd";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import Config from "../config";
import Dropzone from "react-dropzone";
import SweetAlert from "react-bootstrap-sweetalert";

class FreelancerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal1Visible: false,
      modal2Visible: false,
      name: "",
      phone: "",
      email: "",
      linkedinUrl: "",
      expectedSalary: "",
      previousProjectDetails: "",
      skills: "",
      resume: "",
    };
    this.validator = new SimpleReactValidator();
  }

  onDrop({ files, name }) {
    let formData = new FormData();
    formData.append("images", files[0]);
    this.setState({ uploading: true });

    axios
      .post(Config.hostName + "/api/profile/images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (res) => {
        console.log(res.data);
        this.setState({ resume: res.data, uploading: false });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ uploading: false });
      });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.success();
  }

  handleSignupButtonClick = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let {
        name,
        phone,
        email,
        linkedinUrl,
        expectedSalary,
        previousProjectDetails,
        skills,
        resume,
      } = this.state;
      const data = {
        name,
        phone,
        email,
        linkedinUrl,
        expectedSalary,
        previousProjectDetails,
        skills,
        resume,
      };
      this.setState({ isUpdating: true });
      try {
        let host = Config.hostName;

        await axios.post(host + "/api/freelancer", data);
        this.validator.visibleFields = [];
        window.gtag("event", "conversion", {
          send_to: "AW-633872257/0RwaCK6869IBEIG_oK4C",
        });

        this.setState({
          isUpdating: false,
          name: "",
          phone: "",
          email: "",
          linkedinUrl: "",
          alert: true,
          expectedSalary: "",
          previousProjectDetails: "",
          skills: "",
          resume: "",
        });

        this.success();
      } catch (err) {
        this.setState({ isUpdating: false });

        var errors =
          err.response && err.response.data && err.response.data.errors;
        if (errors) {
          errors.map((error) => message.error(error.msg));
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <>
        {this.state.alert && (
          <SweetAlert
            success
            title="Success!"
            onCancel={() =>
              this.setState({
                alert: null,
              })
            }
            customButtons={
              <React.Fragment>
                <button
                  className="c-btn "
                  onClick={() =>
                    this.setState({
                      alert: null,
                    })
                  }
                  style={{ outline: "none" }}
                >
                  OK
                </button>
              </React.Fragment>
            }
          >
            Your request has been sent successfully. We will contact you
            shortly.
          </SweetAlert>
        )}
        <div className="type-of-work-box">
          <h4 className="work-box-title">Submit your details</h4>

          <form className="comp form">
            <div>
              <input
                type="text"
                placeholder="Name"
                value={this.state.name}
                onChange={(event) =>
                  this.setState({
                    name: event.target.value,
                  })
                }
                onFocus={(e) => this.validator.showMessageFor("name")}
              />

              <div className="text-danger">
                {this.validator.message("name", this.state.name, "required")}
              </div>

              <input
                type="text"
                placeholder="Email"
                value={this.state.email}
                onChange={(event) =>
                  this.setState({
                    email: event.target.value,
                  })
                }
                onFocus={(e) => this.validator.showMessageFor("email")}
              />

              <div className="text-danger">
                {this.validator.message(
                  "email",
                  this.state.email,
                  "required|email"
                )}
              </div>

              <input
                type="text"
                placeholder="Phone"
                value={this.state.phone}
                onChange={(event) =>
                  this.setState({
                    phone: event.target.value,
                  })
                }
                onFocus={(e) => this.validator.showMessageFor("phone")}
              />

              <div className="text-danger">
                {this.validator.message(
                  "phone",
                  this.state.phone,
                  "required|phone"
                )}
              </div>
              <input
                type="text"
                placeholder="Linkedin Url"
                value={this.state.linkedinUrl}
                onChange={(event) =>
                  this.setState({
                    linkedinUrl: event.target.value,
                  })
                }
                // onFocus={(e) => this.validator.showMessageFor("linkedinUrl")}
              />

              {/* <div className="text-danger">
                {this.validator.message(
                  "linkedinUrl",
                  this.state.linkedinUrl,
                  "required|url"
                )}
              </div> */}

              <input
                type="text"
                placeholder="Expected salary : 20000 Per Month"
                value={this.state.expectedSalary}
                onChange={(event) =>
                  this.setState({
                    expectedSalary: event.target.value,
                  })
                }
                // onFocus={(e) => this.validator.showMessageFor("expectedSalary")}
              />

              {/* <div className="text-danger">
                {this.validator.message(
                  "expectedSalary",
                  this.state.expectedSalary,
                  "required|min:2"
                )}
              </div> */}

              <textarea
                type="text"
                rows="3"
                placeholder="Please share details of the most brilliant project you have worked on."
                value={this.state.previousProjectDetails}
                onChange={(event) =>
                  this.setState({
                    previousProjectDetails: event.target.value,
                  })
                }
                // onFocus={(e) =>
                //   this.validator.showMessageFor("previousProjectDetails")
                // }
              />

              {/* <div className="text-danger">
                {this.validator.message(
                  "previousProjectDetails",
                  this.state.previousProjectDetails,
                  "required"
                )}
              </div> */}
            </div>

            <input
              type="text"
              placeholder="Skills Ex: ReactJS, NodeJs"
              value={this.state.skills}
              onChange={(event) =>
                this.setState({
                  skills: event.target.value,
                })
              }
              // onFocus={(e) => this.validator.showMessageFor("skills")}
            />

            {/* <div className="text-danger">
              {this.validator.message("skills", this.state.skills, "required")}
            </div> */}

            <div>
              <label>Please upload your CV</label>
              <Dropzone
                accept="application/pdf"
                multiple={false}
                onDrop={(acceptedFiles) => {
                  this.onDrop({
                    files: acceptedFiles,
                    name: "resume",
                  });
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />

                      <button
                        onClick={(e) => e.preventDefault()}
                        className="upload-button"
                        disabled={this.state.uploading}
                      >
                        {this.state.uploading ? "Uploading" : "Attach PDF"}
                      </button>
                      {/* <button
                        className="c-btn "
                        onClick={(e) => e.preventDefault()}
                        style={{ outline: "none" }}
                        disabled={this.state.isUpdating}
                      >
                        {this.state.isUpdating ? "updating" : "Upload Resume"}
                      </button> */}
                    </div>
                  </section>
                )}
              </Dropzone>
              {this.state.resume && (
                <a
                  style={{ color: "blue" }}
                  href={this.state.resume}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Uploaded Resume
                </a>
              )}
            </div>
            {/* <div className="text-danger">
              {this.validator.message("resume", this.state.resume, "required")}
            </div> */}

            <div className="text-center pt-3">
              <button
                className="c-btn "
                onClick={(e) => {
                  this.handleSignupButtonClick(e);
                }}
                style={{ outline: "none" }}
                disabled={this.state.isUpdating}
              >
                {this.state.isUpdating ? "loading" : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
}

FreelancerForm.propTypes = { auth: PropTypes.object.isRequired };
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(FreelancerForm);
