import React from "react";
import SimpleReactValidator from "simple-react-validator";
import Config from "../../config";
import Dropzone from "react-dropzone";
import { Radio } from "antd";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import { setAlert } from "../../actions/alert.actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createProfile, getCurrentProfile } from "../../actions/profile";
import { Link } from "react-router-dom";
import Alert from "../../layouts/Alert";
import Spinner from "../../layouts/Spinner";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      isEditable: false,
      password: "",
      phone: "",
      isPublic: true,
      isUpdating: false,
      isLoading: true,
      youtube: "",
      avatar: "/img/landing-page/avatar.jpg",
      style: {},
    };
    this.validator = new SimpleReactValidator();
  }

  getUser = async () => {
    try {
      const res = await axios.get(Config.hostName + "/api/auth");

      this.setState({ isPublic: res.data.isPublic });
    } catch (err) {
      console.log(err.response && err.response.data);
    }
  };

  async componentWillMount() {
    this.props.profile
      ? await this.setState({
          website: this.props.profile.website,
          location: this.props.profile.location,
          bio: this.props.profile.bio,
          status: this.props.profile.status,
          github: this.props.profile.social && this.props.profile.social.github,
          facebook:
            this.props.profile.social && this.props.profile.social.facebook,
          twitter:
            this.props.profile.social && this.props.profile.social.twitter,
          behance:
            this.props.profile.social && this.props.profile.social.behance,
          linkedin:
            this.props.profile.social && this.props.profile.social.linkedin,
        })
      : this.setState({ isEditable: true });
    this.getUser();
    this.props.profile &&
      this.props.profile.avatar &&
      this.setState({ avatar: this.props.profile.avatar });
  }

  async onDrop({ files, name }) {
    let formData = new FormData();
    formData.append("images", files[0]);

    await axios
      .post(Config.hostName + "/api/profile/images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (res) => {
        this.setState({ avatar: res.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  async handleSubmitButtonClick(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      // get form data from react state object
      let {
        avatar,
        website,
        location,
        bio,
        isPublic,
        status,
        github,
        facebook,
        twitter,
        behance,
        linkedin,
      } = this.state;

      let body = {
        userid:this.props.userid,
        avatar,
        website,
        location,
        isPublic,
        bio,
        status,
        github,
        facebook,
        twitter,
        behance,
        linkedin,
      };
      //make user signup request
      await this.setState({ isUpdating: true });
      var that = this;
      try {
        console.log("body", body);
        const res = await axios.post(Config.hostName + "/api/profile", body);

        console.log(res.data);
        await this.setState({
          isUpdating: false,
          isEditable: false,
        });
        this.getUser();
        this.props.getProfile();
      } catch (err) {
        that.props.getProfile();
        this.getUser();
        console.log(err);
        const errors =
          err.response &&
          err.response.data &&
          err.response &&
          err.response.data &&
          err.response.data.errors;
        await this.setState({ isUpdating: false });

        if (errors) {
          errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }
    console.log(this.state);
    return (
      <>
        <div className="content bg-white shadow" style={{ marginTop: -50 }}>
          <Row>
            {!this.state.isEditable && (
              <Col md="12">
                <div>
                  <div
                    className="bg-secondary"
                    style={{
                      height: 180,
                      background: `url(
                        "https://miro.medium.com/max/6000/1*5kRCNXmpxrRwTgR2hNJxvg.jpeg"
                      )`,
                      backgroundPosition: "center",
                      width: "100%",
                      marginTop: -40,
                    }}
                  ></div>
                  <CardBody>
                    <div className="row container">
                      <div className="col-md-12">
                        <a
                          href="#pablo"
                          className="text-center"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img
                            alt="..."
                            className="ratio img-responsive img-circle  border-gray"
                            style={{
                              minWidth: 50,
                              marginTop: -75,
                              maxWidth: 100,
                            }}
                            src={this.state.avatar}
                          />
                        </a>
                      </div>
                      <div className="col-md-12">
                        {this.state.isEditable === false && (
                          <Row>
                            <Col md="5">
                              <h5 className="title text-capitalize">
                                {this.props.profile
                                  ? this.props.profile.user.name
                                  : this.props.auth.user.name}
                              </h5>

                              {this.state.isPublic ? (
                                <div className="p">
                                  <i class="fas fa-eye"></i> Your profile is
                                  visible to public.
                                </div>
                              ) : (
                                <div className="p">
                                  <i class="fas fa-eye-slash"></i> Your profile
                                  is only visible to you.
                                </div>
                              )}
                            </Col>
                            <Col md="6" xs="10">
                              {this.props.profile && (
                                <>
                                  <p>{this.props.profile.bio}</p>

                                  <p className="">
                                    <i className="nc-icon nc-pin-3" />{" "}
                                    {this.state.location}{" "}
                                    {this.state.github && (
                                      <a
                                        style={{ color: "#A9A3A3" }}
                                        href={
                                          "https://github.com/" +
                                          this.state.github
                                        }
                                        target="blank"
                                      >
                                        <i class="fa fa-github"></i>
                                      </a>
                                    )}{" "}
                                    {this.state.twitter && (
                                      <a
                                        style={{ color: "#A9A3A3" }}
                                        href={
                                          "https://twitter.com/" +
                                          this.state.twitter
                                        }
                                        target="blank"
                                      >
                                        <i class="fa fa-twitter"></i>
                                      </a>
                                    )}{" "}
                                    {this.state.facebook && (
                                      <a
                                        style={{ color: "#A9A3A3" }}
                                        href={
                                          "https://facebook.com/" +
                                          this.state.facebook
                                        }
                                        target="blank"
                                      >
                                        <i class="fa fa-facebook"></i>
                                      </a>
                                    )}{" "}
                                    {this.state.linkedin && (
                                      <a
                                        style={{ color: "#A9A3A3" }}
                                        href={
                                          "https://linkedin.com/" +
                                          this.state.linkedin
                                        }
                                        target="blank"
                                      >
                                        <i class="fa fa-linkedin"></i>
                                      </a>
                                    )}{" "}
                                    {this.state.behance && (
                                      <a
                                        style={{ color: "#A9A3A3" }}
                                        href={
                                          "https://behance.com/" +
                                          this.state.behance
                                        }
                                        target="blank"
                                      >
                                        <i class="fa fa-behance"></i>
                                      </a>
                                    )}{" "}
                                    {this.state.website && (
                                      <a
                                        style={{ color: "#A9A3A3" }}
                                        href={this.state.website}
                                        target="blank"
                                      >
                                        <i class="fa fa-globe"></i>
                                      </a>
                                    )}
                                  </p>
                                </>
                              )}
                            </Col>
                            <Col md="1" xs="2">
                              <div
                                className="text-right"
                                style={{ marginLeft: 5 }}
                              >
                                {this.props.auth.user.id === this.props.userid && (
                                  <Link
                                    style={{ color: "blue" }}
                                    onClick={async (e) =>
                                      await this.setState({ isEditable: true })
                                    }
                                  >
                                    <img
                                      alt=""
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/landing-page/editicon.svg"
                                      }
                                      width="100%"
                                      className="rounded"
                                    />
                                    {/* <i className="fa fa-edit " /> */}
                                  </Link>
                                )}
                              </div>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </div>
              </Col>
            )}
            {this.state.isEditable && (
              <Col md="12">
                <Card className="card-user container mt-5 pt-2">
                  <CardHeader className="">
                    <Row>
                      <Col xs="8">
                        <CardTitle tag="h5">Basic</CardTitle>
                      </Col>
                      <Col xs="4" className="text-right">
                        <Button
                          className="btn primary"
                          onClick={async (e) =>
                            await this.setState({ isEditable: false })
                          }
                        >
                          CANCEL
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <form onSubmit={(e) => this.handleSubmitButtonClick(e)}>
                      <Alert />
                      <div className="row">
                        <div className="col-md-6">
                          <div style={{ cursor: "pointer" }}>
                            <Dropzone
                              accept="image/*"
                              multiple={false}
                              onDrop={(acceptedFiles) => {
                                this.onDrop({
                                  files: acceptedFiles,
                                  name: "profile",
                                });
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <img
                                      alt="..."
                                      className="img-thumbnail  border-gray"
                                      style={{
                                        minWidth: 80,
                                        // marginTop: -75,
                                        maxWidth: 115,
                                      }}
                                      src={this.state.avatar}
                                    />
                                    <div>Upload Photo</div>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <FormGroup>
                            <Label className="col-form-label">Headline *</Label>
                            <Input
                              className="form-control"
                              type="text"
                              name="bio"
                              value={this.state.bio}
                              placeholder="Headline*"
                              onChange={(event) =>
                                this.setState({ bio: event.target.value })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "headline",
                                this.state.bio,
                                "required|min:20|max:150"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">Website</Label>
                            <Input
                              className="form-control"
                              type="text"
                              name="website"
                              value={this.state.website}
                              placeholder="Website"
                              onChange={(event) =>
                                this.setState({
                                  website: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "website",
                                this.state.website,
                                "required"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">Location</Label>
                            <Input
                              className="form-control"
                              type="text"
                              name="location"
                              value={this.state.location}
                              placeholder="City, State, Contry"
                              onChange={(event) =>
                                this.setState({ location: event.target.value })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "location",
                                this.state.location,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">
                              Visible to public
                            </Label>
                            <div>
                              <Radio.Group
                                onChange={(event) =>
                                  this.setState({
                                    isPublic: event.target.value,
                                  })
                                }
                                value={this.state.isPublic}
                              >
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                              </Radio.Group>
                            </div>
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label">Twitter</Label>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  https://twitter.com/
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                className="form-control"
                                type="text"
                                name="twitter"
                                value={this.state.twitter}
                                placeholder="Username"
                                onChange={(event) =>
                                  this.setState({ twitter: event.target.value })
                                }
                              />
                            </InputGroup>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">Github</Label>

                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  https://github.com/
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                className="form-control"
                                type="text"
                                name="github"
                                value={this.state.github}
                                placeholder="Username"
                                onChange={(event) =>
                                  this.setState({ github: event.target.value })
                                }
                              />
                            </InputGroup>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">Facebook</Label>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  https://facebook.com/
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                className="form-control"
                                type="text"
                                name="facebook"
                                value={this.state.facebook}
                                placeholder="Username"
                                onChange={(event) =>
                                  this.setState({
                                    facebook: event.target.value,
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">Linkedin</Label>

                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  https://linkedin.com/
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                className="form-control"
                                type="text"
                                name="linkedin"
                                value={this.state.linkedin}
                                placeholder="Username"
                                onChange={(event) =>
                                  this.setState({
                                    linkedin: event.target.value,
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Behance</Label>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  https://behance.com/
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                className="form-control"
                                type="text"
                                name="behance"
                                value={this.state.behance}
                                placeholder="Username"
                                onChange={(event) =>
                                  this.setState({ behance: event.target.value })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </div>
                      </div>

                      <Row>
                        <div className="update ml-auto mr-auto">
                          <Button className="primary" type="submit" size="md">
                            {this.state.isUpdating ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </div>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      </>
    );
  }
}

Profile.propTypes = {
  setAlert: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  createProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setAlert,
  createProfile,
  getCurrentProfile,
})(Profile);
