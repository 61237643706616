import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ClientList from 'components/client-list';
import { Helmet } from 'react-helmet';

import ContactUs from 'components/contactus';
import GetDemoForm from 'components/GetDemoForm';
import LandingPageCommon from 'views/LandingPageCommon';

class GetDemo extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Zapplabs | Hire React Developer</title>
          <meta
            name="description"
            content="We have worked with various startup companies to develop
            their product using react.js. We have worked on different
            kind of solutions from small change to build production
            application from scratch."
          />
        </Helmet>
        <div style={{ backgroundColor: '#f5f4f9' }} className="mb-5">
          <section className="container pt-2">
            <div className="row ">
              <div className="col-md-7">
                <div
                  style={{
                    paddingBottom: '8vw',
                  }}
                >
                  <h2 className="title header-title pt-3">
                    We provide react.js development services
                  </h2>

                  <h5 className="poppins work-box-desc pt-1">
                    We have worked with various startup companies to develop
                    their product using react.js. We have worked on different
                    kind of solutions from small change to build production
                    application from scratch.
                  </h5>
                  <h4 className="header-title pt-1 ">
                    Our call will help us to:
                  </h4>
                  <ul>
                    <li className="work-box-desc">
                      Your requeirements and how we can work together
                    </li>
                    <li className="work-box-desc">
                      Understand if we are right team to solve your problem
                    </li>
                    <li className="work-box-desc">
                      Any other details that you want to discuss with us
                    </li>
                    {/* <li className="work-box-desc">
                      Identify and monetize mobile moments with the help of our
                      mobile marketing experts
                    </li> */}
                  </ul>
                  <ContactUs />
                </div>
              </div>
              <div className="col-md-5 pb-5 pt-3">
                <GetDemoForm {...this.props} />
              </div>
            </div>
          </section>
        </div>
        <ClientList />
        {/* <LandingPageCommon></LandingPageCommon> */}
      </>
    );
  }
}

GetDemo.propTypes = { auth: PropTypes.object.isRequired };
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(GetDemo);
