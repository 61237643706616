import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import './Config/Fire';
import 'firebase/analytics';
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss?v=1.1.0';
import 'assets/demo/demo.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import Profile from './views/user-pages/Profile';
import ChatRoom from './views/user-pages/ChatRoom';
import Chats from './views/user-pages/Chats';
import Profiles from './views/user-pages/Profiles';
import UserRegister from './views/user-pages/UserRegister';
import UserLogin from './views/user-pages/UserLogin';
import ConfirmEmail from './views/user-pages/ConfirmEmail';
import ResetPasswordForm from './views/user-pages/ResetPasswordForm';
import ResetPTokenVarify from './views/user-pages/ResetPTokenVarify';
import ChangePasswordForm from './views/user-pages/ChangePasswordForm';
import Projects from './views/user-pages/Projects';
import DesignerProjects from './views/user-pages/DesignerProjects';
import Services from './views/user-pages/Services';
import Service from './views/user-pages/Service';
import Transactions from './views/user-pages/Transactions';
import AddService from './components/profile/service-form';

import DevProfile from './components/profiles/profile';

import './app.css';
import BusinessUserLogin from './views/business-user-pages/BusinessUserLogin';
import BusinessUserRegister from './views/business-user-pages/BusinessUserRegister';
import CallBackForm from './views/business-user-pages/CallBackForm';

import DevelopersProfile from './views/user-pages/DevelopersProfile';

import Freelancer from './views/forms/Freelancer';
import Customer from './views/forms/Customer';

import LandingPage from './views/LandingPage';
import AllServicesHome from './views/AllServices';
import OurServices from './views/OurServices';
import ContactUs from './views/ContactUs';

import GetDemo from './views/GetDemo';
import EcommercePricing from './views/EcommercePricing';
import MobileCoursePricing from './views/MobileCoursePricing';
import HireAndroidDeveloper from './views/hire/HireAndroidDeveloper';
import HireNodeDeveloper from './views/hire/HireNodeDeveloper';
import HireReactDeveloper from './views/hire/HireReactDeveloper';
import HireReactNativeDeveloper from './views/hire/HireReactNativeDeveloper';
import HireShopifyAppDeveloper from './views/hire/HireShopifyAppDeveloper';
import HireShopifyDeveloper from './views/hire/HireShopifyDeveloper';
import HireShopifyPrivateAppDeveloper from './views/hire/HireShopifyPrivateAppDeveloper';
import HireShopifyPublicAppDeveloper from './views/hire/HireShopifyPublicAppDeveloper';
import SoftwareDevelopmentCompany from './views/hire/SoftwareDevelopmentCompany';
import AppDeveloperInDelhi from './views/hire/AppDeveloperInDelhi';
import AppDeveloperInKolkata from './views/hire/AppDeveloperInKolkata';
import ShopifyDesigner from './views/hire/ShopifyDesigner';
import HireCustomAppDeveloper from './views/hire/HireCustomAppDeveloper';
import GetBestAppDevelopment from './views/hire/GetBestAppDevelopment';
import AppDeveloper from './views/hire/AppDeveloper';
import ReactNativeDevelopmentCompanyIndia from './views/hire/ReactNativeDevelopmentCompanyIndia';
import HireFreelanceWebDeveloper from './views/hire/HireFreelanceWebDeveloper';
import FreelanceWebDeveloperSites from './views/hire/FreelanceWebDeveloperSites';
import HowCanIFindAWebDeveloper from './views/hire/HowCanIFindAWebDeveloper';
import ByjusAppFreelancer from './views/hire/ByjusAppFreelancer';
import DevelopersForHire from './views/hire/DevelopersForHire';
import EcommerceFreelancer from './views/hire/EcommerceFreelancer';
import FreelancePhpDeveloper from './views/hire/FreelancePhpDeveloper';
import HireFreelanceWebDeveloperWebsite from './views/hire/HireFreelanceWebDeveloperWebsite';
import FreelanceSoftwareDeveloperHariNagarDelhi from './views/hire/FreelanceSoftwareDeveloperHariNagarDelhi';
import HireWebDeveloper from './views/hire/HireWebDeveloper';
import HirePythonFreelancer from './views/hire/HirePythonFreelancer';
import HirePHPFreelancer from './views/hire/HirePHPFreelancer';
import HireNodejsFreelancer from './views/hire/HireNodejsFreelancer';
import HireReactjsFreelancer from './views/hire/HireReactjsFreelancer';
import HireAndroidFreelancer from './views/hire/HireAndroidFreelancer';
import HireHTMLFreelancer from './views/hire/HireHTMLFreelancer';
import HirePythonFreelancerInMumbai from './views/hire/HirePythonFreelancerInMumbai';
import UIFreelancer from './views/hire/UIFreelancer';
import HireReactDevelopers from './views/hire/HireReactDevelopers';
import HireWordpressDeveloper from './views/hire/HireWordpressDeveloper';
import EcommerceDevelopment from './views/hire/EcommerceDevelopment';
import EcommerceMobileAppDevelopment from './views/hire/EcommerceMobileAppDevelopment';
import HireMagentoDeveloper from './views/hire/HireMagentoDeveloper';

import Clients from './views/Clients';
import PortfolioProjects from './views/PortfolioProjects';
import Aboutus from './views/Aboutus';
import Pricing from './views/Pricing';

import DemoNavbar from './components/Navbars/DemoNavbar';
import Footer from './components/Footer/Footer';
import PrivateRoute from './routing/PrivateRoute';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './store';
import { Provider } from 'react-redux';
import setAuthToken from './utils/setAuthToken';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FreelancerContact from 'views/FreelancerContact';
import My404 from 'views/My404';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}
const hist = createBrowserHistory();

class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisconnected: false,
    };
  }

  componentDidMount() {
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    console.log({ condition });
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('//google.com', {
          mode: 'no-cors',
        })
          .then(() => {
            this.setState({ isDisconnected: false }, () => {
              return clearInterval(webPing);
            });
          })
          .catch(() => this.setState({ isDisconnected: true }));
      }, 2000);
      return;
    }

    return this.setState({ isDisconnected: true });
  };

  render() {
    const { isDisconnected } = this.state;

    const routes = [
      { path: '/app-developer-in-kolkata', component: AppDeveloperInKolkata },
      { path: '/shopify-designer', component: ShopifyDesigner },
      { path: '/hire-custom-app-developer', component: HireCustomAppDeveloper },
      { path: '/get-best-app-development', component: GetBestAppDevelopment },
      { path: '/app-developer', component: AppDeveloper },
      {
        path: '/react-native-development-company-in-india',
        component: ReactNativeDevelopmentCompanyIndia,
      },
      {
        path: '/hire-freelance-web-developer',
        component: HireFreelanceWebDeveloper,
      },
      {
        path: '/freelance-web-developer-sites',
        component: FreelanceWebDeveloperSites,
      },
      {
        path: '/how-can-i-find-web-developer',
        component: HowCanIFindAWebDeveloper,
      },
      { path: '/byjus-app-freelancer', component: ByjusAppFreelancer },
      { path: '/developers-for-hire', component: DevelopersForHire },
      { path: '/ecommerce-freelancer', component: EcommerceFreelancer },
      { path: '/freelance-php-developer', component: FreelancePhpDeveloper },
      {
        path: '/hire-freelance-web-developer-websites',
        component: HireFreelanceWebDeveloperWebsite,
      },
      {
        path: '/freelance-software-developer-hari-nagar-delhi',
        component: FreelanceSoftwareDeveloperHariNagarDelhi,
      },
      { path: '/hire-web-developer', component: HireWebDeveloper },
      { path: '/hire-python-freelancer', component: HirePythonFreelancer },
      { path: '/hire-php-freelancer', component: HirePHPFreelancer },
      { path: '/hire-nodejs-freelancer', component: HireNodejsFreelancer },
      { path: '/hire-reactjs-freelancer', component: HireReactjsFreelancer },
      { path: '/hire-android-freelancer', component: HireAndroidFreelancer },
      { path: '/hire-html-freelancer', component: HireHTMLFreelancer },
      {
        path: '/hire-python-freelancer-in-mumbai',
        component: HirePythonFreelancerInMumbai,
      },
      { path: '/hire-ui-freelancer', component: UIFreelancer },
      { path: '/hire-reactjs-developers', component: HireReactDevelopers },
    ];
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={hist}>
            {isDisconnected ? (
              <div className="internet-error">
                <h2 className="header-title">No internet</h2>

                <h5 className="poppins header-subtext pt-4 pb-3">
                  Please Connect to internet
                </h5>
              </div>
            ) : (
              <>
                <Route render={(props) => <DemoNavbar {...props} />} />
                <div
                  className="main-content"
                  ref="mainContent"
                  style={{ marginTop: '-2em' }}
                >
                  <Switch>
                    {routes.map((route) => (
                      <Route
                        exact
                        path={route.path}
                        key={route.path}
                        component={route.component}
                      />
                    ))}
                    <Route
                      exact
                      path={'/freelancer-form'}
                      component={Freelancer}
                    />
                    <Route exact path={'/clients'} component={Clients} />
                    <Route
                      exact
                      path={'/portfolio-projects'}
                      component={PortfolioProjects}
                    />
                    <Route exact path={'/about-us'} component={Aboutus} />
                    <Route exact path={'/pricing'} component={Pricing} />
                    <Route exact path={'/customer-form'} component={Customer} />
                    <Route
                      exact
                      path={'/user/register'}
                      component={UserRegister}
                    />
                    <Route exact path={'/user/login'} component={UserLogin} />
                    <Route
                      exact
                      path={'/business/register'}
                      component={BusinessUserRegister}
                    />
                    <Route
                      exact
                      path={'/business/login'}
                      component={BusinessUserLogin}
                    />

                    <Route
                      exact
                      path={'/confirm/:token'}
                      component={ConfirmEmail}
                    />
                    <Route
                      exact
                      path={'/reset-password/form'}
                      component={ResetPasswordForm}
                    />
                    <Route
                      exact
                      path={'/reset-password/verify/:token'}
                      component={ResetPTokenVarify}
                    />
                    <Route
                      exact
                      path={'/invite/:token'}
                      component={ResetPTokenVarify}
                    />
                    <Route
                      exact
                      path={'/change-password/:token'}
                      component={ChangePasswordForm}
                    />
                    <Route
                      exact
                      path={'/create-password/:token'}
                      component={ChangePasswordForm}
                    />
                    <PrivateRoute
                      exact
                      path={'/user/profile/:userId'}
                      component={Profile}
                    />
                    <PrivateRoute
                      exact
                      path={'/user/:userId'}
                      component={Profile}
                    />
                    <Route exact path={'/profiles'} component={Profiles} />
                    <Route
                      exact
                      path={'/dev-profiles'}
                      component={DevelopersProfile}
                    />
                    <Route
                      exact
                      path={'/dev-profile/:profileid'}
                      component={DevProfile}
                    />
                    <PrivateRoute
                      exact
                      path={'/chat-room/:profileid'}
                      component={ChatRoom}
                    />
                    <PrivateRoute exact path={'/chats'} component={Chats} />
                    <Route exact path={'/projects'} component={Projects} />
                    <Route
                      exact
                      path={'/designer-projects'}
                      component={DesignerProjects}
                    />
                    <Route
                      exact
                      path={'/transactions'}
                      component={Transactions}
                    />
                    <Route exact path={'/services'} component={Services} />
                    <Route
                      exact
                      path={'/services/tags/:tags'}
                      component={Services}
                    />
                    <Route exact path={'/service/:id'} component={Service} />
                    <PrivateRoute
                      exact
                      path={'/my-services'}
                      component={AddService}
                    />
                    <Route
                      exact
                      path={'/our-services'}
                      component={OurServices}
                    />
                    <Route exact path={'/'} component={LandingPage} />
                    <Route
                      exact
                      path={'/all-services'}
                      component={AllServicesHome}
                    />
                    <Route exact path={'/contact-us'} component={ContactUs} />
                    <Route
                      exact
                      path={'/freelancer-contact'}
                      component={FreelancerContact}
                    />

                    {/* get demo page */}
                    <Route exact path={'/get-demo'} component={GetDemo} />
                    <Route
                      exact
                      path={'/ecommerce'}
                      component={EcommercePricing}
                    />
                    <Route
                      exact
                      path={'/course-app'}
                      component={MobileCoursePricing}
                    />

                    {/* hire node.js developer */}
                    <Route
                      exact
                      path={'/hire-node-developer'}
                      component={HireNodeDeveloper}
                    />

                    {/* hire react.js developer */}
                    <Route
                      exact
                      path={'/hire-react-developer'}
                      component={HireReactDeveloper}
                    />

                    {/* hire shopify website developer developer */}
                    <Route
                      exact
                      path={'/hire-shopify-developer'}
                      component={HireShopifyDeveloper}
                    />

                    {/* Hire wordpress website developer */}
                    <Route
                      exact
                      path={'/hire-wordpress-developer'}
                      component={HireWordpressDeveloper}
                    />

                    <Route
                      exact
                      path={'/ecommerce-website-development'}
                      component={EcommerceDevelopment}
                    />

                    <Route
                      exact
                      path={'/ecommerce-mobile-app-development'}
                      component={EcommerceMobileAppDevelopment}
                    />

                    {/* Hire magento developer */}
                    <Route
                      exact
                      path={'/hire-magento-developer'}
                      component={HireMagentoDeveloper}
                    />

                    <Route
                      exact
                      path={'/software-development-company'}
                      component={SoftwareDevelopmentCompany}
                    />

                    <Route
                      exact
                      path={'/app-developer-in-delhi'}
                      component={AppDeveloperInDelhi}
                    />

                    {/* hire shopify app developer */}
                    <Route
                      exact
                      path={'/hire-shopify-app-developer'}
                      component={HireShopifyAppDeveloper}
                    />

                    {/* hire shopify public app developer developer */}
                    <Route
                      exact
                      path={'/hire-shopify-public-app-developer'}
                      component={HireShopifyPublicAppDeveloper}
                    />

                    {/* hire shopoify private app developer */}
                    <Route
                      exact
                      path={'/hire-shopify-private-app-developer'}
                      component={HireShopifyPrivateAppDeveloper}
                    />

                    {/* hire android app developer */}
                    <Route
                      exact
                      path={'/hire-android-app-developer'}
                      component={HireAndroidDeveloper}
                    />

                    {/* hire shopoify private app developer */}
                    <Route
                      exact
                      path={'/hire-react-native-developer'}
                      component={HireReactNativeDeveloper}
                    />

                    <Route component={My404} />

                    <div className="container">
                      <PrivateRoute
                        exact
                        path={'/business/home'}
                        component={CallBackForm}
                      />
                    </div>
                  </Switch>
                  <Footer />
                </div>
              </>
            )}
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   ReactDOM.hydrate(<Root />, rootElement);
// } else {
//   ReactDOM.render(<Root />, rootElement);
// }
