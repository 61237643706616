import React from "react";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
// reactstrap components
import {
  CardBody,
  Nav,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Input,
  Navbar,
  Row,
  Col
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProfiles, getCurrentProfile } from "../../actions/profile";
import Spinner from "../../layouts/Spinner";

class Experience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      profiles: [],
      locationFilter: "",
      companyFilter: "",
      skillsFilter: "",
      description: "desc",
      isEditable: false,
      isUpdating: false,
      isLoading: true
    };
    this.validator = new SimpleReactValidator();
  }

  wait = ms => {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  };
  async componentWillMount() {
    
    // if (!this.props.profile.profile)
    // await this.props.getCurrentProfile(this.props.auth.user.id);
    // if (!this.props.profile.profiles.length === 0)
    await this.props.getProfiles();
    this.props.profile.profiles.length > 0 &&
      (await this.setState({
        profiles: this.props.profile.profiles
      }));
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  render() {
    var filteredProfiles = this.props.profile.profiles.filter(profile => {
      return (
        profile.user.name
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        profile.company
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        profile.location
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1
      );
    });
    if (this.state.locationFilter !== "") {
      filteredProfiles = filteredProfiles.filter(profile => {
        return (
          profile.location
            .toLowerCase()
            .indexOf(this.state.locationFilter.toLowerCase()) !== -1
        );
      });
    }

    if (this.state.companyFilter !== "") {
      filteredProfiles = filteredProfiles.filter(profile => {
        return (
          profile.company
            .toLowerCase()
            .indexOf(this.state.companyFilter.toLowerCase()) !== -1
        );
      });
    }
    if (this.state.skillsFilter !== "") {
      filteredProfiles = filteredProfiles.filter(profile => {
        return (
          profile.skills.find(skill => {
            return (
              skill.toLowerCase() === this.state.skillsFilter.toLowerCase()
            );
          }) !== -1
        );
      });
    }
    return (
      <>
        {this.props.profile.loading ? (
          <Spinner />
        ) : (
          <div className="content container" style={{ marginTop: 28 }}>
            <Row>
              <Col md="12">
                <div className="border p-4">
                  <CardBody>
                    <div className="row">
                      <div className="col-md-12">
                        <FormGroup>
                          <Label className="col-form-label">
                            Search by name, company, location
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            name="search"
                            value={this.state.search}
                            placeholder="Search"
                            onChange={event =>
                              this.setState({
                                search: event.target.value
                              })
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-12">
                        <Navbar
                          color={"white"}
                          expand="lg"
                          className={"navbar-white"}
                        >
                          <div className="navbar-wrapper">
                            <Nav navbar>
                              <Dropdown
                                nav
                                isOpen={this.state.locationDropdown}
                                toggle={async e =>
                                  await this.setState({
                                    locationDropdown: !this.state
                                      .locationDropdown
                                  })
                                }
                              >
                                <DropdownToggle caret nav>
                                  <p style={{ fontSize: 13 }}>
                                    <span className=" d-md-block">
                                      Location
                                    </span>
                                  </p>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem
                                    tag="a"
                                    onClick={e =>
                                      this.setState({
                                        locationFilter: ""
                                      })
                                    }
                                  >
                                    None
                                  </DropdownItem>
                                  <DropdownItem
                                    tag="a"
                                    onClick={e =>
                                      this.setState({
                                        locationFilter: "agra"
                                      })
                                    }
                                  >
                                    Agra
                                  </DropdownItem>
                                  <DropdownItem
                                    tag="a"
                                    onClick={e =>
                                      this.setState({
                                        locationFilter: "company"
                                      })
                                    }
                                  >
                                    company
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                              <Dropdown
                                nav
                                isOpen={this.state.companyDropdown}
                                toggle={async e =>
                                  await this.setState({
                                    companyDropdown: !this.state.companyDropdown
                                  })
                                }
                              >
                                <DropdownToggle caret nav>
                                  <p style={{ fontSize: 13 }}>
                                    <span className=" d-md-block">Company</span>
                                  </p>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem
                                    tag="a"
                                    onClick={e =>
                                      this.setState({
                                        companyFilter: ""
                                      })
                                    }
                                  >
                                    None
                                  </DropdownItem>
                                  <DropdownItem
                                    tag="a"
                                    onClick={e =>
                                      this.setState({
                                        companyFilter: "zapplabs"
                                      })
                                    }
                                  >
                                    Zapplabs
                                  </DropdownItem>
                                  <DropdownItem
                                    tag="a"
                                    onClick={e =>
                                      this.setState({
                                        companyFilter: "company"
                                      })
                                    }
                                  >
                                    company
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </Nav>
                          </div>
                        </Navbar>
                      </div>
                    </div>
                    {filteredProfiles.map(profile => (
                      <div className="row mb-4">
                        <div className="col-sm-2 col-xs-3 col-md-1 ">
                          <a
                            href="#pablo"
                            className="text-center"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              clhomeassName="img-thumbnail  border-gray"
                              style={{
                                minWidth: 50,
                                maxWidth: 80
                              }}
                              src={
                                profile.avatar
                                  ? profile.avatar
                                  : "/img/landing-page/avatar.jpg"
                              }
                            />
                          </a>
                        </div>
                        <div className="col-sm-10 col-xs-9 col-md-11 ">
                          <div className="container">
                            <Row>
                              <Col xs="12">
                                <Link
                                  color="blue"
                                  to={"/user/" + profile.user._id}
                                >
                                  <h6 className="text-capitalize">
                                    {profile.user.name}
                                  </h6>{" "}
                                </Link>
                              </Col>

                              <Col xs="12">
                                <span className="word-wrap">{profile.bio}</span>
                              </Col>
                              <Col xs="12">
                                {profile.company}{" "}
                                <i className="nc-icon nc-pin-3" />{" "}
                                {profile.location}{" "}
                              </Col>
                              <Col
                                xs="12"
                                className="text-right text-secondary pr-md-5 mr-md-5"
                              >
                                <Moment format="DD MMM YYYY">
                                  {moment.utc(profile.createdAt)}
                                </Moment>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <hr />
                        </div>
                      </div>
                    ))}
                  </CardBody>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
}

Experience.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  getProfiles: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(mapStateToProps, { getProfiles, getCurrentProfile })(
  Experience
);
