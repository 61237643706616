import * as firebase from "firebase/app";
import "firebase/analytics";
var firebaseConfig = {
  apiKey: "AIzaSyAL3_XegOdvdihivpL1-5YwXHiEcbjzFsg",
  authDomain: "zapplabs-888ba.firebaseapp.com",
  databaseURL: "https://zapplabs-888ba.firebaseio.com",
  projectId: "zapplabs-888ba",
  storageBucket: "zapplabs-888ba.appspot.com",
  messagingSenderId: "76078370435",
  appId: "1:76078370435:web:405a5639895d97ebc995b6",
  measurementId: "G-KPR3MPM23K",
};
// Initialize firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();
export default firebase;
