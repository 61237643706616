import React from "react";
import SimpleReactValidator from "simple-react-validator";
import Config from "../../config";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import { setAlert } from "../../actions/alert.actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addEducation, deleteEducation } from "../../actions/profile";
import { Link } from "react-router-dom";
import Alert from "../../layouts/Alert";
import Spinner from "../../layouts/Spinner";
import Moment from "react-moment";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";

class Education extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      degree: "",
      school: "",
      fieldofstudy: "",
      current: false,
      from: "",
      to: "",
      education: [],
      description: "",
      isEditable: false,
      isUpdating: false,
      isLoading: true,
    };
    this.validator = new SimpleReactValidator();
  }

  async componentWillMount() {
    console.log(this.props.profile);
    this.props.profile &&
      (await this.setState({
        education: this.props.profile.education,
      }));
    // this.props.profile.education.length === 0 &&
    //   this.setState({ isEditable: true });
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  async handleExperienceSubmitButtonClick(e) {
    e.preventDefault();
    console.log("handleExperienceSubmitButtonClick");
    if (this.validator.allValid()) {
      // get form data from react state object
      let {
        degree,
        school,
        fieldofstudy,
        current,
        from,
        to,
        description,
      } = this.state;

      let body = {
        userid:this.props.userid,
        degree,
        school,
        fieldofstudy,
        current,
        from,
        to,
        description,
      };
      //make user signup request
      await this.setState({ isUpdating: true });

      try {
        const res = await axios.put(
          Config.hostName + "/api/profile/education",
          body
        );

        await this.setState({ isUpdating: false, isEditable: false });
        console.log(res.data);
        this.setState({ education: res.data.education });
        this.props.getProfile();
      } catch (err) {
        const errors =
          err.response && err.response.data && err.response.data.errors;
        await this.setState({ isUpdating: false });
        this.props.getProfile();

        if (errors) {
          errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }
    return (
      <>
        <div
          className="content bg-white shadow"
          style={{
            marginTop: 28,
          }}
        >
          <div className="p-3 ">
            {this.state.isEditable === false && (
              <Row>
                <Col md="6" xs="5">
                  EDUCATION
                </Col>
                <Col md="6" xs="7" className="text-right">
                  {this.props.profile &&
                    this.props.auth.user.id === this.props.profile.user._id && (
                      <Link
                        onClick={async (e) =>
                          await this.setState({ isEditable: true })
                        }
                      >
                        <PlusOutlined />
                      </Link>
                    )}
                </Col>
              </Row>
            )}
          </div>
          <Row>
            {!this.state.isEditable && (
              <Col md="12">
                <div className="border">
                  <CardBody>
                    {this.state.education.length === 0 ? (
                     <div>
                     <h5>No education(s).</h5>
                   </div>
                    ) : (
                      // <div className="h6">No Education</div>
                      this.state.education.map((edu) => (
                        <div className="row">
                          <div className="col-xs-3 col-md-2">
                            <a
                              href="#pablo"
                              className="text-center"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                className="img-thumbnail  border-gray"
                                style={{
                                  minWidth: 50,
                                  maxWidth: 80,
                                }}
                                src={
                                  "https://image.freepik.com/free-vector/flat-university-vector_23-2147501131.jpg"
                                }
                              />
                            </a>
                          </div>
                          <div className="col-xs-9 col-md-10">
                            <div>
                              <Row>
                                <Col xs="12">
                                  <span className="text-capitalize h5 title">
                                    {edu.school}
                                  </span>{" "}
                                  {this.props.profile &&
                                    this.props.auth.user.id ===
                                      this.props.profile.user._id && (
                                      <Link
                                        style={{ color: "blue" }}
                                        onClick={async (e) => {
                                          await this.props.deleteEducation(
                                            edu._id,
                                            this.props.userid
                                          );
                                          window.location.reload();
                                        }}
                                      >
                                        <i className="fa fa-remove" /> Delete
                                      </Link>
                                    )}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs="12">
                                  <h6 className="text-capitalize">
                                    {edu.degree}
                                  </h6>
                                  {edu.grade && <span>{edu.grade} Grade</span>}
                                </Col>
                                <Col xs="12" className=" text-secondary">
                                  <Moment format="MMM YYYY">
                                    {moment.utc(edu.from)}
                                  </Moment>{" "}
                                  -{" "}
                                  {edu.current ? (
                                    "Present"
                                  ) : (
                                    <Moment format="MMM YYYY">
                                      {moment.utc(edu.to)}
                                    </Moment>
                                  )}
                                </Col>
                                <Col xs="12">{edu.description}</Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </CardBody>
                </div>
              </Col>
            )}
            {this.state.isEditable && (
              <Col md="12">
                <Card className="card-user">
                  <CardHeader>
                    <Row>
                      <Col xs="7">
                        <CardTitle tag="h5">Add Education</CardTitle>
                      </Col>
                      <Col xs="5" className="text-right">
                        <Button
                          className="btn primary"
                          onClick={async (e) =>
                            await this.setState({ isEditable: false })
                          }
                        >
                          CANCEL
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <form
                      onSubmit={(e) =>
                        this.handleExperienceSubmitButtonClick(e)
                      }
                    >
                      <Alert />
                      <div className="row">
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label">
                              Degree Name
                            </Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="text"
                              name="degree"
                              value={this.state.degree}
                              placeholder="Ex (B.Sc., MCA, or B.A.)"
                              onChange={(event) =>
                                this.setState({
                                  degree: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "degree",
                                this.state.degree,
                                "required"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">College</Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="text"
                              name="school"
                              value={this.state.school}
                              placeholder="Ex. Delhi University Delhi"
                              onChange={(event) =>
                                this.setState({
                                  school: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "college",
                                this.state.school,
                                "required"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">Grade</Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="text"
                              name="fieldofstudy"
                              value={this.state.fieldofstudy}
                              placeholder="Ex I Div, II Div"
                              onChange={(event) =>
                                this.setState({
                                  fieldofstudy: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "fieldofstudy",
                                this.state.fieldofstudy,
                                "required"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">Start Year</Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="month"
                              name="from"
                              value={this.state.from}
                              onChange={(event) =>
                                this.setState({ from: event.target.value })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "from",
                                this.state.from,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label">End Year</Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="month"
                              name="to"
                              disabled={this.state.current ? true : false}
                              value={this.state.to}
                              onChange={(event) =>
                                this.setState({ to: event.target.value })
                              }
                            />
                            {/* <div className="text-danger">
                              {this.validator.message(
                                "to",
                                this.state.to,
                                "required"
                              )}
                            </div> */}
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input
                                style={{
                                  borderRight: "none",
                                  borderLeft: "none",
                                  borderTop: "none",
                                }}
                                type="checkbox"
                                id="Current"
                                checked={this.state.current}
                                onChange={(e) =>
                                  this.setState({
                                    current: !this.state.current,
                                  })
                                }
                                name="current"
                              />{" "}
                              Current
                              <span className="form-check-sign">
                                <span className="check"></span>
                              </span>
                            </Label>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">
                              D escription
                            </Label>
                            <textarea
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="text"
                              name="description"
                              value={this.state.description}
                              // placeholder="Description"
                              onChange={(event) =>
                                this.setState({
                                  description: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "description",
                                this.state.description,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </div>
                      </div>

                      <Row>
                        <div className="update ml-auto mr-auto">
                          <Button className="primary" type="submit">
                            {this.state.isUpdating ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </div>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      </>
    );
  }
}

Education.propTypes = {
  setAlert: PropTypes.func.isRequired,
  deleteEducation: PropTypes.func.isRequired,
  addEducation: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setAlert,
  addEducation,
  deleteEducation,
})(Education);
