import React from "react";
import SimpleReactValidator from "simple-react-validator";
import Config from "../../config";
// reactstrap components
import { Select, Tag } from "antd";
import "antd/dist/antd.css";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import { setAlert } from "../../actions/alert.actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentProfile } from "../../actions/profile";
import { Link } from "react-router-dom";
import Alert from "../../layouts/Alert";
import Spinner from "../../layouts/Spinner";
const { Option } = Select;
const children = [
  "Node",
  "React",
  "Javascript",
  "java",
  "PHP",
  "Ruby",
  "C#",
  "C / C++",
  "SQL",
  "PL/SQL",
  "ASP .NET",
  "Objective-C",
  "Visual Basic",
];
// tools technologies,libraries,framework
const tools = [
  "Bootstrap",
  "Typescript",
  "D3.js",
  "XHTML",
  "HTML5",
  "XML",
  "XSL",
  "XPath",
  "XQuery",
  "SAX",
  "DOM",
  "StAX",
  "TinyMCE",
  "JW Player",
  "Highcharts",
  "amCharts",
  "Modernizr",
  "Oauth2 security",
  "Omniauth security",
];
const interpersonal = [
  "Self-Confidence",
  "Leadership",
  "Work-Ethic",
  "Conflict-Management",
];
class Skills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditable: false,
      isUpdating: false,
      isLoading: true,
      style: {},
      skill: [],
      technologies: [],
      interpersonalSkills: [],
    };
    this.validator = new SimpleReactValidator();
  }

  async componentWillMount() {
    this.props.profile && this.props.profile.skillsData
      ? await this.setState({
          skill: this.props.profile.skillsData.skill,
          technologies: this.props.profile.skillsData.technologies,
          interpersonalSkills: this.props.profile.skillsData
            .interpersonalSkills,
        })
      : this.setState({ isEditable: true });
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }
  skillHandleChange = (values) => {
    // console.log("selected values", values)
    this.setState({ skill: values });
  };
  technologiesHandleChange = (values) => {
    this.setState({ technologies: values });
  };
  interpersonalSkillsHandleChange = (values) => {
    this.setState({ interpersonalSkills: values });
  };
  async handleSubmitButtonClick(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      // get form data from react state object
      let { skill, technologies, interpersonalSkills } = this.state;
      let body = {
        userid:this.props.userid,
        skill,
        technologies,
        interpersonalSkills,
      };
      //make user signup request
      await this.setState({ isUpdating: true });
      console.log("data", body);
      try {
        const res = await axios.put(
          Config.hostName + "/api/profile/skills",
          body
        );
        console.log(res.data);
        this.props.getProfile();

        await this.setState({ isUpdating: false, isEditable: false });
      } catch (err) {
        const errors =
          err.response && err.response.data && err.response.data.errors;
        await this.setState({ isUpdating: false });
        this.props.getProfile();
        if (errors) {
          errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }
    console.log(this.state);

    return (
      <>
        <div
          className="content bg-white shadow"
          style={{
            marginTop: 28,
          }}
        >
          <div className="p-3 ">
            {this.state.isEditable === false && (
              <Row>
                <Col xs="9">SKILLS & ENDORSEMENTS</Col>
                <Col xs="3" className="text-right">
                  {this.props.profile &&
                    this.props.auth.user.id === this.props.profile.user._id && (
                      <Link
                        style={{ color: "blue" }}
                        onClick={async (e) =>
                          await this.setState({ isEditable: true })
                        }
                      >
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/img/landing-page/editicon.svg"
                          }
                          width="15%"
                          className="rounded"
                        />
                        {/* <i className="fa fa-edit" /> Edit */}
                      </Link>
                    )}
                </Col>
              </Row>
            )}
          </div>
          <Row>
            {this.state.isEditable === false && (
              <Col md="12">
                <div className="border">
                  <CardBody>
                    <div className="row">
                      <div className="col-xs-12">
                        <div className="container">
                          <p>
                            {this.state.skill && (
                              <div className="row">
                                <div className="col-3 mt-2">Skills</div>
                                <div className="col-9 mt-2">
                                  {this.state.skill.map((i) => {
                                    return (
                                      <Tag>
                                        {i}
                                        {"  "}
                                      </Tag>
                                    );
                                  })}
                                </div>
                                <div className="col-3 mt-2">
                                  Tools and Technologies
                                </div>
                                <div className="col-9 mt-2">
                                  {this.state.technologies.map((i) => {
                                    return (
                                      <Tag>
                                        {i}
                                        {"  "}
                                      </Tag>
                                    );
                                  })}
                                </div>
                                <div className="col-3 mt-2">
                                  Interpersonal Skills
                                </div>
                                <div className="col-9 mt-2">
                                  {this.state.interpersonalSkills.map((i) => {
                                    return (
                                      <Tag>
                                        {i}
                                        {"  "}
                                      </Tag>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </div>
              </Col>
            )}
            {this.state.isEditable && (
              <Col md="12">
                <Card className="card-user ">
                  <CardHeader className="container">
                    <Row>
                      <Col xs="8">
                        <CardTitle tag="h5">Skills</CardTitle>
                      </Col>
                      <Col xs="4">
                        <Button
                          className="btn primary"
                          // color="primary"
                          onClick={async (e) =>
                            await this.setState({ isEditable: false })
                          }
                        >
                          CANCEL
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <form onSubmit={(e) => this.handleSubmitButtonClick(e)}>
                      <Alert />
                      <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label">Skills</Label>
                            <Select
                              mode="tags"
                              //   size={size}
                              placeholder="Please select"
                              defaultValue={this.state.skill}
                              // defaultValue={['node', 'react.js']}
                              onChange={this.skillHandleChange}
                              style={{ width: "100%" }}
                            >
                              {children.map((i) => {
                                return <Option key={i}>{i}</Option>;
                              })}
                            </Select>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">
                              {/* Work Location */}
                              Tools and Technologies
                            </Label>
                            <Select
                              mode="tags"
                              //   size={size}
                              placeholder="Please select"
                              // defaultValue={['Bootstrap', 'Typescript']}
                              defaultValue={this.state.technologies}
                              onChange={this.technologiesHandleChange}
                              style={{ width: "100%" }}
                            >
                              {tools.map((i) => {
                                return <Option key={i}>{i}</Option>;
                              })}
                            </Select>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">
                              Interpersonal Skills
                            </Label>
                            <Select
                              mode="tags"
                              //   size={size}
                              placeholder="Please select"
                              // defaultValue={['Self-Confidence']}
                              defaultValue={this.state.interpersonalSkills}
                              onChange={this.interpersonalSkillsHandleChange}
                              style={{ width: "100%" }}
                            >
                              {interpersonal.map((i) => {
                                return <Option key={i}>{i}</Option>;
                              })}
                            </Select>
                          </FormGroup>
                        </div>
                        <div className="col-md-6"></div>
                      </div>

                      <Row>
                        <div className="update ml-auto mr-auto">
                          <Button className="primary" type="submit">
                            {this.state.isUpdating ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </div>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      </>
    );
  }
}

Skills.propTypes = {
  setAlert: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setAlert,
  getCurrentProfile,
})(Skills);
