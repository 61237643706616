import React from "react";

class ContactUs extends React.Component {
  render() {
    var data = [
      {
        type: "Mobile",
        caps: [
          {
            img: "/img/capabilities/frontend/reactjs.png",
            title: "React Native",
          },
          { img: "/img/capabilities/mobile/android.png", title: "Android" },
          { img: "/img/capabilities/mobile/pwa.png", title: "PWA" },
        ],
      },
      {
        type: "Desktop",
        caps: [
          {
            img: "/img/capabilities/desktop/electron.png",
            title: "ElectronJS",
          },
        ],
      },
      {
        type: "E-commerce",
        caps: [
          {
            img: "/img/capabilities/ecommerce/shopify.png",
            title: "Shopify",
          },
        ],
      },
      {
        type: "Back-End",
        caps: [
          { img: "/img/capabilities/backend/node.jpeg", title: "Node.js" },
          { img: "/img/capabilities/backend/php.png", title: "PHP" },
          { img: "/img/capabilities/backend/python.png", title: "Python" },
          { img: "/img/capabilities/backend/java.png", title: "Java" },
        ],
      },
      {
        type: "Front-End",
        caps: [
          { img: "/img/capabilities/frontend/reactjs.png", title: "ReactJS" },
          { img: "/img/capabilities/frontend/angular.png", title: "AngularJS" },
          { img: "/img/capabilities/frontend/js.png", title: "Javascript" },
          { img: "/img/capabilities/frontend/vue.png", title: "VueJS" },
        ],
      },

      {
        type: "Cloud",
        caps: [
          {
            img: "/img/capabilities/cloud/aws.png",
            title: "AWS",
          },
          {
            img: "/img/capabilities/cloud/googleCloud.png",
            title: "Google Cloud",
          },
          {
            img: "/img/capabilities/cloud/digital_ocean.jpg",
            title: "Digital Ocean",
          },
          { img: "/img/capabilities/cloud/docker.png", title: "Docker" },
          { img: "/img/capabilities/cloud/ecs.png", title: "ECS" },
          { img: "/img/capabilities/cloud/heroku.webp", title: "Heroku" },
        ],
      },
    ];
    return (
      <section className="mt-2" id="our-capabilities">
        <div
          style={{
            paddingTop: "2vw",
            paddingBottom: "8vw",
          }}
        >
          <div className="container">
            <h2 className="header-title pt-3 pb-3 text-left">
              Our Capabilities
            </h2>
            <div className="row">
              {data.map((d) => (
                <div className="col-md-4 p-3">
                  <h4 className="work-box-title pb-2">{d.type}</h4>
                  {d.caps.map((cap) => (
                    <div className="pt-3">
                      <div className="d-inline-block">
                        <img src={cap.img} className="cap" alt={cap.title} />
                      </div>
                      <div className="d-inline-block">
                        <p className="work-box-desc">{cap.title} </p>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactUs;
