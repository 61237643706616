import React from "react";
import { Helmet } from "react-helmet";
class ClientList extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>ZappLabs | Our Clients</title>
          <meta name="description" content="Pricing" />
        </Helmet>

        <section className="pb-5 mb-5 pt-5 container">
          <h2 className="title header-title text-center">Clients</h2>

          <div className="row">
            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/landing-page/ttakeoff.png"
                  alt="ttakeoff"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    TTakeoff is an IT Products & Services company founded by a
                    group of Travel Technology professionals with a varied, rich
                    International experience of several decades each.
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center pt-2">
              <div className="type-of-work-box">
                <img
                  // className="pt-2"
                  src="img/landing-page/logicarts.png"
                  alt="ttakeoff"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Logicarts is an award-winning single aggregator platform
                    efficiently solving major Logistics problems in south India.
                  </h5>
                  <br />
                </div>
              </div>
            </div>{" "}
            <div className="col-md-4 text-center pt-2">
              <div className="type-of-work-box">
                <img
                  // className="pt-2"
                  style={{ maxWidth: "20vh" }}
                  src="img/landing-page/opdlift.png"
                  alt="opdlift"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    OPDlift is a healthcare company that partners with clinics &
                    hospitals to enable telemedicine and manage clinic
                    consultations.{" "}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center pt-2">
              <div className="type-of-work-box">
                <img
                  // className="pt-2"
                  // style={{ maxWidth: "20vh" }}
                  src="img/landing-page/airprobe.png"
                  alt="opdlift"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    A team of Solar PV Professionals specialized for. Aerial
                    Thermographic Inspection. for Solar PV. features.{" "}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center pt-2">
              <div className="type-of-work-box">
                <img
                  // className="pt-2"
                  style={{ maxWidth: "13vh" }}
                  src="img/landing-page/soulslings.png"
                  alt="opdlift"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Soul Aseema Baby Carriers are a full wrap conversion soft
                    structured adjustable carrier, which can be used from birth
                    to toddlerhood
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ClientList;
