import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Row, Col } from "reactstrap";
import AvatarLoading from "../../layouts/AvatarLoading";
import { search, filterProfiles, getProfiles } from "../../actions/profile";
import ProfileComponent from "../../components/profiles";
import { Checkbox, Pagination, Input } from "antd";
import { SearchOutlined } from '@ant-design/icons';

import { Skills } from "../../constants/vars";
class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skip: 1,
      title: "",
      typeOfEmployment: "",
      skills: [],
    };
  }
  componentWillMount() {
    this.getDevs();
  }
  getDevs = async () => {
    let { title, skip, skills } = this.state;
    if (skills.length === 0) {
      const profile = {
        title,
        skip: (skip - 1) * 10,
      };
      this.props.search(profile);
    } else {
      const profile = {
        skills,
        skip: (skip - 1) * 10,
      };
      this.props.filterProfiles(profile);
    }
  };

  handleSearch = async () => {
    this.setState({ skills: [], skip: 1 }, () => this.getDevs());
  };

  handleFilter = async () => {
    let { skills } = this.state;

    const profile = {
      skills,
      skip: 1,
    };
    if (skills.length === 0) this.getDevs();
    else this.setState({ skip: 1 }, () => this.props.filterProfiles(profile));
  };

  render() {
    var skillsData = [];
    Skills.map((skill) => {
      return skillsData.push({ label: skill, value: skill });
    });
    return (
      <div>
        <section style={{ backgroundColor: "#F7F7F7" }}>
          <div className="container">
            <div
              className="row no-gutters "
              style={{
                paddingTop: "4vw",
                paddingBottom: "4vw",
              }}
            >
              <div className="col-md-12">
                <section className=" rounded text-left">
                  {/* <div className="h4 title ">Search by skills, Company</div> */}
                  <div style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", backgroundColor: "white", padding: 0
                  }}>
                    {/* <div className="form-group col-md-9 pr-md-1" > */}
                    {/* <label>Skill, Company, Title</label> */}

                    < Input
                      type="text"
                      style={{ border: "none" }}
                      prefix={<SearchOutlined style={{ marginTop: 25, marginLeft: 20 }} />}
                      suffix={
                        <Button
                          style={{ borderRadius: 10, background: "rgba(54, 106, 239, 0.99)", color: "white", padding: 10 }}

                          onClick={() => this.handleSearch()}
                          disabled={this.props.profile.loading}
                        >
                          {this.props.profile.loading
                            ? "loading"
                            : "Search now"}
                        </Button>
                      }
                      className="form-control"
                      value={this.state.title}
                      // placeholder="Enter title"
                      onChange={(event) =>
                        this.setState({ title: event.target.value })
                      }
                    />
                    <div className="text-danger"></div>
                    {/* </div> */}

                    <div className="form-group col-md-3 pt-3 mt-1">

                    </div>
                  </div>
                </section>
                <br />
                <div className={"text-center"}>
                  <Pagination
                    current={this.state.skip}
                    onChange={(page) => {
                      this.setState({ skip: page }, () => this.getDevs());
                    }}
                    simple
                    defaultCurrent={this.state.skip}
                    total={500}
                  /><br />
                </div>

              </div>
              <div className="col-md-3 pr-md-2 ">
                <section className="border mb-3 bg-white rounded p-3 text-left">
                  <span className="h4 title ">Filters</span>{" "}
                  {this.state.skills.length > 0 && (
                    <Button
                      color="link"
                      // className="primary"
                      style={{ color: "blue" }}
                      size="sm"
                      onClick={() => {
                        this.setState({ skills: [], skip: 1 }, () =>
                          this.getDevs()
                        );
                      }}
                    >
                      Clear Filters
                    </Button>
                  )}
                  <div className="form-group">
                    <label>Skills</label>
                    <div>
                      <Checkbox.Group
                        onChange={(values) =>
                          this.setState(
                            {
                              ...this.state,
                              skills: values,
                            },
                            () => {
                              this.handleFilter();
                            }
                          )
                        }
                        // options={skillsData}
                        value={this.state.skills}
                      >
                        <Row>
                          {Skills.map((skill) => (
                            <Col xs={12}>
                              <Checkbox value={skill}>{skill}</Checkbox>
                            </Col>
                          ))}
                        </Row>
                      </Checkbox.Group>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-md-9">


                {this.props.profile.loading ? (
                  <AvatarLoading />
                ) : (
                    <>
                      {this.props.profile.profiles && (
                        <div>
                          {/* //     {this.props.profile.profiles.length} Search results */}
                        </div>
                      )}
                      {this.props.profile.profiles === null ? (
                        ""
                      ) : this.props.profile.profiles.length === 0 ? (
                        <div className="title h3">No profiles</div>
                      ) : (
                            this.props.profile.profiles.map((profile) => (
                              <div>
                                <ProfileComponent
                                  history={this.props.history}
                                  profile={profile}
                                />
                              </div>
                            ))
                          )}
                    </>
                  )}
              </div>
            </div>
            {/* <img
              alt=""
              src={process.env.PUBLIC_URL + "/img/landing-page/wave.png"}
              width="100%"
              height="30"
            /> */}
          </div>
        </section>
        {/* <section className="pt-5 pb-5 mt-5 mb-5 text-center container">
          <h2 className="pt-2 title ">
            The goal isn’t to build a website. The goal is to build your
            business.
          </h2>
        </section> */}
      </div >
    );
  }
}

LandingPage.propTypes = {
  getProfiles: PropTypes.func.isRequired,
  filterProfiles: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  search,
  getProfiles,
  filterProfiles,
})(LandingPage);
