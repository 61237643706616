import React from "react";
import SimpleReactValidator from "simple-react-validator";
// import ProfileCard from './ProfileCard'
// reactstrap components
import { Input } from "antd";
import {
  MailOutlined,
  LockOutlined,
  UserOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

import { login } from "../../actions/user.actions";
import { Button, CardBody, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { register, socialLogin } from "../../actions/user.actions";
import { Redirect, Link } from "react-router-dom";
import Alert from "../../layouts/Alert";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase";
import { Helmet } from "react-helmet";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      password: "",
      style: {},
      isSignedIn: false,
    };
    this.validator = new SimpleReactValidator();
    this.handleSignupButtonClick = this.handleSignupButtonClick.bind(this);
  }
  uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // Fire.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => {
        firebase.auth().onAuthStateChanged(() => {
          var user = firebase.auth().currentUser;
          console.log("user ", user);
          // this.props.socialLogin(user.displayName, user.email, user.phoneNumber, user.uid, "user")
          const userDetail = {
            name: user.displayName,
            mobile: user.phoneNumber,
            email: user.email,
            userType: "user",
          };
          this.props.socialLogin(userDetail);

          // this.props.login("randomtoken@gmail.com", "password", "user")
        });
      },
    },
  };
  requestProfile = () => {
    var oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=817f92qz0dobo5&scope=r_liteprofile&state=123456&redirect_uri=http://localhost:3000/`;
    var width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;
    // this.props.login("randomtoken@gmail.com", "password", "user")

    window.open(
      oauthUrl,
      "Linkedin",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ style: { display: "none" } });
      }.bind(this),
      1000
    );
  }

  handleSignupButtonClick(e) {
    // prevent default form submission
    e.preventDefault();
    if (this.validator.allValid()) {
      let { name, phone, email, password } = this.state;

      //make user signup request
      console.log(this.props.register(name, phone, email, password, "user"));
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  render() {
    if (this.props.auth.user) {
      if (this.props.auth.user.userType === "user")
        return (
          <Redirect
            to={
              "/user/profile/" + this.props.auth.user.id &&
              this.props.auth.user.id
            }
          />
        );
      if (this.props.auth.user.userType === "admin") return <Redirect to="/" />;
    }
    return (
      <>
        <Helmet>
          <title>Zapplabs | Register</title>
          <meta name="description" content="Register" />
        </Helmet>
        <div className="jumbotron bg-white">
          {/* <h2 className="text-center">Sign Up</h2>
           */}
          <Alert />

          <Row>
            <Col md="4"></Col>
            <Col
              md="4"
              className="border ml-3 m-0"
              style={{ maxWidth: "380px" }}
            >
              <div className="card-user">
                <Row>
                  <Col
                    style={{
                      backgroundColor: "#007dff",
                      padding: 10,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <Link to="/user/login" style={{ color: "white" }}>
                        Log in
                      </Link>
                    </div>
                  </Col>
                  <Col
                    style={{
                      padding: 10,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      {/* <Link to="/user/register" style={{ color: "black" }} > */}
                      Sign up
                      {/* </Link> */}
                    </div>
                  </Col>
                </Row>

                <CardBody>
                  <form className="theme-form">
                    <div className="form-group">
                      <Input
                        style={{
                          // backgroundColor: "rgba(196, 196, 196, 0.2)",
                          borderRight: "none",
                          borderLeft: "none",
                          borderTop: "none",
                        }}
                        prefix={<UserOutlined />}
                        type="text"
                        required
                        className="form-control"
                        placeholder="Name"
                        onFocus={(e) => this.validator.showMessageFor("name")}
                        onChange={(event) =>
                          this.setState({ name: event.target.value })
                        }
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "name",
                          this.state.name,
                          "required|min:3|regex:(^[a-zA-Z ]*$)"
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <Input
                        style={{
                          // backgroundColor: "rgba(196, 196, 196, 0.2)",
                          borderRight: "none",
                          borderLeft: "none",
                          borderTop: "none",
                        }}
                        type="text"
                        required
                        prefix={<PhoneOutlined />}
                        className="form-control"
                        placeholder="Phone Number"
                        onFocus={(e) => this.validator.showMessageFor("phone")}
                        onChange={(event) =>
                          this.setState({ phone: event.target.value })
                        }
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "phone",
                          this.state.phone,
                          "required|phone"
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <Input
                        style={{
                          // backgroundColor: "rgba(196, 196, 196, 0.2)",
                          borderRight: "none",
                          borderLeft: "none",
                          borderTop: "none",
                        }}
                        type="text"
                        required
                        prefix={<MailOutlined />}
                        className="form-control"
                        placeholder=" Email"
                        onChange={(event) =>
                          this.setState({ email: event.target.value })
                        }
                      />

                      <div className="text-danger">
                        {this.validator.message(
                          "email",
                          this.state.email,
                          "required|email"
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <Input
                        style={{
                          // backgroundColor: "rgba(196, 196, 196, 0.2)",
                          borderRight: "none",
                          borderLeft: "none",
                          borderTop: "none",
                        }}
                        type="password"
                        required
                        prefix={<LockOutlined />}
                        className="form-control"
                        placeholder="Password"
                        onChange={(event) =>
                          this.setState({ password: event.target.value })
                        }
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "password",
                          this.state.password,
                          "required|min:6"
                        )}
                      </div>
                    </div>

                    {/* <Col md="1""> */}
                    <Button
                      style={{ width: "50%", marginLeft: 70 }}
                      className="btn primary"
                      onClick={this.handleSignupButtonClick}
                    >
                      Sign Up
                    </Button>
                    {/* <Row>
                          <Col ><strong><hr width="100%" style={{ marginTop: 10 }} /></strong></Col>
                          <Col ><strong>or</strong></Col>
                          <Col ><hr width="100%" style={{ marginTop: 10 }} /></Col>
                        </Row> */}
                    <StyledFirebaseAuth
                      uiConfig={this.uiConfig}
                      firebaseAuth={firebase.auth()}
                    />
                  </form>
                </CardBody>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

User.propTypes = {
  register: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  socialLogin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { register, login, socialLogin })(User);
