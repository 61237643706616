import React from "react";
import SimpleReactValidator from "simple-react-validator";
import firebase from "firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { Input } from "antd";
// reactstrap components
import { Button, CardBody,  Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { login, socialLogin } from "../../actions/user.actions";
import { setAlert } from "../../actions/alert.actions";
import { Redirect, Link } from "react-router-dom";
import Alert from "../../layouts/Alert";
import { Helmet } from "react-helmet";

// import ProfileCard from './ProfileCard'
class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      dob: "",
      style: {},
      isSignIn: false,

      isAuthorized: false,
      firstName: null,
      lastName: null,
      profileURL: null,
      pictureURL: null,
    };
    this.validator = new SimpleReactValidator();
    this.handleSignupButtonClick = this.handleSignupButtonClick.bind(this);
  }

  uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // Fire.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => {
        firebase.auth().onAuthStateChanged(() => {
          var user = firebase.auth().currentUser;

          console.log("user ", user.email, user.phoneNumber);
          const userDetail = {
            name: user.displayName,
            mobile: user.phoneNumber,
            email: user.email,
            userType: "user",
          };
          this.props.socialLogin(userDetail);
        });
      },
    },
  };
  authListener = () => {
    firebase.auth().onAuthStateChanged((user) => {
      // this.setState({ isSignIn: !user })
      // console.log
    });
  };

  handlePostMessage = (event) => {
    if (event.data.type === "profile") {
      console.log("data", event.data.profile);
      this.updateProfile(event.data.profile);
      alert(`Login successful: ${event.data.profile.localizedFirstName}`, {
        position: "top",
      });
    }
  };

  componentDidMount() {
    this.authListener();
    if (this.props.location.from === "pay for service") {
      this.props.setAlert("Please login first before pay.", "danger", 30000);
    }

    window.addEventListener("message", this.handlePostMessage);
    setTimeout(
      function () {
        this.setState({ style: { display: "none" } });
      }.bind(this),
      1000
    );
  }

  async handleSignupButtonClick(e) {
    // prevent default form submission
    e.preventDefault();
    if (this.validator.allValid()) {
      // get form data from react state object
      let { email, password } = this.state;

      this.props.login(email, password, "user");

      //make user signup request
      // await this.props.login(email, password, "user");
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  render() {
    if (this.props.auth.user) {
    return <Redirect to="/" />;
    }
    return (
      <>
        <Helmet>
          <title>Zapplabs | Login</title>
          <meta name="description" content="Login" />
        </Helmet>
        <div className="jumbotron bg-white">
          <Alert />

          <Row>
            <Col md="4"></Col>
            <Col
              md="4"
              className="border ml-3 m-0"
              style={{ maxWidth: "380px" }}
            >
              <div className="card-user">
                <Row>
                  <Col>
                    <div
                      style={{
                        padding: 10,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      Log in
                    </div>
                  </Col>
                  <Col
                    style={{
                      backgroundColor: "#007dff",
                      padding: 10,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <div
                      style={{ width: "100%" }}
                      // className="btn primary"
                      // onClick={this.handleSignupButtonClick}
                    >
                      <Link to="/user/register" style={{ color: "white" }}>
                        Sign up
                      </Link>
                    </div>
                  </Col>
                </Row>
                <CardBody>
                  <form className="theme-form">
                    <div className="form-group">
                      <label>Email</label>
                      <Input
                        style={{
                          // backgroundColor: "rgba(196, 196, 196, 0.2)",
                          borderRight: "none",
                          borderLeft: "none",
                          borderTop: "none",
                          outline: "none",
                        }}
                        type="text"
                        required
                        prefix={<MailOutlined />}
                        className="form-control"
                        placeholder=" Email"
                        onChange={(event) =>
                          this.setState({ email: event.target.value })
                        }
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "email",
                          this.state.email,
                          "required|email"
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Password</label>

                      <Input
                        style={{
                          // backgroundColor: "rgba(196, 196, 196, 0.2)",
                          borderRight: "none",
                          borderLeft: "none",
                          borderTop: "none",
                          outline: "none",
                        }}
                        type="password"
                        required
                        prefix={<LockOutlined />}
                        className="form-control"
                        placeholder="Password"
                        onChange={(event) =>
                          this.setState({ password: event.target.value })
                        }
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "password",
                          this.state.password,
                          "required|min:6"
                        )}
                      </div>
                    </div>

                    {/* <Col md="1""> */}
                    <Button
                      style={{ width: "50%", marginLeft: 70 }}
                      className="btn primary"
                      onClick={this.handleSignupButtonClick}
                    >
                      Log in
                    </Button>
                    {/* <div className="text-left mt-2">
                          Don't have an account?
                            <Link to="/user/register" className="btn-link ">
                            Register
                            </Link>
                        </div> */}

                    <div className="text-left mt-2">
                      <Link to="/reset-password/form" className="btn-link ">
                        Forgot Password?
                      </Link>
                    </div>
                    {/* <div> */}
                    {/* <Row>
                          <Col ><strong><hr width="100%" style={{ marginTop: 10 }} /></strong></Col>
                          <Col style={{ paddingRight: -10 }}>or </Col>
                          <Col style={{ paddingLeft: -90 }}><strong><hr width="100%" style={{ marginTop: 10 }} /></strong></Col>
                          <Col />
                        </Row> */}
                    {/* </div> */}
                    {/* <div className="col-12">Or Sign in with</div> */}
                    <StyledFirebaseAuth
                      uiConfig={this.uiConfig}
                      firebaseAuth={firebase.auth()}
                    />
                    {/* <div></div> */}
                  </form>
                </CardBody>
              </div>
            </Col>
            {/* <Col md="6" className=" border ">
                  <div
                    className="pt-sm-3 text-center"
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <StyledFirebaseAuth
                          uiConfig={this.uiConfig}
                          firebaseAuth={firebase.auth()}
                        />
                      </div>
                    </div> */}

            {/* <a
                        href="#"
                        title="LinkedIn"
                        className="btn btn-linkedin btn-lg"
                        onClick={this.requestProfile}
                        style={{
                          background: "#0E76A8",
                          borderRadius: 0,
                          color: "#fff",
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderColor: "#084461",
                          width: "100%",
                          align: "center"
                        }}
                      >
                        <i class="fa fa-linkedin fa-fw"></i> LinkedIn
                      </a> */}
            {/* </div> */}
            {/* </Col> */}
          </Row>
        </div>
      </>
    );
  }
}

User.propTypes = {
  setAlert: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  socialLogin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login, socialLogin, setAlert })(User);
