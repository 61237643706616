import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ClientList from "components/client-list";
import { Helmet } from "react-helmet";
import ContactUs from "components/contactus";
import GetDemoForm from "components/GetDemoForm";

class GetDemo extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Zapplabs | Ecommerce Pricing</title>
          <meta
            name="description"
            content="Ecommerce development monthly pricing."
          />
          <meta
            name="keyword"
            content="Ecommerce, development, monthly, pricing, Ecommerce App, IOS, Android."
          />
        </Helmet>
        <div style={{ backgroundColor: "#f5f4f9" }} className="mb-5">
          <section className="container pt-2">
            <div className="row " id="chatList">
              <div className="col-md-7">
                <div
                  style={{
                    paddingBottom: "8vw",
                  }}
                >
                  <h2 className="header-title pt-3 pb-3 text-left">
                    Pricing for Ecommerce website development
                  </h2>
                  <h4>
                    <i className="fa fa-dot-circle pink-icon" /> Services -
                  </h4>
                  <div className="row ">
                    {[
                      "Search feature",
                      "FrontEnd Support",
                      "Backend Support",
                      "Secure Payment Support",
                      "Manage Store",
                      "Admin panel",
                      "No locking period",
                    ].map((i) => (
                      <div className="col-md-6">
                        <h5>
                          <i className="fa fa-check pink-icon" /> {i}
                        </h5>
                      </div>
                    ))}
                  </div>

                  <div class="ui-card pt-4 pb-4 mt-3">
                    <h4 className="pt-2">INR 3,999/month + 18% GST</h4>
                  </div>
                  <h4 className="header-title pt-3">
                    INR 1500/month extra for Android Mobile App.
                  </h4>
                  <h4 className="header-title">
                    INR 1500/month extra for IOS Mobile App.
                  </h4>

                  <ContactUs />
                </div>
              </div>
              <div className="col-md-5 pb-5 pt-3">
                <GetDemoForm {...this.props} />
              </div>
            </div>
          </section>
        </div>
        <ClientList />
      </>
    );
  }
}

GetDemo.propTypes = { auth: PropTypes.object.isRequired };
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(GetDemo);
