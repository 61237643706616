import axios from "axios";
import { setAlert } from "./alert.actions";
import {
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  CLEAR_PROFILE,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  GET_USERS,
  UPDATE_USER,
  REMOVE_USER,
  LOGOUT,
  TRANSACTION_ERROR,
  CLEAR_ALERTS,
  LOADING,
  GET_SUCCESSFUL_TRANSACTIONS,
} from "../constants/actionTypes";
import Config from "../config";
import setAuthToken from "../utils/setAuthToken.js";

export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(Config.hostName + "/api/auth");

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = (name, phone, email, password, userType = "user") => (
  dispatch
) => {
  dispatch({
    type: CLEAR_ALERTS,
  });

  axios
    .post(Config.hostName + "/api/users", {
      name,
      phone,
      email,
      password,
      userType,
    })

    .then(
      userType === "user"
        ? dispatch(setAlert("Successfully Registered", "success"))
        : dispatch(
            setAlert("Successfully Registered, Kindly Login", "success")
          ),
      (error) => {
        const errors = error.response.data.errors;
        console.log(error);

        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }

        dispatch({
          type: REGISTER_FAIL,
        });
      }
    );
};

// Search user
export const search = (data) => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  dispatch({
    type: CLEAR_ALERTS,
  });

  try {
    var res = await axios.post(Config.hostName + "/api/users/search", data);

    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err.response.statusText);
    dispatch({
      type: TRANSACTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Search user
export const searchAllUser = (data) => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  dispatch({
    type: CLEAR_ALERTS,
  });

  try {
    var res = await axios.post(Config.hostName + "/api/users/search/all", data);

    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err.response.statusText);
    dispatch({
      type: TRANSACTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Search user
export const sendInvitation = (data) => async (dispatch) => {
  dispatch({
    type: CLEAR_ALERTS,
  });

  try {
    var res = await axios.post(
      Config.hostName + "/api/users/send-invitation",
      data
    );
    dispatch(setAlert("Updated", "success"));
    console.log(res.data);
  } catch (err) {
    console.log(err.response.statusText);
    dispatch({
      type: TRANSACTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const updateUser = (id, data) => async (dispatch) => {
  dispatch({
    type: CLEAR_ALERTS,
  });
  console.log(data);

  try {
    var res = await axios.put(Config.hostName + `/api/users/${id}`, data);
    console.log(data);
    dispatch({
      type: UPDATE_USER,
      payload: { _id: id, data: res.data },
    });
    dispatch(setAlert("Updated", "success"));
  } catch (err) {
    console.log(err.response.statusText);
    dispatch({
      type: TRANSACTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const deleteUser = (id) => async (dispatch) => {
  dispatch({
    type: CLEAR_ALERTS,
  });
  try {
    await axios.delete(Config.hostName + `/api/users/${id}`);

    dispatch({
      type: REMOVE_USER,
      payload: id,
    });

    dispatch(setAlert("User Removed", "success"));
  } catch (err) {
    dispatch({
      type: TRANSACTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Login User
export const login = (email, password, userType = "user") => async (
  dispatch
) => {
  try {
    dispatch({
      type: CLEAR_ALERTS,
    });

    console.log(email, password, userType);
    const res = await axios.post(Config.hostName + "/api/auth", {
      email,
      password,
      userType,
    });
    setAuthToken(res.data.token);
    await dispatch(loadUser());

    if (userType === "admin") {
      const job = {
        title: "",
        status: false,
        userType: "user",
        skip: 0,
      };
      dispatch(search(job));
    }
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const errors =
      err.response && err.response.data && err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// get all transactions of user
export const getSuccessfulTransactions = () => async (dispatch) => {
  try {
    const res = await axios.get(
      Config.hostName + "/api/transaction/successful/user"
    );
    console.log(res.data);
    dispatch({
      type: GET_SUCCESSFUL_TRANSACTIONS,
      payload: res.data,
    });
  } catch (err) {
    const errors =
      err.response &&
      err.response &&
      err.response.data &&
      err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: TRANSACTION_ERROR,
    });
  }
};
export const getAllSuccessfulTransactions = () => async (dispatch) => {
  try {
    const res = await axios.get(
      Config.hostName + "/api/transaction/successful/all"
    );
    console.log(res.data);
    dispatch({
      type: GET_SUCCESSFUL_TRANSACTIONS,
      payload: res.data,
    });
  } catch (err) {
    const errors =
      err.response &&
      err.response &&
      err.response.data &&
      err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: TRANSACTION_ERROR,
    });
  }
};

// Logout / Clear Profile
export const logout = () => (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: CLEAR_ALERTS });
  dispatch({ type: LOGOUT });
};

export const socialLogin = (user) => async (dispatch) => {
  console.log(user);
  try {
    const res = await axios.post(Config.hostName + "/api/auth/social", user);
    console.log(res.data);

    // dispatch(setUser());
    setAuthToken(res.data.token);

    await dispatch(loadUser());
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const errors =
      err.response &&
      err.response &&
      err.response.data &&
      err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};
