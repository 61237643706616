import React from "react";
import SimpleReactValidator from "simple-react-validator";

// reactstrap components
import { Button, CardBody, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { register } from "../../actions/user.actions";
import { Redirect, Link } from "react-router-dom";
import Alert from "../../layouts/Alert";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      password: "",
      phone: "",
      dob: "",
      style: {}
    };
    this.validator = new SimpleReactValidator();
    this.handleSignupButtonClick = this.handleSignupButtonClick.bind(this);
  }

  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ style: { display: "none" } });
      }.bind(this),
      1000
    );
  }

  handleSignupButtonClick(e) {
    // prevent default form submission
    e.preventDefault();
    if (this.validator.allValid()) {
      // get form data from react state object
      let { name, email, password } = this.state;

      //make user signup request
      console.log(this.props.register(name, email, password, "business"));
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  render() {
    if (this.props.auth.isAuthenticated)
      return <Redirect to="/business/home" />;

    return (
      <>
        <div className="jumbotron bg-white">
          <h2 className="text-center">Business Sign Up</h2>
          <Alert />
          <Row>
            <Col md="3"></Col>

            <Col md="6">
              <Row>
                <Col md="6" className="border">
                  <div className="card-user">
                    <CardBody>
                      <form className="theme-form">
                        <div className="form-group">
                          <input
                            type="text"
                            required
                            className="form-control"
                            placeholder="Enter Name"
                            onChange={event =>
                              this.setState({ name: event.target.value })
                            }
                          />
                          <div className="text-danger">
                            {this.validator.message(
                              "name",
                              this.state.name,
                              "required|min:6|regex:(^[a-zA-Z ]*$)"
                            )}
                          </div>
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            required
                            className="form-control"
                            placeholder="Enter email"
                            onChange={event =>
                              this.setState({ email: event.target.value })
                            }
                          />
                          <div className="text-danger">
                            {this.validator.message(
                              "email",
                              this.state.email,
                              "required|email"
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            required
                            className="form-control"
                            placeholder="********** (min 6 characters)"
                            onChange={event =>
                              this.setState({ password: event.target.value })
                            }
                          />
                          <div className="text-danger">
                            {this.validator.message(
                              "password",
                              this.state.password,
                              "required|min:6"
                            )}
                          </div>
                        </div>

                        {/* <Col md="1""> */}
                        <Button
                          style={{ width: "100%" }}
                          className="primary"
                          onClick={this.handleSignupButtonClick}
                        >
                          Sign Up
                        </Button>
                        <div className="text-left mt-2">
                          Already have an account?
                          <Link to="/business/login" className="btn-link ">
                            Login
                          </Link>
                        </div>
                      </form>
                    </CardBody>
                  </div>
                </Col>
                <Col
                  md="6"
                  className="d-none d-md-block  border "
                  style={{ margin: 0, padding: 0 }}
                >
                  {/* <div className="card-user">
                      <CardBody>
                        <form className="theme-form">
                          <Button
                            style={{ width: "100%" }}
                            color="primary"
                            round
                            outline
                          >
                            Sign Up with google
                          </Button>
                          <Button
                            style={{ width: "100%" }}
                            color="primary"
                            round
                            outline
                          >
                            Sign Up with google
                          </Button>
                          <Button
                            style={{ width: "100%" }}
                            color="primary"
                            round
                            outline
                          >
                            Sign Up with google
                          </Button>
                        </form>
                      </CardBody>
                    </div> */}
                  <img
                    alt=""
                    src="https://cdn1.vectorstock.com/i/1000x1000/57/50/sign-up-vector-4115750.jpg"
                    width="100%"
                    height="100%"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

User.propTypes = {
  register: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { register })(User);
