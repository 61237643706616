import React from "react";

class PortfolioProjects extends React.Component {
  render() {
    return (
      <>
        <section className="pb-5 mb-5 pt-5 container">
          <h2 className="title header-title text-center">Portfolio Projects</h2>

          {/* Edesy mobile app */}

          <div>
            <h4 className="header-title">Project: Edesy mobile application</h4>

            <h5 className="header-title">Overview: </h5>

            <p className="poppins work-box-desc">
              Edesy is an online course mobile app where anybody can record a
              video of their tutorial and upload the video in the application.
              They can set some price of the course and sell the course. They
              can also create their profile and list them as a trainer. Whoever
              need to learn something they can contact that person. We have
              integrated Razorpay payment gateway to make online transactions.
              The admin can see the list of all the users, their course videos,
              the students who has bought the courses.
            </p>

            <p>
              <h5 className="header-title">Technology used:</h5>
              <ul>
                <li className="work-box-desc">Node.js on the backend</li>
                <li className="work-box-desc">Mongodb as the database</li>
                <li className="work-box-desc">
                  React native for the mobile application
                </li>
                <li className="work-box-desc">
                  React native for the mobile application{" "}
                </li>
                <li className="work-box-desc">
                  Razorpay for the payment gateway
                </li>
                <li className="work-box-desc">
                  AWS serverless to host the backend in a cost effective way
                </li>
                <li className="work-box-desc">
                  Host the frontend using AWS amplify
                </li>
                <li className="work-box-desc">Store files in AWS S3</li>
                <li className="work-box-desc">
                  Server the files using AWS cloudfront
                </li>
              </ul>
            </p>
          </div>

          <div className="row">
            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/edesy/1.png"
                  alt="ttakeoff"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Showing list of courses on the home page
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/edesy/2.png"
                  alt="ttakeoff"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">Add course screen</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/edesy/3.png"
                  alt="ttakeoff"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    User profile screen
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/edesy/4.png"
                  alt="ttakeoff"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Update user profile
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/edesy/5.png"
                  alt="ttakeoff"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Add training class
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/edesy/6.png"
                  alt="ttakeoff"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Add questions and answers
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/edesy/7.png"
                  alt="ttakeoff"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Add course review screen
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/edesy/8.png"
                  alt="ttakeoff"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Showing videos and description
                  </h5>
                </div>
              </div>
            </div>
          </div>

          {/* Edesy online course website */}
          <br></br>
          <br></br>
          <br></br>

          <div>
            <h4 className="header-title">Project: Edesy website</h4>

            <h5 className="header-title">Overview: </h5>

            <p className="poppins work-box-desc">
              Edesy is an online course mobile app where anybody can record a
              video of their tutorial and upload the video in the application.
              They can set some price of the course and sell the course. They
              can also create their profile and list them as a trainer. Whoever
              need to learn something they can contact that person. We have
              integrated Razorpay payment gateway to make online transactions.
              The admin can see the list of all the users, their course videos,
              the students who has bought the courses. Once someone buy the
              courses then that person will be able to access those videos for
              lifetime.
            </p>

            <p>
              <h5 className="header-title">Technology used:</h5>
              <ul>
                <li className="work-box-desc">Node.js on the backend</li>
                <li className="work-box-desc">
                  React.js, HTML, css, Javascript, Redux on the frontend
                </li>
                <li className="work-box-desc">
                  Using google firebase for social login
                </li>
                <li className="work-box-desc">Razorpay for payment gateway</li>
                <li className="work-box-desc">
                  AWS serverless to host the backend in a cost effective way
                </li>
                <li className="work-box-desc">
                  Host the frontend using AWS amplify
                </li>
                <li className="work-box-desc">Store files in AWS S3</li>
                <li className="work-box-desc">
                  Serve the files using AWS cloudfront
                </li>
              </ul>
            </p>
          </div>
          <div className="row">
            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/edesy-web/course-page.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">View course page</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/edesy-web/get-demo.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Schedule a demo page
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/edesy-web/home-page.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Edesy website home page
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/edesy-web/login.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">User login page</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/edesy-web/trainer-profile.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Trainer profile page
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/edesy-web/trainers.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    List of trainers page
                  </h5>
                </div>
              </div>
            </div>
          </div>

          {/* zapplabs email marketing */}
          <br></br>
          <br></br>
          <br></br>

          <div>
            <h5 className="header-title">
              Project: Email marketing application
            </h5>

            <h5 className="header-title">Overview: </h5>

            <p className="poppins work-box-desc">
              Using the email marketing application someone can send bulk email
              using other service providers like AWS Simple Email Service (SES).
              Someone can add multiple credentials of multiple email service
              provider in the account and then send email using those
              credentials. You can create list of users whom you want to send
              email. Then add user to those list.
            </p>

            <p>
              <h5 className="header-title">Technology used:</h5>
              <ul>
                <li className="work-box-desc">Node.js on the backend</li>
                <li className="work-box-desc">
                  HTML, CSS, Javascript, React.js, Redux on the frontend
                </li>
                <li className="work-box-desc">
                  Google firebase for social login
                </li>
                <li className="work-box-desc">
                  Razorpay for payment gateway integration
                </li>
                <li className="work-box-desc">
                  AWS Simple Email Service, Sendgrid to send the email to users
                </li>
                <li className="work-box-desc">
                  AWS serverless for hosting the backend server
                </li>
                <li className="work-box-desc">
                  AWS amplify for hosting the frontend of the application
                </li>
                <li className="work-box-desc">AWS s3 for storing the files</li>
                <li className="work-box-desc">
                  AWS coudfront to serve the files
                </li>
              </ul>
            </p>
          </div>
          <div className="row">
            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/email-marketing/all-contacts.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    List of contacts page
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/email-marketing/contact-list.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Contact Lists page
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/email-marketing/credentials.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Email credentials add page
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/email-marketing/send-email.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">Make payment page</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/email-marketing/sign-in.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">User Sign in page</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/email-marketing/transactions.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    User transactions history page
                  </h5>
                </div>
              </div>
            </div>
          </div>

          {/* zapplabs leads generation */}
          <br></br>
          <br></br>
          <br></br>

          <div>
            <h4 className="header-title">Project: Email Finder Application</h4>

            <h5 className="header-title">Overview:</h5>

            <p className="poppins work-box-desc">
              We have created the application to generate any person’s email id
              from their LinkedIn profile url. You can go to that application
              and you can search any persons contact details like email using
              the persons email id. You will get some free credit to use the
              application. Then you can buy credit points to use the
              application. We are using Razorpay for payment gateway integration
            </p>

            <p>
              <h5 className="header-title">Technology used:</h5>
              <ul>
                <li className="work-box-desc">Node.js on the backend</li>
                <li className="work-box-desc">
                  HTML, CSS, Javascript, React.js, Redux on the frontend
                </li>
                <li className="work-box-desc">
                  Google firebase for social login
                </li>
                <li className="work-box-desc">
                  Razorpay for payment gateway integration
                </li>
                <li className="work-box-desc">
                  AWS Simple Email Service, Sendgrid to send the email
                </li>
                <li className="work-box-desc">
                  AWS serverless for hosting the backend server{" "}
                </li>
                <li className="work-box-desc">
                  AWS amplify for hosting the frontend of the application
                </li>
                <li className="work-box-desc">AWS s3 for storing the files</li>
                <li className="work-box-desc">
                  AWS cloudfront to serve the files
                </li>
              </ul>
            </p>
          </div>
          <div className="row">
            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/find-prospects/all-contact-lists.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Contact Lists page
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/find-prospects/new-image-1.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Showing contact details page
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/find-prospects/new-image-2.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Get email address from a domain
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/find-prospects/signin.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">User signin page</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/find-prospects/transactions.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    User transactions history page
                  </h5>
                </div>
              </div>
            </div>
          </div>

          {/* jobqo job portal */}
          <br></br>
          <br></br>
          <br></br>

          <div>
            <h5 className="header-title">
              Project: Jobqo Job portal application
            </h5>

            <h5 className="header-title">Overview:</h5>

            <p className="poppins work-box-desc">
              We have developed an in house job portal application. We are
              aggregating jobs from different portals and showing those jobs
              here in this website. To find the jobs we are scrapping the jobs
              from different job portals. Here user can see the list of jobs
              after they login to the portal, see the details of the jobs and
              then apply to those jobs from the portal. Any company can create
              the employer profile and then add the jobs on the portal. They can
              see the list of users applied on the jobs and then communicate
              with them.
            </p>

            <p>
              <h5 className="header-title">Technology used:</h5>
              <ul>
                <li className="work-box-desc">Node.js on the backend</li>
                <li className="work-box-desc">
                  HTML, CSS, Javascript, React.js, Redux on the frontend
                </li>
                <li className="work-box-desc">
                  Google firebase for social login
                </li>
                <li className="work-box-desc">
                  Razorpay for payment gateway integration
                </li>
                <li className="work-box-desc">
                  AWS Simple Email Service to send the email
                </li>
                <li className="work-box-desc">
                  AWS serverless for hosting the backend server{" "}
                </li>
                <li className="work-box-desc">
                  AWS amplify for hosting the frontend of the application
                </li>
                <li className="work-box-desc">AWS s3 for storing the files</li>
                <li className="work-box-desc">
                  AWS cloudfront to serve the files
                </li>
                <li className="work-box-desc">
                  Python to scrape the data from different portals
                </li>
              </ul>
            </p>
          </div>
          <div className="row">
            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/jobqo/applied-jobs.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">Applied job page</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/jobqo/home-page.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Showing list of jobs in the home page
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/jobqo/job-page.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">job details page</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/jobqo/login.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">user login page</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/jobqo/user-profile.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">user profile page</h5>
                </div>
              </div>
            </div>
          </div>

          {/* zapplabs online course subscription app */}
          <br></br>
          <br></br>
          <br></br>

          <div>
            <h5 className="header-title">
              Project: Online course application for training institutes
            </h5>

            <h5 className="header-title">Overview:</h5>

            <p className="poppins work-box-desc">
              We have developed an in house application for the training
              institutes where they can use the application to sell their
              training courses and charge for the course. We have a monthly
              subscription where their user can buy any course and then learn
              from those courses. We are using Razorpay payment gateway to
              accept the payment. We also have admin dashboard for the online
              course application to manage all the users and the courses
              properly
            </p>

            <p>
              <h5 className="header-title">Technology used:</h5>
              <ul>
                <li className="work-box-desc">
                  Node.js for backend development
                </li>
                <li className="work-box-desc">Mongodb for database</li>
                <li className="work-box-desc">
                  HTML, CSS, Javascript, React.js, Redux for frontend
                  development
                </li>
                <li className="work-box-desc">Google firebase for login</li>
                <li className="work-box-desc">
                  Razorpay for payment gateway integration
                </li>
                <li className="work-box-desc">
                  AWS Simple Email Service to send the email
                </li>
                <li className="work-box-desc">
                  AWS serverless for hosting the backend server{" "}
                </li>
                <li className="work-box-desc">
                  AWS amplify for hosting the frontend of the application
                </li>
                <li className="work-box-desc">AWS s3 for storing the files</li>
                <li className="work-box-desc">
                  AWS coudfront to serve the files
                </li>
              </ul>
            </p>
          </div>
          <div className="row">
            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/zapplabs-mobile-course-app/login-page.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">User login page</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/zapplabs-mobile-course-app/class-list.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Showing list of classes
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/zapplabs-mobile-course-app/course-video-list.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Showing list of videos in a course
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/zapplabs-mobile-course-app/video-page.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    Showing video page
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/zapplabs-mobile-course-app/transactions-page.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">User profile page</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center pt-2 ">
              <div className="type-of-work-box">
                <img
                  className="pt-2"
                  src="img/portfolio-projects/zapplabs-mobile-course-app/user-transactions-page.png"
                  alt="edesy website"
                />
                <div className="pt-3">
                  <h5 className="work-box-desc text-left">
                    User transactions page
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default PortfolioProjects;
