import React from "react";
import SimpleReactValidator from "simple-react-validator";
import Config from "../../config";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import Dropzone from "react-dropzone";
import axios from "axios";
import { setAlert } from "../../actions/alert.actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addExperience, deleteCertificate } from "../../actions/profile";
import { Link } from "react-router-dom";
import Alert from "../../layouts/Alert";
import Spinner from "../../layouts/Spinner";
import Moment from "react-moment";
import moment from "moment";

class Certificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      company: "",
      image: "",
      from: "",
      link: "",
      to: "",
      certificate: [],
      id: "",
      isEditable: false,
      isUpdating: false,
      isLoading: true,
    };
    this.validator = new SimpleReactValidator();
  }
  async onDrop({ files, name }) {
    let formData = new FormData();
    formData.append("images", files[0]);

    await axios
      .post(Config.hostName + "/api/profile/images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (res) => {
        this.setState({ image: res.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  async componentWillMount() {
    console.log(this.props.profile);
    this.props.profile &&
      (await this.setState({
        certificate: this.props.profile.certificate,
      }));
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  async handleExperienceSubmitButtonClick(e) {
    e.preventDefault();
    console.log("handleExperienceSubmitButtonClick");
    if (this.validator.allValid()) {
      // get form data from react state object
      let { title, company, image, link, from, to, id } = this.state;

      let body = {
        userid: this.props.userid,
        title,
        company,
        image,
        link,
        from,
        to,
        id,
      };
      //make user signup request
      await this.setState({ isUpdating: true });

      try {
        const res = await axios.put(
          Config.hostName + "/api/profile/certificate",
          body
        );

        await this.setState({ isUpdating: false, isEditable: false });
        console.log("certificatedata", res.data);
        this.setState({ certificate: res.data.certificate });
      } catch (err) {
        const errors =
          err.response && err.response.data && err.response.data.errors;
        await this.setState({ isUpdating: false });

        if (errors) {
          errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }
    return (
      <>
        <div
          className="content bg-white shadow"
          style={{
            marginTop: 28,
          }}
        >
          <div className="p-3 container-fluid">
            {this.state.isEditable === false && (
              <Row>
                <Col xs="5">CERTIFICATES</Col>
                <Col xs="7" className="text-right">
                  {this.props.profile &&
                    this.props.auth.user.id === this.props.profile.user._id && (
                      <Link
                        style={{ color: "blue" }}
                        onClick={async (e) =>
                          await this.setState({ isEditable: true })
                        }
                      >
                        <i className="fa fa-edit" /> Add new Certificate
                      </Link>
                    )}
                </Col>
              </Row>
            )}
          </div>
          <Row>
            {!this.state.isEditable && (
              <Col md="12">
                <div className="border">
                  <CardBody>
                    {this.state.certificate.length === 0 ? (
                      <div><h5>No Certificate(s).</h5></div>
                    ) : (
                      // <div className="p-3 h6">No Certificate</div>
                      this.state.certificate.map((prjt) => (
                        <div className="row" style={{ margin: 6 }}>
                          <div className="col-xs-3 col-md-2">
                            <a
                              href="#pablo"
                              className="text-center"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                className="img-thumbnail  border-gray"
                                style={{
                                  minWidth: 50,
                                  maxWidth: 80,
                                }}
                                src={
                                  prjt.image
                                    ? prjt.image
                                    : "https://image.freepik.com/free-vector/city-architecture-apartment-building-block-with-tower-skyscraper-linear-sketch-vector-illustration_98292-1035.jpg"
                                }
                              />
                            </a>
                          </div>
                          <div className="col-xs-9 col-md-10">
                            <div>
                              <Row>
                                <Col xs="12">
                                  <span className="text-capitalize h5 title">
                                    {prjt.company}
                                  </span>{" "}
                                  {this.props.profile &&
                                    this.props.auth.user.id ===
                                      this.props.profile.user._id && (
                                      <Link
                                        style={{ color: "blue" }}
                                        onClick={async (e) => {
                                          await this.props.deleteCertificate(
                                            prjt._id,
                                            this.props.userid
                                          );
                                          window.location.reload();
                                        }}
                                      >
                                        <i className="fa fa-remove" /> Delete
                                      </Link>
                                    )}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs="12">
                                  <h6 className="text-capitalize">
                                    {prjt.title}{" "}
                                    {prjt.link && (
                                      <small>
                                        <a
                                          style={{ color: "blue" }}
                                          href={prjt.link}
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          Link
                                        </a>
                                      </small>
                                    )}
                                  </h6>
                                </Col>
                                <Col xs="12" className=" text-secondary">
                                  <Moment format="DD MMM YY">
                                    {moment.utc(prjt.from)}
                                  </Moment>{" "}
                                  -{" "}
                                  <Moment format="DD MMM YY">
                                    {moment.utc(prjt.to)}
                                  </Moment>
                                </Col>
                                <Col xs="12" className="text-capitalize">
                                  {/* {prjt.description} */}
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </CardBody>
                </div>
              </Col>
            )}
            {this.state.isEditable && (
              <Col md="12">
                <Card className="card-user">
                  <CardHeader>
                    <Row>
                      <Col xs="7">
                        <CardTitle tag="h5">Add Certificate</CardTitle>
                      </Col>
                      <Col xs="5" className="text-right">
                        <Button
                          className="btn primary"
                          onClick={async (e) =>
                            await this.setState({ isEditable: false })
                          }
                        >
                          CANCEL
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <form
                      onSubmit={(e) =>
                        this.handleExperienceSubmitButtonClick(e)
                      }
                    >
                      <Alert />
                      <div className="row">
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label">Image</Label>
                            <div
                              className="mt--5"
                              style={{ cursor: "pointer", marginTop: -10 }}
                            >
                              <Dropzone
                                // accept="image/*"
                                accept="audio/*,image/*,.psd,.pdf"
                                multiple={false}
                                onDrop={(acceptedFiles) => {
                                  this.onDrop({
                                    files: acceptedFiles,
                                    name: "file",
                                  });
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section>
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <Button
                                        onClick={(e) => e.preventDefault()}
                                        className="primary"
                                      >
                                        Upload
                                      </Button>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                              {this.state.image && (
                                <a
                                  style={{ color: "blue" }}
                                  href={this.state.image}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  Image
                                </a>
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">
                              Certificate Name
                            </Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="text"
                              name="title"
                              value={this.state.title}
                              placeholder="Ex: Cisco Certified Network Associate Security"
                              onChange={(event) =>
                                this.setState({
                                  title: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "title",
                                this.state.title,
                                "required"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">
                              Issuing Organisation
                            </Label>
                            <Input
                              className="form-control"
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              type="text"
                              name="company"
                              value={this.state.company}
                              placeholder="Ex:Cisco"
                              onChange={(event) =>
                                this.setState({
                                  company: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "company",
                                this.state.company,
                                "required"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">
                              Issuing Date
                            </Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="date"
                              name="from"
                              value={this.state.from}
                              placeholder="yyyy/mm/dd"
                              onChange={(event) =>
                                this.setState({ from: event.target.value })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "from",
                                this.state.from,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label">
                              Expiration Date
                            </Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="date"
                              name="to"
                              value={this.state.to}
                              placeholder="yyyy/mm/dd"
                              onChange={(event) =>
                                this.setState({ to: event.target.value })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "to",
                                this.state.to,
                                "required"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">
                              Credential ID
                            </Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="text"
                              name="id"
                              value={this.state.id}
                              // placeholder="Description"
                              onChange={(event) =>
                                this.setState({
                                  id: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "id",
                                this.state.id,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">
                              Credential URL
                            </Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="text"
                              name="link"
                              value={this.state.link}
                              placeholder="URL"
                              onChange={(event) =>
                                this.setState({
                                  link: event.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </div>
                      </div>

                      <Row>
                        <div className="update ml-auto mr-auto">
                          <Button className="primary" type="submit">
                            {this.state.isUpdating ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </div>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      </>
    );
  }
}

Certificate.propTypes = {
  setAlert: PropTypes.func.isRequired,
  deleteCertificate: PropTypes.func.isRequired,
  addExperience: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  addExperience,
  deleteCertificate,
  setAlert,
})(Certificate);
