import React from "react";
import { getCurrentProfile } from "../../actions/profile";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../../layouts/Spinner";
import axios from "axios";
import moment from "moment";
import Moment from "react-moment";
import Config from "../../config";
import SimpleReactValidator from "simple-react-validator";
import firebase from "firebase";
var LOADING_IMAGE_URL = "https://www.google.com/images/spin-32.gif?a";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      loading: true,
      messages: [],
      msg: "",
      skills: [],
    };
    this.validator = new SimpleReactValidator();
  }

  setOneToOneChat() {
    var uid1 = this.props.auth.user.id,
      uid2 = this.props.user._id;
    //Check if user1’s id is less than user2's
    if (uid1 < uid2) {
      return uid1 + "-" + uid2;
    } else {
      return uid2 + "-" + uid1;
    }
  }

  getChatRooms = async () => {
    try {
      const result = await axios.get(
        Config.hostName + `/api/chatroom/${this.props.auth.user.id}`
      );
      console.log(result.data);
    } catch (err) {
      console.log(err.response.data.msg);
    }
  };

  createChatRoom = async () => {
    try {
      const result = await axios.post(Config.hostName + `/api/chatroom`, {
        room: this.setOneToOneChat(),
      });
      console.log(result.data);
    } catch (err) {
      console.log(err.response.data.msg);
    }
  };
  scrollToBottom = () => {
    var objDiv = document.getElementById("chatList");
    if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
  };

  saveImageMessage(file) {
    // 1 - We add a message with a loading icon that will get updated with the shared image.
    console.log(file);
    firebase
      .firestore()
      .collection("messages")
      .add({
        room: this.setOneToOneChat(),
        sentBy: this.props.auth.user.id,
        imageUrl: LOADING_IMAGE_URL,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then((messageRef) => {
        // 2 - Upload the image to Cloud Storage.
        var filePath = this.props.auth.user.id + "/" + file.name;

        return firebase
          .storage()
          .ref(filePath)
          .put(file)
          .then((fileSnapshot) => {
            // 3 - Generate a public URL for the file.
            return fileSnapshot.ref.getDownloadURL().then((url) => {
              // 4 - Update the chat message placeholder with the image's URL.
              return messageRef
                .update({
                  imageUrl: url,
                })
                .then(this.loadMessages());
            });
          });
      })
      .catch(function (error) {
        console.error(
          "There was an error uploading a file to Cloud Storage:",
          error
        );
      });
  }
  saveMessage = (messageText) => {
    this.setState({ msg: "" });
    firebase
      .firestore()
      .collection("messages")
      .add({
        room: this.setOneToOneChat(),
        sentBy: this.props.auth.user.id,
        text: messageText,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .catch(function (error) {
        console.error("Error writing new message to database", error);
      });
  };

  loadMessages = () => {
    // Create the query to load the last 12 messages and listen for new ones.
    var query = firebase
      .firestore()
      .collection("messages")
      .where("room", "==", this.setOneToOneChat())
      .orderBy("timestamp", "asc")
      .limit(40);
    var data = [];
    // Start listening to the query.
    query.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          //   deleteMessage(change.doc.id);
        } else {
          var message = change.doc.data();
          message.timestamp && data.push(message);
          console.log(message.timestamp && message.timestamp.toDate());
        }
      });
      if (data.length === 0) this.createChatRoom();
      this.setState({ messages: data }, () => this.scrollToBottom());
    });
  };

  // Saves the messaging device token to the datastore.
  saveMessagingDeviceToken = () => {
    firebase
      .messaging()
      .getToken()
      .then(function (currentToken) {
        if (currentToken) {
          console.log("Got FCM device token:", currentToken);
          // Saving the Device Token to the datastore.
          firebase
            .firestore()
            .collection("fcmTokens")
            .doc(currentToken)
            .set({ uid: firebase.auth().currentUser.uid });
        } else {
          // Need to request permissions to show notifications.
          this.requestNotificationsPermissions();
        }
      })
      .catch(function (error) {
        console.error("Unable to get messaging token.", error);
      });
  };

  componentDidMount() {
    this.requestNotificationsPermissions();
    firebase.messaging();
    this.getChatRooms();
  }

  // Requests permissions to show notifications.
  requestNotificationsPermissions = () => {
    console.log("Requesting notifications permission...");
    firebase
      .messaging()
      .requestPermission()
      .then(function () {
        // Notification permission granted.
        this.saveMessagingDeviceToken();
      })
      .catch(function (error) {
        console.error("Unable to get permission to notify.", error);
      });
  };

  handleSignupButtonClick = async (e) => {
    if (this.validator.allValid()) {
      let { msg } = this.state;
      this.saveMessage(msg);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  getprofile = async () => {
    try {
      this.setState({ loading: false, user: this.props.user });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  };
  componentWillMount() {
    this.getprofile();
    this.loadMessages();
  }
  render() {
    var user = this.state.user;
    if (this.state.loading) return <Spinner />;

    return (
      <section style={{ backgroundColor: "#F7F7F7" }}>
        <section className="msger">
          <header className="msger-header">
            <div className="msger-header-title">
              <i className="fas fa-comment-alt"></i>{" "}
              <span className="title h6 m-0 p-0 pt-1 text-capitalize">
                {user.name}
              </span>{" "}
              <span className=" text-lowercase">{user.email}</span>
            </div>
            <div className="msger-header-options">
              <span>
                <i className="fas fa-cog"></i>
              </span>
            </div>
          </header>

          <main className="msger-chat" id="chatList">
            {this.state.messages.map((data, index) =>
              this.props.auth.user.id !== data.sentBy ? (
                <div className="msg left-msg" key={index}>
                  <div
                    className="msg-img"
                    style={{
                      backgroundImage:
                        "url(https://image.flaticon.com/icons/svg/327/327779.svg)",
                    }}
                  ></div>

                  <div className="msg-bubble">
                    <div className="msg-info">
                      <div className="msg-info-name text-capitalize">
                        {user.name}
                      </div>
                      <div className="msg-info-time">
                        <Moment format="hh:mm">
                          {moment(data.timestamp.toDate(), "hh:mm a")}
                        </Moment>
                      </div>
                    </div>

                    <div className="msg-text">
                      {data.text || <img alt="" src={data.imageUrl} />}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="msg right-msg" key={index}>
                  <div
                    className="msg-img"
                    style={{
                      backgroundImage:
                        "url(https://image.flaticon.com/icons/svg/145/145867.svg)",
                    }}
                  ></div>

                  <div className="msg-bubble">
                    <div className="msg-info">
                      <div className="msg-info-name text-capitalize">
                        {this.props.auth.user.name}
                      </div>
                      <div className="msg-info-time">
                        <Moment format="hh:mm">
                          {moment(data.timestamp.toDate(), "hh:mm a")}
                        </Moment>
                      </div>
                    </div>

                    <div className="msg-text">
                      {data.text || <img alt="" src={data.imageUrl} />}
                    </div>
                  </div>
                </div>
              )
            )}
          </main>

          <div className="msger-inputarea">
            <input
              type="text"
              className="msger-input"
              value={this.state.msg}
              placeholder="Enter your message..."
              onChange={(event) => this.setState({ msg: event.target.value })}
            />
            <input
              id="mediaCapture"
              type="file"
              accept="image/*"
              multiple={false}
              capture="camera"
              onChange={(e) => this.saveImageMessage(e.target.files[0])}
            />
            <button
              type="submit"
              disabled={this.state.msg.length === 0}
              className="msger-send-btn"
              onClick={this.handleSignupButtonClick}
            >
              Send
            </button>
          </div>
        </section>
      </section>
    );
  }
}

Profile.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  getCurrentProfile,
})(Profile);
