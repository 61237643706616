import React from "react";
import SimpleReactValidator from "simple-react-validator";
import Config from "../../config";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import { setAlert } from "../../actions/alert.actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addExperience, deleteExperience } from "../../actions/profile";
import { Link } from "react-router-dom";
import Alert from "../../layouts/Alert";
import Spinner from "../../layouts/Spinner";
import Moment from "react-moment";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";

class Experience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      company: "",
      location: "",
      current: false,
      from: "",
      to: "",
      experience: [],
      description: "",
      isEditable: false,
      isUpdating: false,
      isLoading: true,
    };
    this.validator = new SimpleReactValidator();
  }

  async componentWillMount() {
    console.log(this.props.profile);
    this.props.profile &&
      (await this.setState({
        experience: this.props.profile.experience,
      }));

    // this.props.profile.experience.length === 0 &&
    //   this.setState({ isEditable: true });
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  async handleExperienceSubmitButtonClick(e) {
    e.preventDefault();
    console.log("handleExperienceSubmitButtonClick");
    if (this.validator.allValid()) {
      // get form data from react state object
      let {
        title,
        company,
        location,
        current,
        from,
        to,
        description,
      } = this.state;
      if (current) to = "";
      let body = {
        userid: this.props.userid,
        title,
        company,
        location,
        current,
        from,
        to,
        description,
      };
      //make user signup request
      await this.setState({ isUpdating: true });

      try {
        const res = await axios.put(
          Config.hostName + "/api/profile/experience",
          body
        );

        await this.setState({ isUpdating: false, isEditable: false });
        console.log(res.data);
        this.setState({ experience: res.data.experience });
      } catch (err) {
        const errors =
          err.response && err.response.data && err.response.data.errors;
        await this.setState({ isUpdating: false });

        if (errors) {
          errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }

    return (
      <>
        <div
          className="content bg-white shadow"
          style={{
            marginTop: 28,
          }}
        >
          <div className="p-3">
            {this.state.isEditable === false && (
              <Row>
                <Col md="6" xs="4">
                  EXPERIENCE
                </Col>
                <Col md="6" xs="8" className="text-right">
                  {this.props.profile &&
                    this.props.auth.user.id === this.props.profile.user._id && (
                      <Link
                        onClick={async (e) =>
                          await this.setState({ isEditable: true })
                        }
                      >
                        <PlusOutlined />
                      </Link>
                    )}
                </Col>
              </Row>
            )}
          </div>
          <Row>
            {!this.state.isEditable && (
              <Col md="12">
                <div className="border">
                  <CardBody>
                    {this.state.experience.length === 0 ? (
                      <div>
                        <h5>No experience(s).</h5>
                      </div>
                    ) : (
                      // <div className="h6">No Experience</div>
                      this.state.experience.map((exp) => (
                        <div className="row">
                          <div className="col-xs-3 col-md-2">
                            <a
                              href="#pablo"
                              className="text-center"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                className="img-thumbnail  border-gray"
                                style={{
                                  minWidth: 50,
                                  maxWidth: 80,
                                }}
                                src={
                                  "https://image.freepik.com/free-vector/city-architecture-apartment-building-block-with-tower-skyscraper-linear-sketch-vector-illustration_98292-1035.jpg"
                                }
                              />
                            </a>
                          </div>
                          <div className="col-xs-9 col-md-10">
                            <div>
                              <Row>
                                <Col xs="12">
                                  <span className="text-capitalize h5 title">
                                    {exp.title}
                                  </span>{" "}
                                  {this.props.profile &&
                                    this.props.auth.user.id ===
                                      this.props.profile.user._id && (
                                      <Link
                                        style={{ color: "blue" }}
                                        onClick={async (e) => {
                                          await this.props.deleteExperience(
                                            exp._id,
                                            this.props.userid
                                          );
                                          window.location.reload();
                                        }}
                                      >
                                        <i className="fa fa-remove" /> Delete
                                      </Link>
                                    )}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs="12">
                                  <h6 className="text-capitalize">
                                    {exp.company}
                                  </h6>
                                </Col>
                                <Col xs="12" className=" text-secondary">
                                  <Moment format="MMM YYYY">
                                    {moment.utc(exp.from)}
                                  </Moment>{" "}
                                  -{" "}
                                  {exp.current ? (
                                    "Present"
                                  ) : (
                                    <Moment format="MMM YYYY">
                                      {moment.utc(exp.to)}
                                    </Moment>
                                  )}{" "}
                                  ({" "}
                                  {moment(exp.to).diff(
                                    moment(exp.from),
                                    "months",
                                    true
                                  )}{" "}
                                  Months )
                                </Col>
                                <Col xs="12">{exp.location}</Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </CardBody>
                </div>
              </Col>
            )}
            {this.state.isEditable && (
              <Col md="12">
                <Card className="card-user">
                  <CardHeader>
                    <Row>
                      <Col xs="7">
                        <CardTitle tag="h5">Add Experience</CardTitle>
                      </Col>
                      <Col xs="5" className="text-right">
                        <Button
                          className="btn primary"
                          onClick={async (e) =>
                            await this.setState({ isEditable: false })
                          }
                        >
                          CANCEL
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <form
                      onSubmit={(e) =>
                        this.handleExperienceSubmitButtonClick(e)
                      }
                    >
                      <Alert />
                      <div className="row">
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label">Title</Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="text"
                              name="title"
                              value={this.state.title}
                              placeholder="Ex: Retail Sales Manager or Front End Developer"
                              onChange={(event) =>
                                this.setState({
                                  title: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "title",
                                this.state.title,
                                "required"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">Company</Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="text"
                              name="company"
                              value={this.state.company}
                              placeholder="Ex: Microsoft, Google"
                              onChange={(event) =>
                                this.setState({
                                  company: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "company",
                                this.state.company,
                                "required"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">Location</Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="text"
                              name="location"
                              value={this.state.location}
                              placeholder="Agra Uttar Pradesh"
                              onChange={(event) =>
                                this.setState({ location: event.target.value })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "location",
                                this.state.location,
                                "required"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">From</Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="month"
                              name="from"
                              value={this.state.from}
                              placeholder="yyyy/mm/dd"
                              onChange={(event) =>
                                this.setState({ from: event.target.value })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "from",
                                this.state.from,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label">To</Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="month"
                              name="to"
                              disabled={this.state.current ? true : false}
                              value={this.state.to}
                              // placeholder="yyyy/mm/dd"
                              onChange={(event) =>
                                this.setState({ to: event.target.value })
                              }
                            />
                            {/* <div className="text-danger">
                              {this.validator.message(
                                "to",
                                this.state.to,
                                "required"
                              )}
                            </div> */}
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input
                                style={{
                                  borderRight: "none",
                                  borderLeft: "none",
                                  borderTop: "none",
                                }}
                                id="Present"
                                type="checkbox"
                                checked={this.state.current}
                                onChange={(e) => {
                                  this.setState({
                                    current: !this.state.current,
                                  });
                                }}
                                name="current"
                              />{" "}
                              Present
                              <span className="form-check-sign">
                                <span className="check"></span>
                              </span>
                            </Label>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">
                              Description
                            </Label>
                            <textarea
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="text"
                              name="description"
                              value={this.state.description}
                              // placeholder="Description"
                              onChange={(event) =>
                                this.setState({
                                  description: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "description",
                                this.state.description,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </div>
                      </div>

                      <Row>
                        <div className="update ml-auto mr-auto">
                          <Button className="primary" type="submit">
                            {this.state.isUpdating ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </div>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      </>
    );
  }
}

Experience.propTypes = {
  setAlert: PropTypes.func.isRequired,
  deleteExperience: PropTypes.func.isRequired,
  addExperience: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  addExperience,
  deleteExperience,
  setAlert,
})(Experience);
