import React from "react";
import { Spin } from "antd";

export default class AwesomeComponent extends React.Component {
  render() {
    return (
      <div className="text-center pt-3 pb-3">
        <div className="spinner">
          <Spin size="large" />
        </div>
      </div>
    );
  }
}
