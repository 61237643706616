import React from "react";
import { Tag } from "antd";
import { getCurrentProfile } from "../../actions/profile";
import Apply from "./Apply";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../../layouts/Spinner";
import axios from "axios";
import Config from "../../config";
import { Row, Col } from "reactstrap";
import Moment from "react-moment";
import moment from "moment";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: "",
      loading: true,
      skills: [],
    };
  }
  getprofile = async () => {
    try {
      const res = await axios.get(
        Config.hostName +
          "/api/profile/user/" +
          this.props.match.params.profileid
      );

      var project = await axios.get(
        Config.hostName +
          "/api/project/user/" +
          this.props.match.params.profileid
      );

      console.log(project.data);
      var designerProject = await axios.get(
        Config.hostName +
          "/api/designer-project/user/" +
          this.props.match.params.profileid
      );
      var profile = res.data;
      profile.project = project.data;
      profile.designerProject = designerProject.data;

      console.log(res.data);

      this.setState({ loading: false, profile: res.data });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  };
  componentWillMount() {
    this.getprofile();
  }
  render() {
    var profile = this.state.profile;
    if (this.state.loading) return <Spinner />;

    var title = profile.user && "Browse profile of - " + profile.user.name;
    var url = window.location.href;
    var size = "2rem";

    return (
      <section style={{ backgroundColor: "#F7F7F7" }}>
        <div className="container">
          {profile.user ? (
            <div
              className="row no-gutters"
              style={{
                paddingTop: "8vw",
                paddingBottom: "8vw",
              }}
            >
              <div className="col-md-8 pr-md-2 ">
                <section className="border mb-3 bg-white rounded p-3 text-left">
                  <div className="m-0 p-0">
                    <div className="row mb-4">
                      <div className="col-md-3 ">
                        <a
                          href="#pablo"
                          className="text-center"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img
                            alt="..."
                            clhomeassName="img-thumbnail  border-gray"
                            style={{
                              minWidth: 140,
                              maxWidth: 140,
                            }}
                            src={
                              profile && profile.avatar
                                ? profile.avatar
                                : "/img/landing-page/avatar.jpg"
                            }
                          />
                        </a>
                      </div>
                      <div className="col-md-9 ">
                        <div className="row">
                          <div className="col-md-12 text-dark text-capitalize">
                            <span className="title h6 m-0 p-0 pt-3">
                              {" "}
                              {profile.user && profile.user.name}
                            </span>
                          </div>
                        </div>

                        <div
                          className="text-secondary pt-2"
                          style={{ maxHeight: "140px", overflow: "hidden" }}
                          dangerouslySetInnerHTML={{
                            __html: profile.bio,
                          }}
                        />
                        <div className="pt-2">
                          Skills :{" "}
                          <span className="text-capitalize">
                            {profile.skillsData &&
                              profile.skillsData.skill.map((skill) => (
                                <Tag>
                                  {skill}
                                  {"  "}
                                </Tag>
                              ))}
                          </span>
                        </div>
                        <div className="pt-2">
                          Share Profile :{" "}
                          <div class="d-inline-block">
                            <TwitterShareButton
                              className="network__share-button"
                              url={url}
                              title={title}
                            >
                              <TwitterIcon size={size} />
                            </TwitterShareButton>
                          </div>
                          <div class="d-inline-block pl-1">
                            <FacebookShareButton
                              className="network__share-button"
                              url={url}
                              quote={title}
                            >
                              <FacebookIcon size={size} />
                            </FacebookShareButton>
                          </div>
                          <div class="d-inline-block pl-1">
                            <LinkedinShareButton
                              className="network__share-button"
                              url={url}
                              quote={title}
                            >
                              <LinkedinIcon size={size} />
                            </LinkedinShareButton>
                          </div>
                        </div>

                        {/* <div className="col-6 text-secondary">
                Posted :
                <TimeAgo date={new Date(profile.createdAt)}>
                  {({ value }) => <>{value}</>}
                </TimeAgo>
              </div> */}
                      </div>
                    </div>
                  </div>
                </section>
                {profile.experience && profile.experience.length > 0 && (
                  <section className="border mb-3 bg-white rounded p-3 text-left">
                    <div className="col-12">
                      <div className="title h5">Experience</div>
                      {profile.experience &&
                        profile.experience.map((exp) => (
                          <div className="row" style={{ margin: 6 }}>
                            <div className="col-xs-3 col-md-2">
                              <a
                                href="#pablo"
                                className="text-center"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  className="img-thumbnail  border-gray"
                                  style={{
                                    minWidth: 50,
                                    maxWidth: 80,
                                  }}
                                  src={
                                    "https://image.freepik.com/free-vector/city-architecture-apartment-building-block-with-tower-skyscraper-linear-sketch-vector-illustration_98292-1035.jpg"
                                  }
                                />
                              </a>
                            </div>
                            <div className="col-xs-9 col-md-10">
                              <div>
                                <Row>
                                  <Col xs="12">
                                    <span className="text-capitalize h6 title">
                                      {exp.title}
                                    </span>{" "}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs="12">
                                    <h6 className="text-capitalize">
                                      {exp.company}
                                    </h6>
                                  </Col>
                                  <Col xs="12" className=" text-secondary">
                                    <Moment format="DD MMM YY">
                                      {moment.utc(exp.from)}
                                    </Moment>{" "}
                                    -{" "}
                                    {exp.current ? (
                                      "Present"
                                    ) : (
                                      <Moment format="DD MMM YY">
                                        {moment.utc(exp.to)}
                                      </Moment>
                                    )}
                                  </Col>
                                  <Col xs="12">{exp.location}</Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </section>
                )}

                {profile.project && profile.project.length > 0 && (
                  <section className="border mb-3 bg-white rounded p-3 text-left">
                    <div className="col-12">
                      <div className="title h5">Projects</div>
                      {profile.project &&
                        profile.project.map((prjt) => (
                          <div className="row" style={{ margin: 6 }}>
                            <div className="col-xs-3 col-md-2">
                              <a
                                href="#pablo"
                                className="text-center"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  className="img-thumbnail  border-gray"
                                  style={{
                                    minWidth: 50,
                                    maxWidth: 80,
                                    maxHeight: 80,
                                  }}
                                  src={
                                    prjt.image
                                      ? prjt.image
                                      : "https://image.freepik.com/free-vector/city-architecture-apartment-building-block-with-tower-skyscraper-linear-sketch-vector-illustration_98292-1035.jpg"
                                  }
                                />
                              </a>
                            </div>
                            <div className="col-xs-9 col-md-10">
                              <div>
                                <Row>
                                  <Col xs="12">
                                    <span className="text-capitalize h6 title">
                                      {prjt.title}
                                    </span>{" "}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs="12">
                                    <h6 className="text-capitalize">
                                      {prjt.company}{" "}
                                      {prjt.link && (
                                        <small>
                                          <a
                                            style={{ color: "blue" }}
                                            href={prjt.link}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                          >
                                            Link
                                          </a>
                                        </small>
                                      )}{" "}
                                    </h6>
                                  </Col>
                                  <Col xs="12" className="text-secondary">
                                    <div>
                                      <Moment format="DD MMM YY">
                                        {moment.utc(prjt.from)}
                                      </Moment>{" "}
                                      -{" "}
                                      {prjt.current ? (
                                        "Present"
                                      ) : (
                                        <Moment format="DD MMM YY">
                                          {moment.utc(prjt.to)}
                                        </Moment>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs="12" className="text-capitalize">
                                    <div
                                      style={{
                                        maxHeight: "82px",
                                        overflow: "hidden",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: prjt.description,
                                      }}
                                    />
                                  </Col>
                                  {prjt.technologies.length > 0 && (
                                    <Col xs="12">
                                      Technologies :{" "}
                                      {prjt.technologies.map((i) => {
                                        return (
                                          <Tag>
                                            {i}
                                            {"  "}
                                          </Tag>
                                        );
                                      })}
                                    </Col>
                                  )}
                                </Row>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </section>
                )}

                {/* designer project section start */}

                {profile.designerProject && profile.designerProject.length > 0 && (
                  <section className="border mb-3 bg-white rounded p-3 text-left">
                    <div className="col-12">
                      <div className="title h5">Designer Projects</div>
                      {profile.designerProject &&
                        profile.designerProject.map((prjt) => (
                          <div className="row" style={{ margin: 6 }}>
                            <div className="col-xs-3 col-md-2">
                              <a
                                href="#pablo"
                                className="text-center"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  className="img-thumbnail  border-gray"
                                  style={{
                                    minWidth: 50,
                                    maxWidth: 80,
                                    maxHeight: 80,
                                  }}
                                  src={
                                    prjt.image
                                      ? prjt.image[0]
                                      : "https://image.freepik.com/free-vector/city-architecture-apartment-building-block-with-tower-skyscraper-linear-sketch-vector-illustration_98292-1035.jpg"
                                  }
                                />
                              </a>
                            </div>
                            <div className="col-xs-9 col-md-10">
                              <div>
                                <Row>
                                  <Col xs="12">
                                    <span className="text-capitalize h6 title">
                                      {prjt.title}
                                    </span>{" "}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs="12">
                                    <h6 className="text-capitalize">
                                      {prjt.company}{" "}
                                      {prjt.link && (
                                        <small>
                                          <a
                                            style={{ color: "blue" }}
                                            href={prjt.link}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                          >
                                            Link
                                          </a>
                                        </small>
                                      )}{" "}
                                    </h6>
                                  </Col>
                                  <Col xs="12" className="text-secondary">
                                    <div>
                                      <Moment format="DD MMM YY">
                                        {moment.utc(prjt.from)}
                                      </Moment>{" "}
                                      -{" "}
                                      {prjt.current ? (
                                        "Present"
                                      ) : (
                                        <Moment format="DD MMM YY">
                                          {moment.utc(prjt.to)}
                                        </Moment>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs="12" className="text-capitalize">
                                    <div
                                      style={{
                                        maxHeight: "82px",
                                        overflow: "hidden",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: prjt.description,
                                      }}
                                    />
                                  </Col>
                                  {prjt.technologies.length > 0 && (
                                    <Col xs="12">
                                      Technologies :{" "}
                                      {prjt.technologies.map((i) => {
                                        return (
                                          <Tag>
                                            {i}
                                            {"  "}
                                          </Tag>
                                        );
                                      })}
                                    </Col>
                                  )}
                                </Row>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </section>
                )}
                {/* designer project section end */}

                {profile.education && profile.education.length > 0 && (
                  <section className="border mb-3 bg-white rounded p-3 text-left">
                    <div className="col-12">
                      <div className="title h5">Education</div>
                      {profile.education &&
                        profile.education.map((edu) => (
                          <div className="row" style={{ margin: 6 }}>
                            <div className="col-xs-3 col-md-2">
                              <a
                                href="#pablo"
                                className="text-center"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  className="img-thumbnail  border-gray"
                                  style={{
                                    minWidth: 50,
                                    maxWidth: 80,
                                  }}
                                  src={
                                    "https://image.freepik.com/free-vector/flat-university-vector_23-2147501131.jpg"
                                  }
                                />
                              </a>
                            </div>
                            <div className="col-xs-9 col-md-10">
                              <div>
                                <Row>
                                  <Col xs="12">
                                    <span className="text-capitalize h6 title">
                                      {edu.school}
                                    </span>{" "}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs="12">
                                    <h6 className="text-capitalize">
                                      {edu.degree}
                                    </h6>
                                    {edu.grade && (
                                      <span>{edu.grade} Grade</span>
                                    )}
                                  </Col>
                                  <Col xs="12" className=" text-secondary">
                                    <Moment format="DD MMM YY">
                                      {moment.utc(edu.from)}
                                    </Moment>{" "}
                                    -{" "}
                                    {edu.current ? (
                                      "Present"
                                    ) : (
                                      <Moment format="DD MMM YY">
                                        {moment.utc(edu.to)}
                                      </Moment>
                                    )}
                                  </Col>
                                  <Col xs="12">{edu.description}</Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </section>
                )}
              </div>
              <div className="col-md-4">
                <section className="border mb-3 bg-white rounded p-3 text-left">
                  <Apply
                    fromProfile={true}
                    profileData={profile}
                    key={profile.id}
                    history={this.props.history}
                  />
                </section>
              </div>
            </div>
          ) : (
            <div className="pt-5 pb-5">
              <h5>Oops...No Profile available.</h5>
            </div>
          )}
        </div>
      </section>
    );
  }
}

Profile.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  getCurrentProfile,
})(Profile);
