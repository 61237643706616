import React from "react";
import SimpleReactValidator from "simple-react-validator";
import Config from "../../config";
// reactstrap components
import { Button, FormGroup, Label, Input, Row } from "reactstrap";
import Dropzone from "react-dropzone";
import axios from "axios";
import { setAlert } from "../../actions/alert.actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Alert from "../../layouts/Alert";
import Spinner from "../../layouts/Spinner";
import { Skills } from "../../constants/vars";
import { Select, Modal } from "antd";
import RichTextEditor from "react-rte";
const { Option } = Select;

class DesignerProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      title: "",
      company: "",
      image: "",
      current: false,
      from: "",
      link: "",
      to: "",
      designerProject: [],
      description: RichTextEditor.createEmptyValue(),
      serviceInfoDescription: RichTextEditor.createEmptyValue(),
      isEditable: false,
      isUpdating: false,
      isLoading: true,
      modal1Visible: false,
    };
    this.validator = new SimpleReactValidator();
  }

  setServices = async () => {
    var service = this.props.service;
    console.log(service);
    this.setState({
      title: service.title,
      image: service.image,
      technologies: service.technologies,
      price: service.serviceInfo.price,
      deliveryTime: service.serviceInfo.deliveryTime,
      allowedRevisions: service.serviceInfo.allowedRevisions,
      description: service.description
        ? RichTextEditor.createValueFromString(service.description, "html")
        : RichTextEditor.createEmptyValue(),
      serviceInfoDescription: service.serviceInfo.description
        ? RichTextEditor.createValueFromString(
            service.serviceInfo.description,
            "html"
          )
        : RichTextEditor.createEmptyValue(),
      isLoading: false,
    });
  };

  componentWillMount() {
    this.setServices();
  }

  async onDrop({ files, name }) {
    var image = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      let formData = new FormData();
      formData.append("images", file);

      await axios
        .post(Config.hostName + "/api/profile/images", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (res) => {
          image[i] = res.data;
          console.log(res.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    this.setState({ image: image });
  }

  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }

  async handleSubmit(e) {
    e.preventDefault();
    console.log("handleSubmit");
    if (this.validator.allValid()) {
      // get form data from react state object
      let {
        title,
        image,
        technologies,
        link,
        price,
        deliveryTime,
        allowedRevisions,
        description,
        serviceInfoDescription,
      } = this.state;

      let body = {
        title,
        image,
        technologies,
        link,
        price,
        deliveryTime,
        allowedRevisions,
        description: description.toString("html"),
        serviceInfoDescription: serviceInfoDescription.toString("html"),
      };
      //make user signup request
      await this.setState({ isUpdating: true });

      try {
        const res = await axios.put(
          Config.hostName + "/api/service/" + this.props.service._id,
          body
        );
        console.log(res.data);
        this.props.getService();
        this.setModal1Visible(false);
      } catch (err) {
        const errors =
          err.response && err.response.data && err.response.data.errors;
        await this.setState({ isUpdating: false });

        if (errors) {
          errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }
    return (
      <>
        <Button
          type="primary"
          color="link"
          onClick={() => this.setModal1Visible(true)}
        >
          Update
        </Button>
        <Modal
          title={"Update Service"}
          visible={this.state.modal1Visible}
          okText="Submit"
          onOk={(e) => {
            this.handleSubmit(e);
          }}
          onCancel={() => this.setModal1Visible(false)}
        >
          <form onSubmit={(e) => this.handleSubmit(e)}>
            <Alert />
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label">Image</Label>
                  <div
                    className="mt--5"
                    style={{ cursor: "pointer", marginTop: -10 }}
                  >
                    <Dropzone
                      accept="image/*"
                      // multiple={false}
                      onDrop={(acceptedFiles) => {
                        this.onDrop({
                          files: acceptedFiles,
                          name: "image",
                        });
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button
                              onClick={(e) => e.preventDefault()}
                              className="primary"
                            >
                              Upload Images
                            </Button>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {this.state.image &&
                      this.state.image.map((i) => (
                        <a
                          style={{ color: "blue" }}
                          href={i}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Image{" "}
                        </a>
                      ))}
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">Title</Label>
                  <Input
                    className="form-control"
                    type="text"
                    name="title"
                    value={this.state.title}
                    placeholder="Title"
                    onChange={(event) =>
                      this.setState({
                        title: event.target.value,
                      })
                    }
                  />
                  <div className="text-danger">
                    {this.validator.message(
                      "title",
                      this.state.title,
                      "required"
                    )}
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">Tags</Label>
                  <Select
                    mode="tags"
                    placeholder="Please select"
                    value={this.state.technologies}
                    onChange={(values) =>
                      this.setState({ technologies: values })
                    }
                    style={{ width: "100%" }}
                  >
                    {Skills.map((i) => {
                      return <Option key={i}>{i}</Option>;
                    })}
                  </Select>
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">Description</Label>
                  <RichTextEditor
                    value={this.state.description}
                    editorClassName="editor-height"
                    onChange={(value) => this.setState({ description: value })}
                  />
                </FormGroup>
              </div>
              <div className="border col-md-12">
                <div className="h6 title pt-2 pb-2">Service Information</div>
                <FormGroup>
                  <Label className="col-form-label">Price</Label>
                  <Input
                    className="form-control"
                    type="text"
                    name="price"
                    value={this.state.price}
                    placeholder="Price"
                    onChange={(event) =>
                      this.setState({
                        price: event.target.value,
                      })
                    }
                  />
                  <div className="text-danger">
                    {this.validator.message(
                      "price",
                      this.state.price,
                      "required|integer"
                    )}
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label className="col-form-label">Description</Label>
                  <RichTextEditor
                    value={this.state.serviceInfoDescription}
                    editorClassName="editor-height"
                    onChange={(value) =>
                      this.setState({
                        serviceInfoDescription: value,
                      })
                    }
                  />
                </FormGroup>
              </div>
            </div>

            <Row>
              <div className="update ml-auto mr-auto">
                <Button className="primary" type="submit">
                  {this.state.isUpdating ? (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </Row>
          </form>
        </Modal>
      </>
    );
  }
}

DesignerProjects.propTypes = {
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setAlert,
})(DesignerProjects);
