import React from "react";
import { Modal, Button } from "antd";
import { Input, notification } from "antd";
import SimpleReactValidator from "simple-react-validator";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import Config from "../../config";
import { Link } from "react-router-dom";

const { TextArea } = Input;

class Apply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal1Visible: false,
      modal2Visible: false,
    };
    this.validator = new SimpleReactValidator();
  }

  handleSignupButtonClick = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let { note, name, phone, email } = this.state;
      const data = {
        note,
        name,
        phone,
        email,
        dev_name: this.props.profileData.user.name,
        dev_id: this.props.profileData.user._id,
      };
      console.log(data);

      try {
        let host = Config.hostName;

        await axios.post(host + "/api/contact", data);
        notification.open({
          message: "Success message",
          description: "Your request has been sent successfully.",
        });
        this.setModal1Visible(false);
      } catch (err) {
        var errors =
          err.response && err.response.data && err.response.data.errors;
        if (errors) {
          errors.map((error) =>
            notification.open({
              message: "Error message",
              description: error.msg,
            })
          );
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }
  render() {
    var form = (
      <>
        <div className="title h6 m-0 p-0">Enter your details to contact me</div>

        <div className="form-group">
          <label>Full Name</label>

          <Input
            type="text"
            className="form-control"
            name="name"
            id="name"
            value={this.state.name}
            onChange={(event) =>
              this.setState({
                name: event.target.value,
              })
            }
            onFocus={(e) => this.validator.showMessageFor("name")}
          />

          <div className="text-danger">
            {this.validator.message("name", this.state.name, "required")}
          </div>
        </div>

        <div className="form-group">
          <label>Email</label>

          <Input
            type="text"
            className="form-control"
            name="email"
            id="email"
            value={this.state.email}
            onChange={(event) =>
              this.setState({
                email: event.target.value,
              })
            }
            onFocus={(e) => this.validator.showMessageFor("email")}
          />

          <div className="text-danger">
            {this.validator.message(
              "email",
              this.state.email,
              "required|email"
            )}
          </div>
        </div>

        <div className="form-group">
          <label>Phone Number</label>

          <Input
            type="text"
            className="form-control"
            name="phone"
            id="phone"
            value={this.state.phone}
            onChange={(event) =>
              this.setState({
                phone: event.target.value,
              })
            }
            onFocus={(e) => this.validator.showMessageFor("phoneNumber")}
          />

          <div className="text-danger">
            {this.validator.message(
              "phoneNumber",
              this.state.phone,
              "required|phone"
            )}
          </div>
        </div>

        <div className="form-group">
          <label>Add a Note</label>

          <TextArea
            rows={4}
            className="form-control"
            name="note"
            id="note"
            value={this.state.note}
            onChange={(event) =>
              this.setState({
                note: event.target.value,
              })
            }
            onFocus={(e) => this.validator.showMessageFor("note")}
          />
          <div className="text-danger">
            {this.validator.message("note", this.state.note, "required")}
          </div>
        </div>
      </>
    );
    return (
      <div>
        {!this.props.fromProfile ? (
          <>
            <Link
              className="nav-link c-btn-outline"
              onClick={() => this.setModal1Visible(true)}
            >
              Contact
            </Link>
            <Modal
              // centered
              visible={this.state.modal1Visible}
              onOk={(e) => {
                this.handleSignupButtonClick(e);
              }}
              onCancel={() => this.setModal1Visible(false)}
            >
              {form}
            </Modal>
          </>
        ) : (
          <>
            {form}
            <Button
              type="primary"
              onClick={(e) => this.handleSignupButtonClick(e)}
            >
              Request a call back
            </Button>
          </>
        )}
      </div>
    );
  }
}
Apply.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  company: state.company,
});

export default connect(mapStateToProps, {})(Apply);
