import React from "react";
import { getCurrentProfile } from "../../actions/profile";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../../layouts/Spinner";
import axios from "axios";
import Config from "../../config";
import SimpleReactValidator from "simple-react-validator";
import firebase from "firebase";
import ChatRoomComponent from "./ChatRoomComponent";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      loading: true,
      messages: [],
      msg: "",
      skills: [],
    };
    this.validator = new SimpleReactValidator();
  }

  setOneToOneChat() {
    var uid1 = this.props.auth.user.id,
      uid2 = this.state.recvUserId;
    //Check if user1’s id is less than user2's
    if (uid1 < uid2) {
      return uid1 + "-" + uid2;
    } else {
      return uid2 + "-" + uid1;
    }
  }

  getChatRooms = async () => {
    try {
      const result = await axios.get(
        Config.hostName + `/api/chatroom/${this.props.auth.user.id}`
      );
      console.log(result.data);
    } catch (err) {
      console.log(err.response.data.msg);
    }
  };

  createChatRoom = async () => {
    try {
      const result = await axios.post(Config.hostName + `/api/chatroom`, {
        room: this.setOneToOneChat(),
      });
      console.log(result.data);
    } catch (err) {
      console.log(err.response.data.msg);
    }
  };
  scrollToBottom = () => {
    var objDiv = document.getElementById("chatList");
    if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
  };

  saveMessage = (messageText) => {
    firebase
      .firestore()
      .collection("messages")
      .add({
        room: this.setOneToOneChat(),
        sentBy: this.props.auth.user.id,
        text: messageText,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .catch(function (error) {
        console.error("Error writing new message to database", error);
      });
  };

  loadMessages = () => {
    // Create the query to load the last 12 messages and listen for new ones.
    var query = firebase
      .firestore()
      .collection("messages")
      .where("room", "==", this.setOneToOneChat())

      //   .orderBy("timestamp", "desc")
      .limit(12);
    var data = [];
    // Start listening to the query.
    query.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          //   deleteMessage(change.doc.id);
        } else {
          var message = change.doc.data();
          message.timestamp && data.push(message);
        }
      });
      // if (data.length === 0)
      this.createChatRoom();
      this.setState({ messages: data }, () => this.scrollToBottom());
    });
  };

  // Saves the messaging device token to the datastore.
  saveMessagingDeviceToken = () => {
    firebase
      .messaging()
      .getToken()
      .then(function (currentToken) {
        if (currentToken) {
          console.log("Got FCM device token:", currentToken);
          // Saving the Device Token to the datastore.
          firebase
            .firestore()
            .collection("fcmTokens")
            .doc(currentToken)
            .set({ uid: firebase.auth().currentUser.uid });
        } else {
          // Need to request permissions to show notifications.
          this.requestNotificationsPermissions();
        }
      })
      .catch(function (error) {
        console.error("Unable to get messaging token.", error);
      });
  };

  componentDidMount() {
    this.requestNotificationsPermissions();
    firebase.messaging();
    this.getChatRooms();
  }

  // Requests permissions to show notifications.
  requestNotificationsPermissions = () => {
    console.log("Requesting notifications permission...");
    firebase
      .messaging()
      .requestPermission()
      .then(function () {
        // Notification permission granted.
        this.saveMessagingDeviceToken();
      })
      .catch(function (error) {
        console.error("Unable to get permission to notify.", error);
      });
  };

  handleSignupButtonClick = async (e) => {
    if (this.validator.allValid()) {
      let { msg } = this.state;
      this.saveMessage(msg);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  getprofile = async () => {
    try {
      const res = await axios.get(
        Config.hostName + "/api/users/" + this.props.match.params.profileid
      );

      this.setState({ loading: false, user: res.data });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  };
  componentWillMount() {
    this.getprofile();
    this.setState({ recvUserId: this.props.match.params.profileid }, () =>
      this.loadMessages()
    );
  }
  render() {
    var user = this.state.user;
    if (this.state.loading) return <Spinner />;

    return (
      <div className="mt-5 pt-3 bg-white">
        <ChatRoomComponent user={user} />
      </div>
    );
  }
}

Profile.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  getCurrentProfile,
})(Profile);
