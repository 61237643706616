import React from "react";
import { Helmet } from "react-helmet";
import { LinkedinIcon } from "react-share";
import OurCapabilities from "components/OurCapabilities";

class ClientList extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>ZappLabs | About us</title>
          <meta name="description" content="Pricing" />
        </Helmet>

        <section className="pb-5 pt-5 mt-3 container">
          <h2 className="title header-title">About Zapplabs</h2>
          <h5 className="poppins about-us-desc pt-4 pb-3">
            We are energetic young fellows interested in software development.
            We build websites, mobile apps and desktop apps. We use ReactJS,
            NodeJS and React Native technology.
          </h5>
        </section>
        <section className="pb-5 mb-5  container">
          <h2 className="title header-title text-center">Team</h2>

          <div className="row">
            <div className="col-md-2 text-center"></div>

            <div className="col-md-3 text-center about-us pt-2">
              <img
                className=""
                src="https://media-exp1.licdn.com/dms/image/C4E03AQGvAWhrXOjQ4Q/profile-displayphoto-shrink_200_200/0?e=1604534400&v=beta&t=EG-0_Yb6jXsBYPjIP-JX6N8q1axp7Wk6ZQaO5PBcrnw"
                alt="zapplabs founder - Suvojit Mondal"
              />
              <div className="pt-3">
                <h5 className="work-box-title">
                  Suvojit Mondal{" "}
                  <a
                    href={"https://in.linkedin.com/in/suvojitmondal"}
                    target="_blank noopener noreferrer"
                  >
                    <LinkedinIcon size={"1.4rem"} round />
                  </a>
                </h5>
                <h5 className="work-box-desc text-center"> Founding Member</h5>
                <h5 className="work-box-desc text-center">
                  RCC Institute Of Information Technology College
                </h5>
              </div>
            </div>
          </div>
        </section>
        <OurCapabilities />
      </>
    );
  }
}

export default ClientList;
