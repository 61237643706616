export const Skills = [
    "Node",
    "React",
    "Javascript",
    "Java",
    "PHP",
    "Ruby",
    "C#",
    "C / C++",
    "SQL",
    "PL/SQL",
    "ASP .NET",
    "Objective-C",
    "Visual Basic"
  ];
  