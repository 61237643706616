import React from "react";

import { Skeleton, Card, Avatar } from "antd";

const { Meta } = Card;
class ConfirmEmail extends React.Component {
  render() {
    return (
      <div className="row">
        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(m => (
          <div className="col-md-12 mt-2">
            <Card style={{ width: "100%" }}>
              <Skeleton loading={true} avatar active>
                <Meta
                  avatar={
                    <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                  }
                  title="Card title"
                  description="This is the description"
                />
              </Skeleton>
            </Card>
          </div>
        ))}
      </div>
    );
  }
}

export default ConfirmEmail;
