import React from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
// used for making the prop types of this component
class Footer extends React.Component {
  render() {
    return (
      <>
        <Container fluid={false} className="pt-5 pb-5">
          <div className="row">
            <div className="col-md-3  ">
              <h4 className="work-box-title pb-2">Company</h4>

              <p className="footer-link fw">
                <Link to={'/about-us'}>About Us</Link>
              </p>

              <p className="footer-link fw">
                <Link to={'/pricing'}>Pricing</Link>
              </p>

              <p className="footer-link fw">
                <Link to={'/contact-us'}>Contact Us</Link>
              </p>
              <p className="footer-link fw">
                <Link to={'/user/login'}>Freelancer Signup</Link>
              </p>
              <p className="footer-link fw">Career</p>
            </div>

            <div className="col-md-3  ">
              <h4 className="work-box-title pb-2">Services</h4>
              <p className="footer-link fw">
                <Link to={'/hire-node-developer'}>Hire node.js developer</Link>
              </p>
              <p className="footer-link fw">
                <Link to={'/hire-react-developer'}>
                  Hire react.js developer
                </Link>
              </p>
              <p className="footer-link fw">
                <Link to={'/hire-react-native-developer'}>
                  Hire react native developer
                </Link>
              </p>
              <p className="footer-link fw">
                <Link to={'/hire-android-app-developer'}>
                  Hire android developer
                </Link>
              </p>
              <p className="footer-link fw">
                <Link to={'/hire-android-freelancer'}>
                  Hire Android Developer
                </Link>
              </p>
              <p className="footer-link fw">
                <Link to={'/hire-html-freelancer'}>Hire HTML Developer</Link>
              </p>
            </div>

            <div className="col-md-3  ">
              <h4 className="work-box-title pb-2">Services</h4>
              <p className="footer-link fw">
                <Link to={'/hire-shopify-developer'}>
                  Hire shopify developer
                </Link>
              </p>
              <p className="footer-link fw">
                <Link to={'/hire-shopify-app-developer'}>
                  Hire shopify app developer
                </Link>
              </p>
              <p className="footer-link fw">
                <Link to={'/hire-reactjs-developers'}>
                  Hire React Developers
                </Link>
              </p>
              <p className="footer-link fw">
                <Link to={'/hire-ui-freelancer'}>Hire UI/UX Developer</Link>
              </p>
              <p className="footer-link fw">
                <Link to={'/hire-python-freelancer-in-mumbai'}>
                  Hire Python Developer
                </Link>
              </p>

              <p className="footer-link fw">
                <Link to={'/hire-reactjs-freelancer'}>
                  Hire ReactJS Developer
                </Link>
              </p>
            </div>

            <div className="col-md-3  ">
              <h4 className="work-box-title pb-2">Resources</h4>
              <p className="footer-link fw">
                <a href={'http://blog.zapplabs.in'}>Blog</a>
              </p>
              <p className="footer-link fw">
                <Link to={'/all-services'}>All Services</Link>
              </p>
            </div>
          </div>

          <div className="col-md-12 pt-3m-0 p-0">
            <hr className="seperator p-0" />
          </div>
          <div className="row">
            <div className="col-md-6 col-6  footer-link">
              {/* <h4>Zapplabs</h4> */}
              <img alt="" width="50" className="pt-0 mt-0" src="favicon.ico" />
            </div>
            <div className="col-md-2 col-6">
              <h4 className="pt-1" style={{ position: 'absolute' }}>
                <div className="d-inline-block pr-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'black' }}
                    href="http://linkedin.com/company/zapplabs"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </div>
                <div className="d-inline-block pr-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'black' }}
                    href="https://facebook.com/zapplabs"
                  >
                    <i className="fab fa-facebook"></i>
                  </a>
                </div>
                <div className="d-inline-block pr-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'black' }}
                    href="https://twitter.com/zapplabs"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </div>
              </h4>
            </div>
            <div
              className="col-md-2  footer-link"
              style={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: "center",
              }}
            >
              <a href="tel:+919538822290" className="footer-link p-md-0 pt-2">
                +91 95388 22290
              </a>
            </div>
            <div
              className="col-md-2  footer-link"
              style={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: "center",
              }}
            >
              <a href="mailto:suvojit@zapplabs.in" className="footer-link">
                suvojit@zapplabs.in
              </a>
            </div>

            <div className="col-md-12 ">
              <hr className="seperator p-0" />
            </div>
            <div className="col-md-8   pt-1">
              <div className="footer-link d-inline-block">Privacy Policy</div>
              <div className="footer-link d-inline-block pl-3">
                Terms & Conditions
              </div>
              <br />
              <div className="footer-link">
                © 2020 Edesy Technology Labs Pvt Ltd
              </div>
            </div>
            <div className="col-md-4 align-right  pt-1">
              <Link
                className="nav-link c-btn-outline text-uppercase pt-2 pb-2"
                to="/contact-us"
              >
                Contact us
              </Link>
            </div>
            <div className="col-md-12 ">
              <hr className="seperator p-0" />
            </div>
            {/* <div className="col-md-12 mb-3">
              {routes.map((route) => (
                <span
                  className="inline-display footer-link  fw "
                  key={route.path}
                >
                  <Link to={route.path}>{route.title}</Link> |{" "}
                </span>
              ))}
            </div> */}
          </div>
        </Container>
      </>
    );
  }
}

export default Footer;
