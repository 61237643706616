import React from "react";
import SimpleReactValidator from "simple-react-validator";
import Config from "../../config";
import axios from "axios";
// reactstrap components
import { Button, CardBody, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { login } from "../../actions/user.actions";
import { Redirect } from "react-router-dom";
import Alert from "../../layouts/Alert";
import { UncontrolledAlert } from "reactstrap";
class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      msg: "",
      style: {}
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ style: { display: "none" } });
      }.bind(this),
      1000
    );
  }

  handleResetButtonClick = async e => {
    if (this.validator.allValid()) {
      // get form data from react state object
      let { email } = this.state;
      var data = {
        email: email
      };
      try {
        console.log(email);

        const res = await axios.post(
          Config.hostName + "/api/users/reset-password/email",
          data
        );
        this.setState({
          msg: "Reset password link has been Sent to your mail",
          msgType: "success"
          // email: "",
        });
        console.log(res.data);
      } catch (err) {
        this.setState({
          msg: err.response && err.response.data.msg,
          msgType: "danger"
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    if (this.props.auth.isAuthenticated) return <Redirect to="/profiles" />;

    return (
      <>
        <div className="jumbotron bg-white">
          <h2 className="text-center">Reset Password</h2>
          <Alert />
          <Row>
            <Col md="3"></Col>

            <Col md="6">
              <Row>
                <Col md="12" className="border">
                  {this.state.msg && (
                    <UncontrolledAlert
                      align="center"
                      color={this.state.msgType}
                      fade
                      style={{ marginTop: "10px", position: "relative" }}
                    >
                      {this.state.msg}
                    </UncontrolledAlert>
                  )}
                  <div className="card-user">
                    <CardBody>
                      <form className="theme-form">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="text"
                            required
                            className="form-control"
                            placeholder="Enter email"
                            onChange={event =>
                              this.setState({ email: event.target.value })
                            }
                          />
                          <div className="text-danger">
                            {this.validator.message(
                              "email",
                              this.state.email,
                              "required|email"
                            )}
                          </div>
                        </div>

                        <Button
                          style={{ width: "100%" }}
                          className="primary"
                          onClick={() => this.handleResetButtonClick()}
                        >
                          Submit
                        </Button>
                      </form>
                    </CardBody>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

User.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { login })(User);
