import React from 'react';
import Slider from 'react-slick';
import LandingPageCommon from 'views/LandingPageCommon';

class ClientList extends React.Component {
  render() {
    const settings = {
      dots: false,
      inifinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <>
        <section className="pb-5 mb-5 text-center container">
          <p className="pt-2 title header-subtext">
            Trusted by Today's Top Brands
          </p>

          <Slider {...settings}>
            <div className="p-md-2 p-1 slider-image">
              <img
                alt=""
                src={`img/landing-page/ttakeoff.png`}
                className="pt-1"
              />
            </div>
            <div className="p-md-2 p-1 slider-image">
              <img alt="" src={`img/landing-page/logicarts.png`} height="60" />
            </div>
            <div className="p-md-2 p-1 slider-image">
              <img alt="" src={`img/landing-page/opdlift.png`} height="70" />
            </div>
            <div className="p-md-2 p-1 slider-image">
              <img alt="" src={`img/landing-page/airprobe.png`} height="70" />
            </div>
            <div className="p-md-2 p-1 slider-image">
              <img alt="" src={`img/landing-page/soulslings.png`} height="70" />
            </div>
          </Slider>
        </section>

        <LandingPageCommon />
      </>
    );
  }
}

export default ClientList;
