import React from 'react';
import PortfolioComponent from './PortfolioComponent';
import PricePart from './PricePart';
import FAQPart from './FAQPart';
import AboutUsPart from './AboutusPart';
import OurServicesPart from './OurServicesPart';

class PortfolioProjects extends React.Component {
  render() {
    return (
      <>
        <AboutUsPart></AboutUsPart>
        <PricePart></PricePart>
        <PortfolioComponent></PortfolioComponent>
        <OurServicesPart></OurServicesPart>
        <FAQPart></FAQPart>
      </>
    );
  }
}

export default PortfolioProjects;
