import React from "react";
import SimpleReactValidator from "simple-react-validator";
import Config from "../../config"

// reactstrap components
import { Button, CardBody, Container, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { register } from "../../actions/user.actions";
import { Redirect } from "react-router-dom";
import Alert from "../../layouts/Alert";
import { setAlert } from "../../actions/alert.actions";
import axios from "axios";
class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: "",
      subject: "",
      phone: "",
      style: {}
    };
    this.validator = new SimpleReactValidator();
    this.handleSignupButtonClick = this.handleSignupButtonClick.bind(this);
  }

  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ style: { display: "none" } });
      }.bind(this),
      1000
    );
  }

  async handleSignupButtonClick(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      let { subject, phone, desc } = this.state;
      let host =  Config.hostName;

      try {
        await axios.post(host + "/api/business/request", {
          user: this.props.auth.user.id,
          email: this.props.auth.user.name,
          subject,
          phone,
          desc
        });
        this.props.setAlert("Your request has been sent", "success");
        this.setState({
          subject: "",
          phone: "",
          desc: ""
        });
      } catch (errors) {
        // const errors = error.response.data.errors;
        // if (errors) {
        //   errors.forEach(error => this.props.setAlert(error.msg, "danger"));
        // }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    if (!this.props.auth.isAuthenticated)
      return <Redirect to="/business/login" />;
    else if (this.props.auth.user.userType === "admin")
      return <Redirect to="/admin/home" />;
    else if (this.props.auth.user.userType === "user")
      return <Redirect to="/profiles" />;

    return (
      <>
        <div className="jumbotron bg-white">
          <h2 className="text-center">Request Call Back</h2>
          <Alert />
          <Row>
            <Col md="2"></Col>

            <Col md="8">
              <Container>
                <div className="card-user border">
                  <CardBody>
                    <form className="theme-form">
                      <div className="form-group">
                        <label>Subject</label>

                        <input
                          type="text"
                          required
                          className="form-control"
                          value={this.state.subject}
                          placeholder="Enter subject"
                          onChange={event =>
                            this.setState({ subject: event.target.value })
                          }
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "subject",
                            this.state.subject,
                            "required"
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Phone</label>

                        <input
                          type="text"
                          required
                          className="form-control"
                          value={this.state.phone}
                          placeholder="Mobile"
                          onChange={event =>
                            this.setState({ phone: event.target.value })
                          }
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "phone",
                            this.state.phone,
                            "required|min:9|max:12|integer"
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Description</label>

                        <textarea
                          type="text"
                          required
                          value={this.state.desc}
                          className="form-control"
                          placeholder="Description"
                          onChange={event =>
                            this.setState({ desc: event.target.value })
                          }
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "desc",
                            this.state.desc,
                            "required"
                          )}
                        </div>
                      </div>

                      {/* <Col md="1""> */}
                      <Button
                        // style={{ width: "100%" }}
                        className="primary"
                        onClick={this.handleSignupButtonClick}
                      >
                        Submit
                      </Button>
                    </form>
                  </CardBody>
                </div>
              </Container>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

User.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { register, setAlert })(User);
