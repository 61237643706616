import React from "react";
import SimpleReactValidator from "simple-react-validator";
import Config from "../../config";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import Dropzone from "react-dropzone";
import axios from "axios";
import { setAlert } from "../../actions/alert.actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Alert from "../../layouts/Alert";
import Spinner from "../../layouts/Spinner";
import ProjectUpdate from "./ProjectUpdate";
import Moment from "react-moment";
import moment from "moment";
import { Skills } from "../../constants/vars";
import { Select, Tag, Popconfirm } from "antd";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import RichTextEditor from "react-rte";
import { PlusOutlined } from "@ant-design/icons";
const { Option } = Select;
class Experience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      company: "",
      image: "",
      current: false,
      from: "",
      link: "",
      to: "",
      project: [],
      description: RichTextEditor.createEmptyValue(),
      isEditable: false,
      isUpdating: false,
      isLoading: true,
    };
    this.validator = new SimpleReactValidator();
  }

  async onDrop({ files, name }) {
    let formData = new FormData();
    formData.append("images", files[0]);

    await axios
      .post(Config.hostName + "/api/profile/images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (res) => {
        this.setState({ image: res.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  getProjects = async () => {
    try {
      const res = await axios.get(
        Config.hostName + `/api/project/user/${this.props.userId}`
      );
      console.log("project", res.data);
      this.setState({ project: res.data, isLoading: false });
      // if (res.data.length === 0) this.setState({ isEditable: true });
    } catch (err) {
      console.log(err);
      this.setState({ profile: null, isLoading: false });
    }
  };

  componentWillMount() {
    this.getProjects();
  }
  handleDelete = async (id) => {
    try {
      const res = await axios.delete(Config.hostName + `/api/project/${id}`);
      console.log("project", res.data);
      var projects = this.state.project.filter((project) => project._id !== id);

      if (res.data.length === 0) this.setState({ isEditable: true });

      this.setState({ isLoading: false, project: projects });
    } catch (err) {
      console.log(err);
      this.setState({ profile: null, isLoading: false });
    }
  };

  async handleExperienceSubmitButtonClick(e) {
    e.preventDefault();
    console.log("handleExperienceSubmitButtonClick");
    if (this.validator.allValid()) {
      // get form data from react state object
      let {
        title,
        company,
        image,
        current,
        link,
        from,
        to,
        technologies,
        description,
      } = this.state;

      let body = {
        userid: this.props.userid,
        title,
        company,
        image,
        technologies,
        current,
        link,
        from,
        to,
        description: description.toString("html"),
      };

      console.log(body);

      //make user signup request
      await this.setState({ isUpdating: true });

      try {
        const res = await axios.post(Config.hostName + "/api/project", body);

        await this.setState({ isUpdating: false, isEditable: false });
        console.log(res.data);
        this.getProjects();
      } catch (err) {
        const errors =
          err.response && err.response.data && err.response.data.errors;
        await this.setState({ isUpdating: false });

        if (errors) {
          errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }
    return (
      <>
        <div
          className="content bg-white shadow"
          style={{
            marginTop: 28,
          }}
        >
          <div className="p-3 container-fluid">
            {this.state.isEditable === false && (
              <Row>
                <Col md="6" xs="4">
                  PROJECTS
                </Col>
                <Col md="6" xs="8" className="text-right">
                  {this.props.profile &&
                    this.props.auth.user.id === this.props.profile.user._id && (
                      <Link
                        onClick={async (e) =>
                          await this.setState({ isEditable: true })
                        }
                      >
                        <PlusOutlined />
                      </Link>
                    )}
                </Col>
              </Row>
            )}
          </div>
          <Row>
            {!this.state.isEditable && (
              <Col md="12">
                <div className="border">
                  {/* <CardBody> */}
                  {this.state.project.length === 0 ? (
                    <div className="container">
                      <h5>No project(s).</h5>
                    </div>
                  ) : (
                    // <div className="h6">No Projects</div>
                    this.state.project.map((prjt) => (
                      <div className="row" style={{ margin: 6 }}>
                        <div className="col-xs-3 col-md-2">
                          <a
                            href="#pablo"
                            className="text-center"
                            onClick={(e) => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="img-thumbnail  border-gray"
                              style={{
                                minWidth: 50,
                                maxWidth: 80,
                                maxHeight: 80,
                              }}
                              src={
                                prjt.image
                                  ? prjt.image
                                  : "https://image.freepik.com/free-vector/city-architecture-apartment-building-block-with-tower-skyscraper-linear-sketch-vector-illustration_98292-1035.jpg"
                              }
                            />
                          </a>
                        </div>
                        <div className="col-xs-9 col-md-10">
                          <div>
                            <Row>
                              <Col xs="12">
                                <span className="text-capitalize h5 title">
                                  {prjt.title}
                                </span>{" "}
                                {this.props.profile &&
                                  this.props.auth.user.id ===
                                    this.props.profile.user._id && (
                                    <>
                                      <ProjectUpdate
                                        project={prjt}
                                        getProjects={this.getProjects}
                                      />
                                      <Popconfirm
                                        title={"Are you sure?"}
                                        icon={
                                          <QuestionCircleOutlined
                                            style={{ color: "red" }}
                                          />
                                        }
                                        onConfirm={() =>
                                          this.handleDelete(prjt._id)
                                        }
                                      >
                                        <DeleteOutlined
                                          style={{ color: "red" }}
                                        />
                                      </Popconfirm>
                                    </>
                                  )}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12">
                                <h6 className="text-capitalize">
                                  {prjt.company}{" "}
                                  {prjt.link && (
                                    <small>
                                      <a
                                        style={{ color: "blue" }}
                                        href={prjt.link}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                      >
                                        Link
                                      </a>
                                    </small>
                                  )}{" "}
                                </h6>
                              </Col>
                              {prjt.technologies.length > 0 && (
                                <Col xs="12">
                                  Technologies :{" "}
                                  {prjt.technologies.map((i) => {
                                    return (
                                      <Tag
                                        onClick={() =>
                                          this.props.getServicesByTags(i)
                                        }
                                      >
                                        {i}
                                        {"  "}
                                      </Tag>
                                    );
                                  })}
                                </Col>
                              )}
                              <Col xs="12" className="text-secondary">
                                <div>
                                  <Moment format="MMM YYYY">
                                    {moment.utc(prjt.from)}
                                  </Moment>{" "}
                                  -{" "}
                                  {prjt.current ? (
                                    "Present"
                                  ) : (
                                    <Moment format="MMM YYYY">
                                      {moment.utc(prjt.to)}
                                    </Moment>
                                  )}
                                </div>
                              </Col>
                              <Col xs="12" className="text-capitalize">
                                <div
                                  style={{
                                    maxHeight: "90px",
                                    overflow: "hidden",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: prjt.description,
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                  {/* </CardBody> */}
                </div>
              </Col>
            )}
            {this.state.isEditable && (
              <Col md="12">
                <Card className="card-user">
                  <CardHeader>
                    <Row>
                      <Col xs="7">
                        <CardTitle tag="h5">Add Project</CardTitle>
                      </Col>
                      <Col xs="5" className="text-right">
                        <Button
                          className="btn primary"
                          onClick={async (e) =>
                            await this.setState({ isEditable: false })
                          }
                        >
                          CANCEL
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <form
                      onSubmit={(e) =>
                        this.handleExperienceSubmitButtonClick(e)
                      }
                    >
                      <Alert />
                      <div className="row">
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label">Image</Label>
                            <div
                              className="mt--5"
                              style={{ cursor: "pointer", marginTop: -10 }}
                            >
                              <Dropzone
                                accept="image/*"
                                multiple={false}
                                onDrop={(acceptedFiles) => {
                                  this.onDrop({
                                    files: acceptedFiles,
                                    name: "image",
                                  });
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section>
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <Button
                                        onClick={(e) => e.preventDefault()}
                                        className="primary"
                                      >
                                        Upload
                                      </Button>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                              {this.state.image && (
                                <a
                                  style={{ color: "blue" }}
                                  href={this.state.image}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  Image
                                </a>
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">
                              Project name
                            </Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="text"
                              name="title"
                              value={this.state.title}
                              placeholder=""
                              onChange={(event) =>
                                this.setState({
                                  title: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "title",
                                this.state.title,
                                "required"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">Company</Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="text"
                              name="company"
                              value={this.state.company}
                              placeholder="Ex Microfost"
                              onChange={(event) =>
                                this.setState({
                                  company: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "company",
                                this.state.company,
                                "required"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">Start Date</Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="month"
                              name="from"
                              value={this.state.from}
                              placeholder="yyyy/mm/dd"
                              onChange={(event) =>
                                this.setState({ from: event.target.value })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "from",
                                this.state.from,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Skills</Label>
                            <Select
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                                width: "100%",
                              }}
                              mode="tags"
                              placeholder="Please select"
                              defaultValue={this.state.technologies}
                              // defaultValue={['node', 'react.js']}
                              onChange={(values) =>
                                this.setState({ technologies: values })
                              }
                            >
                              {Skills.map((i) => {
                                return <Option key={i}>{i}</Option>;
                              })}
                            </Select>
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label">End Date</Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="month"
                              name="to"
                              disabled={this.state.current ? true : false}
                              value={this.state.to}
                              placeholder="yyyy/mm/dd"
                              onChange={(event) =>
                                this.setState({ to: event.target.value })
                              }
                            />
                            {/* <div className="text-danger">
                              {this.validator.message(
                                "to",
                                this.state.to,
                                "required"
                              )}
                            </div> */}
                          </FormGroup>

                          <FormGroup check>
                            <Label check>
                              <Input
                                style={{
                                  borderRight: "none",
                                  borderLeft: "none",
                                  borderTop: "none",
                                }}
                                id="Current"
                                type="checkbox"
                                checked={this.state.current}
                                onChange={(e) =>
                                  this.setState({
                                    current: !this.state.current,
                                  })
                                }
                                name="current"
                              />{" "}
                              I am currently working on this project
                              <span className="form-check-sign">
                                <span className="check"></span>
                              </span>
                            </Label>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">
                              Project URL
                            </Label>
                            <Input
                              style={{
                                borderRight: "none",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                              className="form-control"
                              type="text"
                              name="link"
                              value={this.state.link}
                              placeholder="Link"
                              onChange={(event) =>
                                this.setState({
                                  link: event.target.value,
                                })
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">
                              Description
                            </Label>
                            <RichTextEditor
                              value={this.state.description}
                              editorClassName="editor-height"
                              onChange={(value) =>
                                this.setState({ description: value })
                              }
                            />
                          </FormGroup>
                        </div>
                      </div>

                      <Row>
                        <div className="update ml-auto mr-auto">
                          <Button className="primary" type="submit">
                            {this.state.isUpdating ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </div>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      </>
    );
  }
}

Experience.propTypes = {
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setAlert,
})(Experience);
