import React from "react";
import { Layout, Menu } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../../layouts/Spinner";
import axios from "axios";
import Config from "../../config";
import SimpleReactValidator from "simple-react-validator";
import ChatRoomComponent from "./ChatRoomComponent";
const { Content, Sider } = Layout;

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentChatRoom: "",
      loading: true,
      chatRooms: [],
      msg: "",
      skills: [],
    };
    this.validator = new SimpleReactValidator();
  }

  getChatRooms = async () => {
    try {
      console.log(Config.hostName + `/api/chatroom/${this.props.auth.user.id}`);
      const result = await axios.get(
        Config.hostName + `/api/chatroom/${this.props.auth.user.id}`
      );
      console.log(result.data);
      if (result.data.length > 0)
        this.setState({ currentChatRoom: result.data[0] });
      this.setState({ chatRooms: result.data, loading: false });
    } catch (err) {
      console.log(err.response.data.msg);
      this.setState({ loading: false });
    }
  };

  componentDidMount() {
    this.getChatRooms();
  }

  componentWillMount() {
    this.getChatRooms();
  }
  render() {
    if (this.state.loading) return <Spinner />;
    var { chatRooms } = this.state;
    return (
      <Layout className="mt-5 pt-3 bg-white">
        <Sider
          style={{
            overflow: "auto",
            height: "100vh",
            // position: "absolute",
            left: 0,
          }}
        >
          <div className="logo" />
          <Menu theme="light" mode="inline" defaultSelectedKeys={["4"]}>
            {chatRooms.map((chatroom) => (
              <Menu.Item
                key={chatroom.room}
                onClick={() => this.setState({ currentChatRoom: chatroom })}
              >
                <span className="nav-text text-capitalize">
                  {chatroom.user.name}
                </span>

                <div className="nav-text">{chatroom.user.email}</div>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Content>
            <ChatRoomComponent
              key={this.state.currentChatRoom.room}
              user={
                this.state.currentChatRoom && this.state.currentChatRoom.user
              }
            />
          </Content>
        </Layout>
      </Layout>
    );
  }
}

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {})(Profile);
