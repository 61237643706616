import React from "react";
import { Modal } from "antd";
import SimpleReactValidator from "simple-react-validator";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import Moment from "react-moment";
import moment from "moment";
import { Tag, Card, Avatar } from "antd";
import { Link } from "react-router-dom";

class Apply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal1Visible: false,
      modal2Visible: false,
    };
    this.validator = new SimpleReactValidator();
  }

  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }
  render() {
    var project = this.props.project;

    var form = (
      <>
        <div className="title h5 m-0 p-0">
          {project.link ? (
            <small>
              <a
                style={{ color: "blue" }}
                href={project.link}
                rel="noopener noreferrer"
                target="_blank"
              >
                {project.title}
              </a>
            </small>
          ) : (
            project.title
          )}
        </div>
        <div className="text secondary m-0 p-0">
          by{" "}
          <Link to={`/dev-profile/${this.props.user._id}`}>
            <Avatar
              style={{ backgroundColor: "blue", verticalAlign: "middle" }}
              size="medium"
            >
              {this.props.user.name && this.props.user.name[0]}
            </Avatar>{" "}
            {this.props.user.name}{" "}
          </Link>
          {project.company && <span> for {project.company}</span>}
        </div>
        <img
          alt="example"
          className="rounded-corner"
          style={{ width: "100%" }}
          src={
            project.image
              ? project.image
              : "https://image.freepik.com/free-vector/city-architecture-apartment-building-block-with-tower-skyscraper-linear-sketch-vector-illustration_98292-1035.jpg"
          }
        />

        <div
          className="text-secondary mt-2"
          dangerouslySetInnerHTML={{ __html: project.description }}
        />
        <Row>
          {project.technologies && project.technologies.length > 0 && (
            <Col xs="12">
              Technologies :{" "}
              {project.technologies.map((i) => {
                return (
                  <Tag>
                    {i}
                    {"  "}
                  </Tag>
                );
              })}
            </Col>
          )}
          <Col xs="12" className="text-secondary">
            <div>
              <Moment format="DD MMM YY">{moment.utc(project.from)}</Moment> -{" "}
              {project.current ? (
                "Present"
              ) : (
                <Moment format="DD MMM YY">{moment.utc(project.to)}</Moment>
              )}
            </div>
          </Col>
        </Row>
      </>
    );
    return (
      <div className="pt-2">
        <Card
          onClick={() => this.setModal1Visible(true)}
          hoverable
          style={{ width: "100%" }}
          cover={
            <img
              className="rounded-corner"
              alt="example"
              style={{ maxHeight: "140px" }}
              src={
                project.image
                  ? project.image
                  : "https://image.freepik.com/free-vector/city-architecture-apartment-building-block-with-tower-skyscraper-linear-sketch-vector-illustration_98292-1035.jpg"
              }
            />
          }
        >
          <div className="text-capitalize">
            <Avatar
              style={{ backgroundColor: "blue", verticalAlign: "middle" }}
              size="medium"
            >
              {this.props.user.name && this.props.user.name[0]}
            </Avatar>{" "}
            {this.props.user.name}
          </div>
          <div className="h6 title text-capitalize">{project.title}</div>
        </Card>
        <Modal
          visible={this.state.modal1Visible}
          onOk={(e) => this.setModal1Visible(false)}
          onCancel={() => this.setModal1Visible(false)}
        >
          {form}
        </Modal>
      </div>
    );
  }
}
Apply.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
});

export default connect(mapStateToProps, {})(Apply);
