import React from "react";

export default class ConfirmEmail extends React.Component {
  render() {
    return (
      <div style={{ marginTop: -50 }}>
        <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
        <iframe
          title="customer"
          className="airtable-embed"
          src="https://airtable.com/embed/shrMUaF1zueUtmRvq?backgroundColor=yellow"
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="533"
          style={{ background: "transparent", border: "1px solid #ccc" }}
        ></iframe>
      </div>
    );
  }
}
