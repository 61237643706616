import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import OurServices from './OurServices';

class LandingPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <section className="mt-2">
          <div
            style={{
              paddingTop: '2vw',
              paddingBottom: '8vw',
            }}
          >
            <div className="container">
              <h2 className="header-title pt-3 pb-3 text-left">Our services</h2>
              <div className="row">
                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="/hire-node-developer">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/server.svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            Software development using node.js
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We provide software development service using node.js
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="/hire-react-developer">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/front-end.svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            Frontend development using React.js
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We provide website development service using React.js
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="/hire-react-native-developer">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/code.svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            React native mobile application development
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We develop mobile application using React native.
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="/hire-shopify-developer">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/ecommerce.svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            Ecommerce website development
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We develop ecommerce website using shopify or magento
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="/hire-shopify-app-developer">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/shopify.svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            Shopify app development
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We provide shopify app development services
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="/hire-android-app-developer">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/android.svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            Android mobile app development
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We provide android mobile application development
                          service
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="#">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/website-development.svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            Website development
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We provide web development services
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="#">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/web-custom.svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            Website customization service
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We provide website customization service
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="#">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/saas.svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            SAAS software development service
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We can develop SAAS software as per your requirement
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="#">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/custom.svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            Custom software development
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We develop custom software as per your requirement
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="#">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/ios.svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            IOS app development
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We develop IOS mobile application
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="#">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/wordpress.svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            Wordpress website development
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We develop wordpress website
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="#">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/wordpress (1).svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            Wordpress website Customization
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We provide wordpress website customization services
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="#">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/wordpress (2).svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            Wordpress plugin development
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We provide wordpress plugin development services
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="#">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/shopify-web.svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            Shopify website development
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We develop shopify website
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="#">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/shopify.svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            Shopify app development
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We develop shopify app to add functionality in the
                          shopify store
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 p-3">
                  <div className="type-of-work-box">
                    <Link to="#">
                      <div className="row">
                        <div className="col-3">
                          <img
                            alt=""
                            width="100%"
                            className="filter-pink"
                            src="img/work-types/java.svg"
                          />
                        </div>
                        <div className="col-9 ">
                          <h4 className="work-box-title">
                            Custom software development using Java
                          </h4>
                        </div>
                        <div className="col-12 work-box-desc">
                          We can develop custom software using Java
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

LandingPage.propTypes = { auth: PropTypes.object.isRequired };
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(LandingPage);
