import React from "react";
import Config from "../../config";

// reactstrap components
import { CardBody, Card } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { login } from "../../actions/user.actions";
import { Link } from "react-router-dom";
import Alert from "../../layouts/Alert";
import axios from "axios";
import { geturl } from "../../actions/profile";
class ConfirmEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: null,
      errors: [],
    };
  }

  async componentWillMount() {
    try {
      let host = Config.hostName;

      const result = await axios.post(
        host + "/api/users/confirm/" + this.props.match.params.token
      );
      await this.setState({ msg: result.data.msg });
    } catch (err) {
      console.log(err);
      // const errors = err.response && err.response.data && err.response.data.errors;

      // this.setState({ errors: errors });
    }
  }

  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ style: { display: "none" } });
      }.bind(this),
      1000
    );
  }

  render() {
    // if (this.props.auth.isAuthenticated) return <Redirect to="/profiles" />;

    return (
      <>
        <div className="content mt-5 pt-5">
          <Alert />

          <Card className="card-user text-center">
            <CardBody>
              {this.state.msg && (
                <>
                  <h2 className="text-center">
                    {<span>{this.state.msg}</span>}
                  </h2>
                  <p>
                    Click here to
                    <Link to="/user/login" className="btn-link ">
                      Login
                    </Link>
                  </p>
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

ConfirmEmail.propTypes = {
  geturl: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login, geturl })(ConfirmEmail);
