import { combineReducers } from "redux";
// import ChatApp from './chat.reducer';
// import TodoApp from './todo.reducer';
import auth from "./auth";
import alert from "./alert";
import profile from "./profile";

const reducers = combineReducers({
  auth,
  alert,
  profile
  // TodoApp,
  // ChatApp
});

export default reducers;
