import React from 'react';
import { Helmet } from 'react-helmet';

class ClientList extends React.Component {
  render() {
    const faqs = [
      {
        question: 'How do you engage with other companies?',
        answer:
          'We work with other companies on per project basis or full time basis. We can provide developer who can work with you on full time basis. In terms of price we charge INR 750 per hour. Please contact us if you want to discuss anything else, we will be happy to address those',
      },
      {
        question: 'How do you charge?',
        answer: 'We charge INR 750 per hour',
      },
      {
        question: 'Can you work full time with us?',
        answer: 'We can work with you on full time basis remotely.',
      },
    ];
    return (
      <>
        <Helmet>
          <title>ZappLabs | Pricing</title>
          <meta name="description" content="Pricing" />
        </Helmet>
        <section className="container">
          <div className="row ">
            <div className="col-md-6">
              <div
                style={{
                  paddingTop: '4vw',
                  paddingBottom: '8vw',
                }}
              >
                <h2 className="title header-title pt-md-5">
                  The most fair pricing plans ever.
                </h2>

                <h5 className="poppins header-subtext pt-4 pb-3">
                  Our standard charges are suitable for your business to
                  introduce it to the virtual world.
                </h5>
              </div>
            </div>
            <div className="col-md-6  pb-5 d-none d-md-block pt-5 text-center">
              <img
                alt=""
                src={'img/pricing-img.png'}
                width="80%"
                className="rounded"
              />
            </div>
          </div>
        </section>
        <section className="pb-5 mb-5  container">
          {/* <p className="pt-2 title header-subtext">Our pricing</p> */}
          <h2 className="title header-title text-center">Our pricing</h2>
          <div className="row">
            <div className="col-md-6 ">
              <div className="type-of-work-box">
                <h4 className="work-box-title">Per hour</h4>
                <h5 className="work-box-desc">
                  For per hour project we charge INR 750 per hour
                  <br />
                  <br />
                </h5>
              </div>
            </div>
            <div className="col-md-6">
              <div className="type-of-work-box">
                <h4 className="work-box-title">Fixed</h4>
                <h5 className="work-box-desc">
                  For fixed project we can discuss about the project
                  functionalities and estimate a fixed cost of the project
                </h5>
              </div>
            </div>
          </div>
          <h5 className="work-box-desc pt-2">
            <strong>
              * Third Party Fees & Charges for additional services are
              exclusive.
            </strong>
          </h5>
        </section>

        <section className="container">
          <div className="faq">Frequently Asked Questions</div>
          <p className="work-box-desc text-center">
            Got a question? We've got answers. If you have some other questions,
            call us at +91 9538822290 or send email to suvojit@zapplabs.in
          </p>
          <div className="row">
            {faqs.map((faq, index) => (
              <div className="col-md-12" key={index}>
                <div className="faq-question">{faq.question}</div>
                <div className="faq-answer">{faq.answer}</div>
              </div>
            ))}
          </div>
        </section>
      </>
    );
  }
}

export default ClientList;
