import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSuccessfulTransactions } from "../../actions/user.actions";
import Moment from "react-moment";
import moment from "moment";
import { Card, CardHeader, Container, Row, Col, Table } from "reactstrap";
import { Link } from "react-router-dom";

class Transaction extends React.Component {
  async componentWillMount() {
    await this.props.getSuccessfulTransactions();
  }

  render() {
    if (this.props.auth.userType === "instructor") {
      return <Redirect to="/instructor/profile/basic-information" />;
    }
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
          style={{
            minHeight: "100px",
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}
        >
          {/* Mask */}
          <span className="mask bg-gradient-default opacity-8" />
          {/* Header container */}
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="12" md="10">
                <h1 className="display-5 text-white">All Transactions</h1>
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Transactions</h3>
                </CardHeader>
                {this.props.auth.successfultransactions.length === 0 ? (
                  <div className="p-3 mb-3"> No transaction is present.</div>
                ) : (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Service</th>
                        <th scope="col">Price</th>
                        <th scope="col">Payment ID</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.auth.successfultransactions.map(
                        transaction => (
                          <tr>
                            <th scope="row" className="text-capitalize">
                              <Link to={"/service/" + transaction.service._id}>
                                {transaction.service.title}
                              </Link>
                            </th>
                            <td>Rs . {transaction.amount.toLocaleString()}</td>
                            <td>{transaction.paymentID}</td>
                            <td className="text-capitalize">
                              {transaction.status}
                            </td>
                            <td>
                              <Moment format="DD MMM YYYY">
                                {moment.utc(transaction.createdAt)}
                              </Moment>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

Transaction.propTypes = {
  getSuccessfulTransactions: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  transaction: state.transaction,
  course: state.course
});

export default connect(mapStateToProps, {
  getSuccessfulTransactions
})(Transaction);
