import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ClientList from "components/client-list";
import GetDemoForm from "components/GetDemoForm";
import { Helmet } from "react-helmet";

class LandingPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Zapplabs | Contact US</title>
          <meta
            name="description"
            content=" Got a question? Send us a message and we’ll respond as soon as
            possible."
          />
        </Helmet>
        <section className="container">
          <div className="row ">
            <div className="col-md-7">
              <div
                style={{
                  paddingTop: "4vw",
                  paddingBottom: "8vw",
                }}
              >
                <h2 className="title header-title">
                  We’d love to
                  <br /> hear from you!
                </h2>

                <h5 className="poppins header-subtext pt-4 pb-3">
                  Got a question? Send us a message and we’ll respond as soon as
                  possible.
                </h5>

                <div className="row contact-wrap">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-2">
                        <img
                          alt=""
                          width="100%"
                          className="filter-pink"
                          src="img/work-types/mail.svg"
                        />
                      </div>
                      <div className="col-9 ">
                        <a
                          href="mailto:suvojit@zapplabs.in"
                          className="header-subtext"
                        >
                          suvojit@zapplabs.in
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-2">
                        <img
                          alt=""
                          width="100%"
                          className="filter-pink"
                          src="img/work-types/phone.svg"
                        />
                      </div>
                      <div className="col-9 ">
                        <a href="tel:+919538822290" className="header-subtext">
                          +91 95388 22290
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 pb-5 pt-3">
              <GetDemoForm {...this.props} />
            </div>
          </div>
        </section>
        <ClientList />
      </div>
    );
  }
}

LandingPage.propTypes = { auth: PropTypes.object.isRequired };
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(LandingPage);
