import React from "react";
import SimpleReactValidator from "simple-react-validator";
import Config from "../../config";

// reactstrap components
import { Button, CardBody, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { login } from "../../actions/user.actions";
import Alert from "../../layouts/Alert";
import axios from "axios";
import { UncontrolledAlert } from "reactstrap";
class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      msg: "",
      style: {},
    };
    this.validator = new SimpleReactValidator();
    this.handleResetButtonClick = this.handleResetButtonClick.bind(this);
  }

  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ style: { display: "none" } });
      }.bind(this),
      1000
    );
  }

  async handleResetButtonClick(e) {
    // prevent default form submission
    e.preventDefault();
    if (this.validator.allValid()) {
      // get form data from react state object
      let { password } = this.state;
      try {
        let host = Config.hostName;

        await axios.put(
          host +
            "/api/users/reset/" +
            this.props.match.params.token +
            "/" +
            password
        );
        await this.setState({
          msg: "Password Updated",
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  render() {
    return (
      <>
        <div className="jumbotron bg-white">
          <h2 className="text-center">
            {window.location.pathname.includes("create-password")
              ? "Create Password"
              : "Change Password"}
          </h2>
          <Alert />
          <Row>
            <Col md="3"></Col>

            <Col md="6">
              <Row>
                <Col md="12" className="border">
                  {this.state.msg && (
                    <UncontrolledAlert
                      align="center"
                      color="success"
                      fade
                      style={{ marginTop: "10px", position: "relative" }}
                    >
                      {this.state.msg}
                    </UncontrolledAlert>
                  )}
                  <div className="card-user">
                    <CardBody>
                      <form className="theme-form">
                        <div className="form-group">
                          <label>Password</label>
                          <input
                            type="text"
                            required
                            className="form-control"
                            placeholder="Enter password"
                            onChange={(event) =>
                              this.setState({ password: event.target.value })
                            }
                          />
                          <div className="text-danger">
                            {this.validator.message(
                              "password",
                              this.state.password,
                              "required|min:6"
                            )}
                          </div>
                        </div>

                        <Button
                          style={{ width: "100%" }}
                          className="primary"
                          onClick={this.handleResetButtonClick}
                        >
                          Submit
                        </Button>
                      </form>
                    </CardBody>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

User.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login })(User);
