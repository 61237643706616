import axios from "axios";
import { setAlert } from "./alert.actions";
import Config from "../config";
import {
  GET_PROFILE,
  GET_PROFILES,
  PROFILE_ERROR,
  UPDATE_PROFILE,
  CLEAR_PROFILES,
  CLEAR_ALERTS,
  LOADING,
} from "../constants/actionTypes";
import setAuthToken from "../utils/setAuthToken.js";
if (localStorage.token) {
  setAuthToken(localStorage.token);
}

export const geturl = () => {
  let host = Config.hostName;
  return host;
};
// Get current users profile
export const getCurrentProfile = (id) => async (dispatch) => {
  console.log(geturl());
  try {
    const res = await axios.get(geturl() + "/api/profile/" + id);

    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err && err.response && err.response.statusText,
        status: err && err.response && err.response.status,
      },
    });
  }
};

// Get all profiles
export const getProfiles = () => async (dispatch) => {
  dispatch({ type: CLEAR_PROFILES });
  dispatch({ type: LOADING });

  try {
    const res = await axios.get(geturl() + "/api/profile");

    dispatch({
      type: GET_PROFILES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err && err.response && err.response.statusText,
        status: err && err.response && err.response.status,
      },
    });
  }
};

export const search = (data) => async (dispatch) => {
  dispatch({
    type: CLEAR_ALERTS,
  });
  dispatch({ type: CLEAR_PROFILES });
  dispatch({ type: LOADING });

  try {
    const res = await axios.post(Config.hostName + "/api/profile/search", data);

    dispatch({
      type: GET_PROFILES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err.response.statusText);
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err && err.response && err.response.statusText,
        status: err && err.response && err.response.status,
      },
    });
  }
};

export const filterProfiles = (data) => async (dispatch) => {
  // dispatch({
  //   type: CLEAR_ALERTS
  // });
  dispatch({ type: CLEAR_PROFILES });
  dispatch({ type: LOADING });

  try {
    const res = await axios.post(Config.hostName + "/api/profile/filter", data);

    dispatch({
      type: GET_PROFILES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err.response.statusText);
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err && err.response && err.response.statusText,
        status: err && err.response && err.response.status,
      },
    });
  }
};

// Get profile by ID
export const getProfileById = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(geturl() + `/api/profile/user/${userId}`);

    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err && err.response && err.response.statusText,
        status: err && err.response && err.response.status,
      },
    });
  }
};

// Create or update profile
export const createProfile = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(geturl() + "/api/profile", formData, config);

    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });

    dispatch(setAlert("Profile Updated", "success"));
  } catch (err) {
    const errors =
      err.response && err.response.data && err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err && err.response && err.response.statusText,
        status: err && err.response && err.response.status,
      },
    });
  }
};

// Add Experience
export const addExperience = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.put(
      geturl() + "/api/profile/experience",
      formData,
      config
    );

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });

    // dispatch(setAlert("Experience Added", "success"));
  } catch (err) {
    const errors =
      err.response && err.response.data && err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err && err.response && err.response.statusText,
        status: err && err.response && err.response.status,
      },
    });
  }
};

// Add Education
export const addEducation = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.put(
      geturl() + "/api/profile/education",
      formData,
      config
    );

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });

    // dispatch(setAlert("Education Added", "success"));
  } catch (err) {
    const errors =
      err.response && err.response.data && err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err && err.response && err.response.statusText,
        status: err && err.response && err.response.status,
      },
    });
  }
};

// Delete experience
export const deleteExperience = (id, userid) => async (dispatch) => {
  try {
    const res = await axios.put(
      geturl() + `/api/profile/experience/${id}/delete`,
      {
        userid,
      }
    );

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });

    // dispatch(setAlert("Experience Removed", "success"));
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err && err.response && err.response.statusText,
        status: err && err.response && err.response.status,
      },
    });
  }
};

// Delete Ceritificate
export const deleteCertificate = (id, userid) => async (dispatch) => {
  try {
    const res = await axios.put(
      geturl() + `/api/profile/certificate/${id}/delete`,
      {
        userid,
      }
    );

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });

    // dispatch(setAlert("Experience Removed", "success"));
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err && err.response && err.response.statusText,
        status: err && err.response && err.response.status,
      },
    });
  }
};

// Delete education
export const deleteEducation = (id, userid) => async (dispatch) => {
  try {
    const res = await axios.put(
      geturl() + `/api/profile/education/${id}/delete`,
      {
        userid,
      }
    );

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });

    // dispatch(setAlert("Education Removed", "success"));
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err && err.response && err.response.statusText,
        status: err && err.response && err.response.status,
      },
    });
  }
};
