import React from "react";
import SimpleReactValidator from "simple-react-validator";
// reactstrap components
import { FormGroup, Label, Input } from "reactstrap";
import { setAlert } from "../../actions/alert.actions";
import { updateUser } from "../../actions/user.actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Alert from "../../layouts/Alert";
import Spinner from "../../layouts/Spinner";
import { EditOutlined } from "@ant-design/icons";
import { Button, Modal, Select } from "antd";
import RichTextEditor from "react-rte";
import { Skills } from "../../constants/vars";
import Dropzone from "react-dropzone";
import axios from "axios";
import Config from "../../config";
var { Option } = Select;

class UpdateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      company: "",
      image: "",
      current: false,
      from: "",
      link: "",
      to: "",
      project: [],
      description: RichTextEditor.createEmptyValue(),
      isEditable: false,
      isUpdating: false,
      isLoading: false,
      modal1Visible: false,
    };
    this.validator = new SimpleReactValidator();
  }

  setServices = async () => {
    var project = this.props.project;
    this.setState({
      title: project.title,
      company: project.company,
      image: project.image,
      technologies: project.technologies,
      current: project.current,
      link: project.link,
      from: project.from,
      to: project.to,
      description: project.description
        ? RichTextEditor.createValueFromString(project.description, "html")
        : RichTextEditor.createEmptyValue(),
      isLoading: false,
    });
  };
  async onDrop({ files, name }) {
    let formData = new FormData();
    formData.append("images", files[0]);

    await axios
      .post(Config.hostName + "/api/profile/images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (res) => {
        this.setState({ image: res.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  componentWillMount() {
    this.setServices();
  }

  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }

  async handleSubmit(e) {
    e.preventDefault();
    console.log("handleSubmit");
    if (this.validator.allValid()) {
      // get form data from react state object
      let {
        title,
        company,
        image,
        technologies,
        current,
        link,
        from,
        to,
        description,
      } = this.state;

      let body = {
        title,
        company,
        image,
        technologies,
        current,
        link,
        from,
        to,
        description: description.toString("html"),
      };
      //make user signup request

      try {
        await axios.put(
          Config.hostName + `/api/project/${this.props.project._id}`,
          body
        );
        this.props.setAlert("Updated", "danger");
        this.props.getProjects();
        console.log(this.props);
        this.setModal1Visible(false);
      } catch (err) {
        const errors =
          err.response && err.response.data && err.response.data.errors;

        if (errors) {
          errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }
    return (
      <>
        <Button type="link" onClick={() => this.setModal1Visible(true)}>
          <EditOutlined style={{ color: "blue" }} />
        </Button>
        <Modal
          name={"Update Service"}
          visible={this.state.modal1Visible}
          okText="Submit"
          onOk={(e) => {
            this.handleSubmit(e);
          }}
          onCancel={() => this.setModal1Visible(false)}
        >
          <form onSubmit={(e) => this.handleSubmit(e)}>
            <Alert />
            <FormGroup>
              <Label className="col-form-label">Image</Label>
              <div
                className="mt--5"
                style={{ cursor: "pointer", marginTop: -10 }}
              >
                <Dropzone
                  accept="image/*"
                  multiple={false}
                  onDrop={(acceptedFiles) => {
                    this.onDrop({
                      files: acceptedFiles,
                      name: "image",
                    });
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button
                          onClick={(e) => e.preventDefault()}
                          className="primary"
                        >
                          Upload
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {this.state.image && (
                  <a
                    style={{ color: "blue" }}
                    href={this.state.image}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Image
                  </a>
                )}
              </div>
            </FormGroup>

            <FormGroup>
              <Label className="col-form-label">Title</Label>
              <Input
                className="form-control"
                type="text"
                name="title"
                value={this.state.title}
                placeholder="Title"
                onChange={(event) =>
                  this.setState({
                    title: event.target.value,
                  })
                }
              />
              <div className="text-danger">
                {this.validator.message("title", this.state.title, "required")}
              </div>
            </FormGroup>

            <FormGroup>
              <Label className="col-form-label">Company</Label>
              <Input
                className="form-control"
                type="text"
                name="company"
                value={this.state.company}
                placeholder="Company"
                onChange={(event) =>
                  this.setState({
                    company: event.target.value,
                  })
                }
              />
              <div className="text-danger">
                {this.validator.message(
                  "company",
                  this.state.company,
                  "required"
                )}
              </div>
            </FormGroup>

            <FormGroup>
              <Label className="col-form-label">From</Label>
              <Input
                className="form-control"
                type="date"
                name="from"
                value={this.state.from}
                placeholder="yyyy/mm/dd"
                onChange={(event) =>
                  this.setState({ from: event.target.value })
                }
              />
              <div className="text-danger">
                {this.validator.message("from", this.state.from, "required")}
              </div>
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label">Skills</Label>
              <Select
                mode="tags"
                placeholder="Please select"
                defaultValue={this.state.technologies}
                // defaultValue={['node', 'react.js']}
                onChange={(values) => this.setState({ technologies: values })}
                style={{ width: "100%" }}
              >
                {Skills.map((i) => {
                  return <Option key={i}>{i}</Option>;
                })}
              </Select>
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label">To</Label>
              <Input
                className="form-control"
                type="date"
                name="to"
                disabled={this.state.current ? true : false}
                value={this.state.to}
                placeholder="yyyy/mm/dd"
                onChange={(event) => this.setState({ to: event.target.value })}
              />
              {/* <div className="text-danger">
                              {this.validator.message(
                                "to",
                                this.state.to,
                                "required"
                              )}
                            </div> */}
            </FormGroup>

            <FormGroup check>
              <Label check>
                <Input
                  id="Current"
                  type="checkbox"
                  checked={this.state.current}
                  onChange={(e) =>
                    this.setState({
                      current: !this.state.current,
                    })
                  }
                  name="current"
                />{" "}
                I am currently working on this project
                <span className="form-check-sign">
                  <span className="check"></span>
                </span>
              </Label>
            </FormGroup>

            <FormGroup>
              <Label className="col-form-label">Link</Label>
              <Input
                className="form-control"
                type="text"
                name="link"
                value={this.state.link}
                placeholder="Link"
                onChange={(event) =>
                  this.setState({
                    link: event.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label">Description</Label>
              <RichTextEditor
                value={this.state.description}
                editorClassName="editor-height"
                onChange={(value) => this.setState({ description: value })}
              />
            </FormGroup>
          </form>
        </Modal>
      </>
    );
  }
}

UpdateUser.propTypes = {
  updateUser: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setAlert,
  updateUser,
})(UpdateUser);
