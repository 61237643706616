import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { message } from "antd";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import Config from "../config";
import ClientList from "components/client-list";
import OurServicesPart from "views/OurServicesPart";
import OurCapabilities from "components/OurCapabilities";
import HomePageClientList from "components/client-list/HomePageClientList";
import { Helmet } from "react-helmet";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal1Visible: false,
      modal2Visible: false,
    };
    this.validator = new SimpleReactValidator();
  }

  handleSignupButtonClick = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let { name, phone, email, budget, requirements } = this.state;
      const data = {
        name,
        phone,
        budget,
        email,
        requirements,
      };

      console.log(data);

      try {
        let host = Config.hostName;

        await axios.post(host + "/api/contact/business", data);

        message.success("Your request has been sent successfully.");
        this.validator.visibleFields = [];

        this.setState({
          isUpdating: false,
          name: "",
          phone: "",
          alert: true,
          email: "",
          requirements: "",
          budget: "",
        });
      } catch (err) {
        var errors =
          err.response && err.response.data && err.response.data.errors;
        if (errors) {
          errors.map((error) => message.error(error.msg));
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>
            ZappLabs | Hire node.js developer | Hire react.js developer | Hire
            shopify developer | Hire shopify app developer | Hire android
            developer
          </title>
          <meta
            name="description"
            content="Develop custom software | ecommerce website development | html, css development | mobile app development | Hire node.js developer | Hire node.js developer | Hire react.js developer | Hire shopify developer | Hire shopify app developer | Hire android developer "
          />
        </Helmet>
        <section className="container">
          <div className="row ">
            <div className="col-md-6">
              <div
                style={{
                  paddingTop: "4vw",
                  paddingBottom: "8vw",
                }}
              >
                <h2 className="title header-title">
                  Startups, Ecommerce, Web Development and Mobile App
                  Development
                </h2>

                <h5 className="poppins header-subtext pt-4 pb-3">
                  Top-notch digital products from an app development company
                  with a focus on your online business profitability.
                </h5>
                <div className="mt-3">
                  <Link className="nav-link btn-rotate c-btn" to="/contact-us">
                    CONTACT US
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6  pb-5 d-none d-md-block">
              <img
                alt=""
                src={
                  "https://image.freepik.com/free-vector/programming-concept-illustration_114360-1325.jpg"
                }
                width="100%"
                className="rounded"
              />
            </div>
          </div>
          <HomePageClientList />
        </section>

        <OurServicesPart />

        <section className="">
          <div
            className="text-center "
            style={{
              paddingBottom: "8vw",
            }}
          >
            <div className="container">
              <div>
                <div className="text-left contact-us-inpage">
                  <h2 className="title header-title text-capitalize">
                    Contact us for software development work
                  </h2>
                  {/* <h5 className="header-subtext">
                    Set up a time to chat with one of our experts.
                  </h5> */}
                  <div className="row pt-3">
                    <div className="col-md-2 col-6">
                      <input
                        type="text"
                        placeholder="Name"
                        value={this.state.name}
                        onChange={(event) =>
                          this.setState({
                            name: event.target.value,
                          })
                        }
                        onFocus={(e) => this.validator.showMessageFor("name")}
                      />

                      <div className="text-danger">
                        {this.validator.message(
                          "name",
                          this.state.name,
                          "required"
                        )}
                      </div>
                    </div>

                    <div className="col-md-2 col-6">
                      <input
                        type="text"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={(event) =>
                          this.setState({
                            email: event.target.value,
                          })
                        }
                        onFocus={(e) => this.validator.showMessageFor("email")}
                      />

                      <div className="text-danger">
                        {this.validator.message(
                          "email",
                          this.state.email,
                          "required|email"
                        )}
                      </div>
                    </div>

                    <div className="col-md-2 col-6">
                      <input
                        type="text"
                        placeholder="Phone"
                        value={this.state.phone}
                        onChange={(event) =>
                          this.setState({
                            phone: event.target.value,
                          })
                        }
                        onFocus={(e) => this.validator.showMessageFor("phone")}
                      />

                      <div className="text-danger">
                        {this.validator.message(
                          "phone",
                          this.state.phone,
                          "required|phone"
                        )}
                      </div>
                    </div>

                    <div className="col-md-2 col-6">
                      <input
                        type="text"
                        placeholder="Budget"
                        value={this.state.budget}
                        onChange={(event) =>
                          this.setState({
                            budget: event.target.value,
                          })
                        }
                        onFocus={(e) => this.validator.showMessageFor("budget")}
                      />

                      <div className="text-danger">
                        {this.validator.message(
                          "budget",
                          this.state.budget,
                          "required|integer"
                        )}
                      </div>
                    </div>

                    <div className="col-md-4 col-12">
                      <input
                        type="text"
                        placeholder="Requirements"
                        value={this.state.requirements}
                        onChange={(event) =>
                          this.setState({
                            requirements: event.target.value,
                          })
                        }
                        onFocus={(e) =>
                          this.validator.showMessageFor("requirements")
                        }
                      />

                      <div className="text-danger">
                        {this.validator.message(
                          "requirements",
                          this.state.requirements,
                          "required"
                        )}
                      </div>
                    </div>

                    <div className="col-md-2 col-6  p-mb-0 pt-3">
                      <Link
                        className="nav-link btn-rotate c-btn"
                        onClick={(e) => {
                          this.handleSignupButtonClick(e);
                        }}
                      >
                        Submit
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurCapabilities />
      </div>
    );
  }
}

LandingPage.propTypes = { auth: PropTypes.object.isRequired };
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(LandingPage);
