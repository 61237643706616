import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { message } from "antd";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import Config from "../config";
import SweetAlert from "react-bootstrap-sweetalert";

class GetDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal1Visible: false,
      alert: null,
      modal2Visible: false,
    };
    this.validator = new SimpleReactValidator();
  }
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSignupButtonClick = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let { name, phone, budget, email, requirements } = this.state;
      const data = {
        name,
        phone,
        budget,
        email,
        requirements,
        from: this.props.location.pathname,
      };
      this.setState({ isUpdating: true });
      try {
        let host = Config.hostName;

        await axios.post(host + "/api/contact/business", data);

        this.validator.visibleFields = [];
        // <!-- Event snippet for Submit lead form conversion page -->
        window.gtag("event", "conversion", {
          send_to: "AW-633872257/0RwaCK6869IBEIG_oK4C",
        });
        this.setState({
          isUpdating: false,
          name: "",
          phone: "",
          alert: true,
          email: "",
          requirements: "",
          budget: "",
        });
      } catch (err) {
        console.log("-----------");
        console.log(err.response);
        console.log("-----------");

        this.setState({ isUpdating: false });

        var errors =
          err && err.response && err.response.data && err.response.data.errors;

        if (errors) {
          errors.map((error) => message.error(error.msg));
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <>
        {this.state.alert && (
          <SweetAlert
            success
            title="Success!"
            onCancel={() =>
              this.setState({
                alert: null,
              })
            }
            customButtons={
              <React.Fragment>
                <button
                  className="c-btn "
                  onClick={() =>
                    this.setState({
                      alert: null,
                    })
                  }
                  style={{ outline: "none" }}
                >
                  OK
                </button>
              </React.Fragment>
            }
          >
            Your request has been sent successfully. We will contact you
            shortly.
          </SweetAlert>
        )}
        <div className="type-of-work-box">
          <h4 className="work-box-title">Schedule a Demo</h4>

          <form className="comp form">
            <div>
              <input
                type="text"
                placeholder="Name"
                value={this.state.name}
                onChange={(event) =>
                  this.setState({
                    name: event.target.value,
                  })
                }
                onFocus={(e) => this.validator.showMessageFor("name")}
              />

              <div className="text-danger">
                {this.validator.message("name", this.state.name, "required")}
              </div>

              <input
                type="text"
                placeholder="Email"
                value={this.state.email}
                onChange={(event) =>
                  this.setState({
                    email: event.target.value,
                  })
                }
                onFocus={(e) => this.validator.showMessageFor("email")}
              />

              <div className="text-danger">
                {this.validator.message(
                  "email",
                  this.state.email,
                  "required|email"
                )}
              </div>

              <input
                type="text"
                placeholder="Phone"
                value={this.state.phone}
                onChange={(event) =>
                  this.setState({
                    phone: event.target.value,
                  })
                }
                onFocus={(e) => this.validator.showMessageFor("phone")}
              />

              <div className="text-danger">
                {this.validator.message(
                  "phone",
                  this.state.phone,
                  "required|phone"
                )}
              </div>

              <input
                type="text"
                placeholder="Your budget"
                value={this.state.budget}
                onChange={(event) =>
                  this.setState({
                    budget: event.target.value,
                  })
                }
                onFocus={(e) => this.validator.showMessageFor("budget")}
              />

              <div className="text-danger">
                {this.validator.message(
                  "budget",
                  this.state.budget,
                  "required|integer"
                )}
              </div>

              <input
                type="text"
                placeholder="Requirements"
                value={this.state.requirements}
                onChange={(event) =>
                  this.setState({
                    requirements: event.target.value,
                  })
                }
                onFocus={(e) => this.validator.showMessageFor("requirements")}
              />

              <div className="text-danger">
                {this.validator.message(
                  "requirements",
                  this.state.requirements,
                  "required"
                )}
              </div>
            </div>

            <div className="text-center pt-3">
              <button
                className="c-btn "
                onClick={(e) => {
                  this.handleSignupButtonClick(e);
                }}
                style={{ outline: "none" }}
                disabled={this.state.isUpdating}
              >
                {this.state.isUpdating ? "loading" : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
}

GetDemo.propTypes = { auth: PropTypes.object.isRequired };
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(GetDemo);
