import React from 'react';

class ClientList extends React.Component {
  render() {
    return (
      <>
        <section className="pb-5 mb-5  container">
          {/* <p className="pt-2 title header-subtext">Our pricing</p> */}
          <h2 className="title header-title text-center">Our pricing</h2>
          <div className="row">
            <div className="col-md-6 ">
              <div className="type-of-work-box">
                <h4 className="work-box-title">Per hour</h4>
                <h5 className="work-box-desc">
                  For per hour project we charge INR 750 per hour
                  <br />
                  <br />
                </h5>
              </div>
            </div>
            <div className="col-md-6">
              <div className="type-of-work-box">
                <h4 className="work-box-title">Fixed</h4>
                <h5 className="work-box-desc">
                  For fixed project we can discuss about the project
                  functionalities and estimate a fixed cost of the project
                </h5>
              </div>
            </div>
          </div>
          <h5 className="work-box-desc pt-2">
            <strong>
              * Third Party Fees & Charges for additional services are
              exclusive.
            </strong>
          </h5>
        </section>
      </>
    );
  }
}

export default ClientList;
