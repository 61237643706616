import React from 'react';
import { Helmet } from 'react-helmet';

class ClientList extends React.Component {
  render() {
    const faqs = [
      {
        question: 'How do you engage with other companies?',
        answer:
          'We work with other companies on per project basis or full time basis. We can provide developer who can work with you on full time basis. In terms of price we charge INR 750 per hour. Please contact us if you want to discuss anything else, we will be happy to address those',
      },
      {
        question: 'How do you charge?',
        answer: 'We charge INR 750 per hour',
      },
      {
        question: 'Can you work full time with us?',
        answer: 'We can work with you on full time basis remotely.',
      },
    ];
    return (
      <>
        <section className="container">
          <div className="faq">Frequently Asked Questions</div>
          <p className="work-box-desc text-center">
            Got a question? We've got answers. If you have some other questions,
            call us at +91 9538822290 or send email to suvojit@zapplabs.in
          </p>
          <div className="row">
            {faqs.map((faq, index) => (
              <div className="col-md-12" key={index}>
                <div className="faq-question">{faq.question}</div>
                <div className="faq-answer">{faq.answer}</div>
              </div>
            ))}
          </div>
        </section>
      </>
    );
  }
}

export default ClientList;
