export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_BASIC_DETAILS = "GET_BASIC_DETAILS";
export const GET_ACADEMIC_DETAILS = "GET_ACADEMIC_DETAILS";
export const DETAILS_ERROR = "DETAILS_ERROR";
export const CLEAR_DETAILS = "CLEAR_DETAILS";
export const CLEAR_ALERTS = "CLEAR_ALERTS";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const GET_REPOS = "GET_REPOS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const CLEAR_PROFILES = "CLEAR_PROFILES";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const LOADING = "LOADING";
export const GET_SUCCESSFUL_TRANSACTIONS = "GET_SUCCESSFUL_TRANSACTIONS";
export const TRANSACTION_ERROR = "TRANSACTION_ERROR";
export const UPDATE_USER = "UPDATE_USER";
export const REMOVE_USER = "REMOVE_USER";
export const GET_USERS = "GET_USERS";