import React from "react";
import { Avatar } from "antd";
import SimpleReactValidator from "simple-react-validator";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card } from "antd";
import { Link } from "react-router-dom";
import Slider from "react-slick";

class Apply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal1Visible: false,
      modal2Visible: false,
    };
    this.validator = new SimpleReactValidator();
  }

  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }
  render() {
    var service = this.props.service;
    var settings = {
      inifinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
    };

    return (
      <div className="pt-2">
        <Link to={`/service/${service._id}`}>
          <Card
            component={Link}
            onClick={() => this.setModal1Visible(true)}
            hoverable
            cover={
              service.image && service.image.length === 0 ? (
                <img
                  alt="example"
                  className="rounded-corner"
                  style={{ width: "100%" }}
                  src={
                    "https://image.freepik.com/free-vector/city-architecture-apartment-building-block-with-tower-skyscraper-linear-sketch-vector-illustration_98292-1035.jpg"
                  }
                />
              ) : (
                <Slider {...settings}>
                  {service.image &&
                    service.image.map((i) => (
                      <img
                        alt="example"
                        className="rounded-corner"
                        style={{ maxHeight: "10px" }}
                        src={i}
                      />
                    ))}
                </Slider>
              )
            }
          >
            <div className="text-capitalize">
              <Avatar
                style={{ backgroundColor: "blue", verticalAlign: "middle" }}
                size="medium"
              >
                {service.user.name && service.user.name[0]}
              </Avatar>{" "}
              {service.user.name}{" "}
              {this.props.auth.user &&
                this.props.auth.user.id === service.user._id && (
                  <Link
                    style={{ color: "blue" }}
                    onClick={async (e) => {
                      await this.props.handleDelete(service._id);
                    }}
                  >
                    <i className="fa fa-remove" /> Delete
                  </Link>
                )}
            </div>
            <div className="h6">{service.title}</div>
            <hr className="m-0 p-0" />
            <div className="p text-right pt-2">
              Starting At Rs {service.serviceInfo.price.toLocaleString()}
            </div>
          </Card>
        </Link>
      </div>
    );
  }
}
Apply.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
});

export default connect(mapStateToProps, {})(Apply);
