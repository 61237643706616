import React from "react";

import { Card, Avatar } from "antd";

const { Meta } = Card;
class ConfirmEmail extends React.Component {
  render() {
    return (
      <div className="row">
        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(m => (
          <div className="col-md-4 col-sm-6">
            <Card style={{ width: "100%", marginTop: 16 }} loading={true}>
              <Meta
                avatar={
                  <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                }
                title="Card title"
                description="This is the description"
              />
            </Card>
          </div>
        ))}
      </div>
    );
  }
}

export default ConfirmEmail;
