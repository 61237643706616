import React from "react";
import { Avatar } from "antd";
import SimpleReactValidator from "simple-react-validator";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import Spinner from "../../layouts/Spinner";
import { Tag, Card, Affix } from "antd";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import Config from "../../config";
import { getSuccessfulTransactions } from "../../actions/user.actions";
import { ClipLoader } from "react-spinners";
import Apply from "../../components/profiles/Apply";
import UpdateService from "../../components/profile/UpdateService";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      service: "",
      loading: true,
    };
    this.validator = new SimpleReactValidator();
  }

  getService = async () => {
    try {
      const res = await axios.get(
        Config.hostName + "/api/service/" + this.props.match.params.id
      );
      console.log(res.data);
      this.setState({ loading: false, service: res.data });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  };

  componentWillMount() {
    this.props.getSuccessfulTransactions();
    this.getService();
  }

  paymentHandler = async (e) => {
    e.preventDefault();
    this.setState({ isPaying: true });
    var that = this;
    if (this.props.auth.user === null)
      return this.props.history.push({
        pathname: "/user/login",
        from: "pay for service",
      });
    const id = this.props.match.params.id;
    const description = "paid for service " + this.state.service.title;

    let result = await axios.get(
      `${Config.hostName}/api/payment/create-order/` + id
    );

    this.setState({ isPaying: false });

    // get the data from the result
    let data = result.data;

    // get the order_id from the data
    let { order_id, name } = data;

    // get the payment amount, product_id and description of the product
    const payment_amount = this.state.service.serviceInfo.price * 100;

    console.log("razorPayKey", Config.razorPayKey);
    const options = {
      key: Config.razorPayKey,
      amount: payment_amount,
      name: name,
      description: description,
      order_id: `${order_id}`,

      handler(response) {
        console.log(response);
        // get the payment_id, order_id and signature from the response
        let {
          razorpay_payment_id,
          razorpay_order_id,
          razorpay_signature,
        } = response;

        // construct the url
        const url = `${Config.hostName}/api/payment/razorapi/verify`;

        // Using my server endpoints to capture the payment
        axios
          .post(
            url,
            { razorpay_payment_id, razorpay_order_id, razorpay_signature },
            {
              headers: {
                "Content-type": "application/json",
              },
            }
          )
          .then(async (res) => {
            // clear the cart both from the frontend and the backend server
            console.log(res.data);
            await that.props.getSuccessfulTransactions();
            // window.location.reload();

            // now redirect the user to the home page
          })
          .catch(function (error) {
            console.log(error.response);
          });
      },
      prefill: {
        name: this.props.auth.user.name,
        email: this.props.auth.user.email,
      },
      notes: {
        address: "India",
      },
      theme: {
        color: "#2F3959",
      },
    };
    const rzp1 = new window.Razorpay(options);

    rzp1.open();
  };
  isRegisteredCourse(id) {
    if (this.props.auth.successfultransactions)
      for (var i = 0; i < this.props.auth.successfultransactions.length; i++) {
        if (this.props.auth.successfultransactions[i].service._id === id)
          return true;
      }

    return false;
  }

  render() {
    if (this.state.loading) return <Spinner />;
    var service = this.state.service;
    var settings = {
      inifinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
    };

    var title = "Browse service - " + service.title;
    var url = window.location.href;
    var size = "2rem";

    return (
      <div className="row jumbotron bg-white">
        <div className="col-md-8">
          <div className="title h5 m-0 p-0">{service.title}</div>

          <div className="row">
            <div className="col-md-6">
              {service.user && (
                <Link to={`/dev-profile/${service.user._id}`}>
                  <div className="text-capitalize pt-2 pb-0">
                    <Avatar
                      style={{
                        backgroundColor: "blue",
                        verticalAlign: "middle",
                      }}
                      size="medium"
                    >
                      {service.user.name && service.user.name[0]}
                    </Avatar>{" "}
                    {service.user.name}
                  </div>
                </Link>
              )}
            </div>
            <div className="col-md-6 text-right">
              {service.user._id ===
                (this.props.auth.user && this.props.auth.user.id) && (
                <div class="d-inline-block">
                  {" "}
                  <UpdateService
                    service={service}
                    getService={this.getService}
                  />
                </div>
              )}
              <div class="d-inline-block">
                <TwitterShareButton
                  className="network__share-button"
                  url={url}
                  title={title}
                >
                  <TwitterIcon size={size} />
                </TwitterShareButton>
              </div>
              <div class="d-inline-block pl-1">
                <FacebookShareButton
                  className="network__share-button"
                  url={url}
                  quote={title}
                >
                  <FacebookIcon size={size} />
                </FacebookShareButton>
              </div>
              <div class="d-inline-block pl-1">
                <WhatsappShareButton
                  className="network__share-button"
                  url={url}
                  quote={title}
                >
                  <WhatsappIcon size={size} />
                </WhatsappShareButton>
              </div>
            </div>
          </div>

          <div className="pt-2">
            {service.image.length === 0 ? (
              <img
                alt="example"
                className="rounded-corner"
                style={{ width: "100%" }}
                src={
                  "https://image.freepik.com/free-vector/city-architecture-apartment-building-block-with-tower-skyscraper-linear-sketch-vector-illustration_98292-1035.jpg"
                }
              />
            ) : (
              <Slider {...settings}>
                {service.image.map((i) => (
                  <img
                    alt="example"
                    className="rounded-corner"
                    width="100%"
                    src={i}
                  />
                ))}
              </Slider>
            )}
          </div>
          <Row className="mt-3">
            {service.technologies.length > 0 && (
              <Col xs="12">
                Tags :{" "}
                {service.technologies.map((i) => {
                  return (
                    <Tag
                      onClick={() =>
                        this.props.history.push({
                          pathname: "/services",
                          tag: i,
                        })
                      }
                    >
                      {i}
                      {"  "}
                    </Tag>
                  );
                })}
              </Col>
            )}
          </Row>
          <div className="h5 title pt-3 pb-3">About this Gig</div>

          <div
            className="text-secondary mt-2"
            dangerouslySetInnerHTML={{ __html: service.description }}
          />
        </div>
        <div className="col-md-4">
          <Affix offsetTop={90}>
            <Card hoverable>
              <div
                className="text-secondary mt-2"
                dangerouslySetInnerHTML={{
                  __html: service.serviceInfo.description,
                }}
              />
              <hr className="m-0 p-0" />
              {service.user._id ===
              (this.props.auth.user && this.props.auth.user.id) ? (
                <Button style={{ width: "100%" }} color="success">
                  Rs. {service.serviceInfo.price.toLocaleString()}
                </Button>
              ) : !this.isRegisteredCourse(this.props.match.params.id) ? (
                <Button
                  style={{ width: "100%" }}
                  color="primary"
                  disabled={this.state.isPaying}
                  onClick={(e) => this.paymentHandler(e)}
                >
                  {this.state.isPaying ? (
                    <span>
                      <ClipLoader size="20" color={"white"} /> Loading
                    </span>
                  ) : (
                    <span>
                      Pay Rs. {service.serviceInfo.price.toLocaleString()}
                    </span>
                  )}
                </Button>
              ) : (
                <Button style={{ width: "100%" }} color="success">
                  Paid Rs. {service.serviceInfo.price.toLocaleString()}
                </Button>
              )}
            </Card>
            <section className="border mb-3 bg-white rounded p-3 text-left">
              <Apply
                profileData={{
                  user: { name: service.user.name, _id: service.user._id },
                }}
                key={service.user._id}
                history={this.props.history}
              />
            </section>
          </Affix>
        </div>
      </div>
    );
  }
}

Service.propTypes = {
  getSuccessfulTransactions: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
});

export default connect(mapStateToProps, { getSuccessfulTransactions })(Service);
