import React from "react";
import PropTypes from "prop-types";
import { createProfile, getCurrentProfile } from "../../actions/profile";
import Config from "../../config";
import axios from "axios";
import { connect } from "react-redux";
import Spinner from "../../layouts/Spinner";
import Profile from "../../components/profile/Basic";
import Experience from "../../components/profile/Experience";
import Education from "../../components/profile/Education";
import About from "../../components/profile/About";
import Project from "../../components/profile/Project";
import Skills from "../../components/profile/skills";
import Certificate from "../../components/profile/certificate";
import DesignerProjects from "../../components/profile/designerProjects";
class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      profile: "",
    };
  }
  getProfile = async () => {
    try {
      const res = await axios.get(
        Config.hostName + `/api/profile/${this.props.match.params.userId}`
      );
      console.log("profile", res.data);

      this.setState({ profile: res.data, isLoading: false });
    } catch (err) {
      console.log(err);
      this.setState({ profile: null, isLoading: false });
    }
  };
  async componentWillMount() {
    if (!this.props.auth.user) await this.props.loadUser();
    this.getProfile();
    // this.props.getCurrentProfile(this.props.match.params.userId);
  }

  render() {
    return (
      <div className="jumbotron  mb-0">
        <div className="container">
          {this.state.isLoading ? (
            <Spinner />
          ) : (
            <div>
              {this.state.profile ? (
                <>
                  <Profile
                    profile={this.state.profile}
                    getProfile={this.getProfile}
                    userid={this.props.match.params.userId}
                    key="profile"
                  />
                  <About
                    profile={this.state.profile}
                    getProfile={this.getProfile}
                    key="about"
                    userid={this.props.match.params.userId}
                  />
                  <Experience
                    profile={this.state.profile}
                    getProfile={this.getProfile}
                    key="Experience"
                    userid={this.props.match.params.userId}
                  />
                  <Project
                    userId={this.props.match.params.userId}
                    profile={this.state.profile}
                    getProfile={this.getProfile}
                    key="Project"
                  />
                  <DesignerProjects
                    userId={this.props.match.params.userId}
                    profile={this.state.profile}
                    getProfile={this.getProfile}
                    key="DesignerProjects"
                  />

                  <Education
                    profile={this.state.profile}
                    getProfile={this.getProfile}
                    key="Education"
                    userid={this.props.match.params.userId}
                  />
                  <Skills
                    profile={this.state.profile}
                    getProfile={this.getProfile}
                    key="Skills"
                  />
                  <Certificate
                    profile={this.state.profile}
                    getProfile={this.getProfile}
                    key="Skills"
                    userid={this.props.match.params.userId}
                  />
                  {/* <Demo /> */}
                </>
              ) : (
                <Profile
                  profile={null}
                  getProfile={this.getProfile}
                  userid={this.props.match.params.userId}
                  key="no profile"
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

index.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { createProfile, getCurrentProfile })(
  index
);
