import React from "react";
import { Tag } from "antd";
import Apply from "./Apply";
import { Link } from "react-router-dom";

class Job extends React.Component {
  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }
  render() {
    var profile = this.props.profile;
    return (
      <section className="border mb-3 bg-white rounded p-3">
        <div className="m-0 p-0">
          <div className="row mb-4">
            <div className="col-sm-2 col-xs-3 col-md-2 ">
              <a
                href="#pablo"
                className="text-center"
                onClick={(e) => e.preventDefault()}
              >
                <img
                  alt="..."
                  clhomeassName="img-thumbnail  border-gray"
                  style={{
                    minWidth: 50,
                    maxWidth: 80,
                  }}
                  src={
                    profile.avatar
                      ? profile.avatar
                      : "/img/landing-page/avatar.jpg"
                  }
                />
              </a>
            </div>
            <div className="col-sm-10 col-xs-9 col-md-10 ">
              <div className="row">
                <div className="col-md-9 text-dark text-capitalize">
                  <Link to={"/dev-profile/" + profile.user._id}>
                    <span className="title h6 m-0 p-0">
                      {" "}
                      {profile.user.name}
                    </span>
                  </Link>
                </div>
                <div className="col-md-3 text-right">
                  <Apply
                    profileData={profile}
                    key={profile.id}
                    history={this.props.history}
                  />
                </div>
              </div>

              <div
                className="text-secondary"
                style={{ maxHeight: "140px", overflow: "hidden" }}
                dangerouslySetInnerHTML={{
                  __html: profile.bio,
                }}
              />
              {profile.skillsData && profile.skillsData.skill && (
                <div>
                  Skills :{" "}
                  <span className="text-capitalize">
                    {profile.skillsData.skill.map((skill) => (
                      <Tag>
                        {skill}
                        {"  "}
                      </Tag>
                    ))}
                  </span>
                </div>
              )}

              {/* <div className="col-6 text-secondary">
                Posted :
                <TimeAgo date={new Date(profile.createdAt)}>
                  {({ value }) => <>{value}</>}
                </TimeAgo>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Job;
