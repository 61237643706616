import React from "react";
import { Helmet } from "react-helmet";
import PortFolioComponent from "./PortfolioComponent";
class ClientList extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>ZappLabs | Port Folio Projects</title>
          <meta name="description" content="Pricing" />
        </Helmet>

        <PortFolioComponent key="fdsf" />
      </>
    );
  }
}

export default ClientList;
