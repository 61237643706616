import React from "react";

class ContactUs extends React.Component {
  render() {
    return (
      <section className="container pt-2">
        <div className="row contact-wrap bg-white ">
          <div className="col-md-6">
            <div className="row">
              <div className="col-2">
                <img
                  alt=""
                  width="100%"
                  className="filter-pink"
                  src="img/work-types/mail.svg"
                />
              </div>
              <div className="col-9 ">
                <a href="mailto:suvojit@zapplabs.in" className="header-subtext">
                  suvojit@zapplabs.in
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 pt-md-0 pt-3">
            <div className="row">
              <div className="col-2">
                <img
                  alt=""
                  width="100%"
                  className="filter-pink"
                  src="img/work-types/phone.svg"
                />
              </div>
              <div className="col-9">
                <a href="tel:+919538822290" className="header-subtext ">+91 95388 22290</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactUs;
