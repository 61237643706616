import React from "react";
import { Helmet } from "react-helmet";

const My404Component = () => {
  return (
    <div className="container">
      <Helmet>
        <title>Zapplabs | 404 </title>
        <meta name="description" content="404 | Not Found" />
      </Helmet>
      <section className="App-header">
        <div className="text-center">
          <img
            src="https://cdn.dribbble.com/users/1408464/screenshots/6377404/404_illustration_2x.png"
            width="400px"
            alt=""
          />
          <h2 className="title header-title">Page not found</h2>
          {/* <div className="heroContent__subText">
            Zapplabs helps you uncover deep customer insights with industry
            first features.
          </div>{" "} */}
          <a href="/" className="c-btn mt-3">
            Explore More<span className="btn-overlay"></span>
          </a>{" "}
        </div>
      </section>
    </div>
  );
};
export default My404Component;
