const routes = [
  {
    path: '/course-app',
    title: 'Mobile course app development monthly charges',
  },
  {
    path: '/ecommerce',
    title: 'Ecommerce Development Monthly Charges',
  },
  { path: '/app-developer-in-kolkata', title: 'App Developer In Kolkata' },
  { path: '/shopify-designer', title: 'Shopify Designer' },
  {
    path: '/hire-custom-app-developer',
    title: 'Hire Custom App Developer',
  },
  { path: '/get-best-app-development', title: 'Get Best App Development' },
  { path: '/app-developer', title: 'App Developer' },
  {
    path: '/react-native-development-company-in-india',
    title: 'React Native Development Company India',
  },
  {
    path: '/hire-freelance-web-developer',
    title: 'Hire Freelance Web Developer',
  },
  {
    path: '/freelance-web-developer-sites',
    title: 'Freelance Web Developer Sites',
  },
  {
    path: '/how-can-i-find-web-developer',
    title: 'How Can I Find A Web Developer',
  },
  { path: '/byjus-app-freelancer', title: "Byju's App Freelancer" },
  { path: '/developers-for-hire', title: 'Developers For Hire' },
  { path: '/ecommerce-freelancer', title: 'Ecommerce Freelancer' },
  { path: '/freelance-php-developer', title: 'Freelance PHP Developer' },
  {
    path: '/hire-freelance-web-developer-websites',
    title: 'Hire Freelance Web Developer Website',
  },
  {
    path: '/freelance-software-developer-hari-nagar-delhi',
    title: 'Freelance Software Developer Hari Nagar Delhi',
  },
  { path: '/hire-web-developer', title: 'Hire Web Developer' },
  { path: '/hire-python-freelancer', title: 'Hire Python Freelancer' },
  { path: '/hire-php-freelancer', title: 'Hire PHP Freelancer' },
  { path: '/hire-nodejs-freelancer', title: 'Hire NodeJS Freelancer' },
  { path: '/hire-reactjs-freelancer', title: 'Hire ReactJS Freelancer' },
  { path: '/hire-android-freelancer', title: 'Hire Android Freelancer' },
  { path: '/hire-html-freelancer', title: 'Hire HTML Freelancer' },
  {
    path: '/hire-python-freelancer-in-mumbai',
    title: 'Hire Python Freelancer In Mumbai',
  },
  { path: '/hire-ui-freelancer', title: 'UI Freelancer' },
  { path: '/hire-reactjs-developers', title: 'Hire React Developers' },
];

export default routes;
