import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import routes from "./routes";
import OurCapabilities from "components/OurCapabilities";
import OurServicesPart from "./OurServicesPart";
class LandingPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>ZappLabs | All Services</title>
          <meta
            name="keywords"
            content="Hire node.js developer | Hire react.js developer | Hire
            shopify developer | Hire shopify app developer | Hire android
            developer | Develop custom software | ecommerce website development | html, css development | mobile app development | Hire node.js developer | Hire node.js developer | Hire react.js developer | Hire shopify developer | Hire shopify app developer | Hire android developer "
          />
        </Helmet>
        <section className="container">
          <div className="row ">
            <div className="col-md-6">
              <div
                style={{
                  paddingTop: "4vw",
                  paddingBottom: "8vw",
                }}
              >
                <h2 className="title header-title">
                  Startups, Ecommerce, Web Development and Mobile App
                  Development
                </h2>

                <h5 className="poppins header-subtext pt-4 pb-3">
                  Top-notch digital products from an app development company
                  with a focus on your online business profitability.
                </h5>
                <div className="mt-3">
                  <Link className="nav-link btn-rotate c-btn" to="/contact-us">
                    CONTACT US
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6  pb-5 d-none d-md-block pt-5">
              <img
                alt=""
                src={"img/landing-page/services.jpg"}
                width="100%"
                className="rounded"
              />
            </div>
          </div>
        </section>

        <OurServicesPart />
        <OurCapabilities />
      </div>
    );
  }
}

LandingPage.propTypes = { auth: PropTypes.object.isRequired };

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(LandingPage);
