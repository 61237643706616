import uuid from "uuid";
import {
  SET_ALERT,
  REMOVE_ALERT,
  CLEAR_ALERTS
} from "../constants/actionTypes";

export const setAlert = (msg, alertType, timeout = 5000) => dispatch => {
  const id = uuid.v4();
  dispatch({
    type: SET_ALERT,
    payload: { msg, alertType, id }
  });

  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};

export const clearAlert = () => dispatch => {
  dispatch({ type: CLEAR_ALERTS });
};
