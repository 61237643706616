import React from "react";
import SimpleReactValidator from "simple-react-validator";
import Config from "../../config";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import Dropzone from "react-dropzone";
import axios from "axios";
import { setAlert } from "../../actions/alert.actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Alert from "../../layouts/Alert";
import Spinner from "../../layouts/Spinner";
import Project from "../../components/project/Service";
import { Skills } from "../../constants/vars";
import { Select } from "antd";
import RichTextEditor from "react-rte";

const { Option } = Select;
class DesignerProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      title: "",
      company: "",
      image: "",
      current: false,
      from: "",
      link: "",
      to: "",
      designerProject: [],
      description: RichTextEditor.createEmptyValue(),
      serviceInfoDescription: RichTextEditor.createEmptyValue(),
      isEditable: false,
      isUpdating: false,
      isLoading: true,
    };
    this.validator = new SimpleReactValidator();
  }

  getServices = async () => {
    try {
      const res = await axios.get(Config.hostName + "/api/service/user");
      console.log(res.data);
      if (res.data.length === 0) this.setState({ isEditable: true });

      this.setState({ isLoading: false, services: res.data });
    } catch (err) {
      console.log(err);
      this.setState({ isLoading: false });
    }
  };

  handleDelete = async (id) => {
    try {
      const res = await axios.delete(Config.hostName + `/api/service/${id}`);
      console.log("project", res.data);
      var services = this.state.services.filter(
        (service) => service._id !== id
      );

      if (res.data.length === 0) this.setState({ isEditable: true });

      this.setState({ isLoading: false, services: services });
    } catch (err) {
      console.log(err);
      this.setState({ profile: null, isLoading: false });
    }
  };
  componentWillMount() {
    this.getServices();
  }

  async onDrop({ files, name }) {
    var image = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      let formData = new FormData();
      formData.append("images", file);

      await axios
        .post(Config.hostName + "/api/profile/images", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (res) => {
          image[i] = res.data;
          console.log(res.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    this.setState({ image: image });
  }

  async handleExperienceSubmitButtonClick(e) {
    e.preventDefault();
    console.log("handleExperienceSubmitButtonClick");
    if (this.validator.allValid()) {
      // get form data from react state object
      let {
        title,
        image,
        technologies,
        link,
        price,
        deliveryTime,
        allowedRevisions,
        description,

        serviceInfoDescription,
      } = this.state;

      let body = {
        user: this.props.auth.user && this.props.auth.user.id,
        title,
        image,
        technologies,
        link,
        price,
        deliveryTime,
        allowedRevisions,
        description: description.toString("html"),
        serviceInfoDescription: serviceInfoDescription.toString("html"),
      };
      //make user signup request
      await this.setState({ isUpdating: true });

      try {
        const res = await axios.post(Config.hostName + "/api/service", body);
        this.getServices();
        console.log(res.data);
        this.setState({
          designerProject: res.data.designerProject,
          isUpdating: false,
          isEditable: false,
        });
      } catch (err) {
        const errors =
          err.response && err.response.data && err.response.data.errors;
        await this.setState({ isUpdating: false });

        if (errors) {
          errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }
    return (
      <div className="jumbotron mb-0">
        <div className="container">
          <div className="p-3 container-fluid">
            {this.state.isEditable === false && (
              <Row>
                <Col xs="5">Services</Col>
                <Col xs="7" className="text-right">
                  {this.props.auth.user && this.props.auth.user.id && (
                    <Link
                      style={{ color: "blue" }}
                      onClick={async (e) =>
                        await this.setState({ isEditable: true })
                      }
                    >
                      <i className="fa fa-edit" /> Add new Service
                    </Link>
                  )}
                </Col>
              </Row>
            )}
          </div>
          <Row>
            {!this.state.isEditable && (
              <Col md="12">
                <div className="p-4">
                  <div className="row">
                    {this.state.services.map((service) => (
                      <div className="col-md-4 col-sm-6 col-xs-12">
                        <Project
                          service={service}
                          handleDelete={this.handleDelete}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            )}
            {this.state.isEditable && (
              <Col md="12">
                <Card className="card-user">
                  <CardHeader>
                    <Row>
                      <Col xs="7">
                        <CardTitle tag="h5">Add Service</CardTitle>
                      </Col>
                      <Col xs="5" className="text-right">
                        <Button
                          className="btn primary"
                          onClick={async (e) =>
                            await this.setState({ isEditable: false })
                          }
                        >
                          CANCEL
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <form
                      onSubmit={(e) =>
                        this.handleExperienceSubmitButtonClick(e)
                      }
                    >
                      <Alert />
                      <div className="row">
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label">Image</Label>
                            <div
                              className="mt--5"
                              style={{ cursor: "pointer", marginTop: -10 }}
                            >
                              <Dropzone
                                accept="image/*"
                                // multiple={false}
                                onDrop={(acceptedFiles) => {
                                  this.onDrop({
                                    files: acceptedFiles,
                                    name: "image",
                                  });
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section>
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <Button
                                        onClick={(e) => e.preventDefault()}
                                        className="primary"
                                      >
                                        Upload Images
                                      </Button>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                              {this.state.image &&
                                this.state.image.map((i) => (
                                  <a
                                    style={{ color: "blue" }}
                                    href={i}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    Image
                                  </a>
                                ))}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Title</Label>
                            <Input
                              className="form-control"
                              type="text"
                              name="title"
                              value={this.state.title}
                              placeholder="Title"
                              onChange={(event) =>
                                this.setState({
                                  title: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "title",
                                this.state.title,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Tags</Label>
                            <Select
                              mode="tags"
                              placeholder="Please select"
                              onChange={(values) =>
                                this.setState({ technologies: values })
                              }
                              style={{ width: "100%" }}
                            >
                              {Skills.map((i) => {
                                return <Option key={i}>{i}</Option>;
                              })}
                            </Select>
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">
                              Description
                            </Label>
                            <RichTextEditor
                              value={this.state.description}
                              editorClassName="editor-height"
                              onChange={(value) =>
                                this.setState({ description: value })
                              }
                            />
                          </FormGroup>
                        </div>
                        <div className="border p-2 col-md-6">
                          <div className="h6 title pt-2 pb-2">
                            Service Information
                          </div>
                          <FormGroup>
                            <Label className="col-form-label">Price</Label>
                            <Input
                              className="form-control"
                              type="text"
                              name="price"
                              value={this.state.price}
                              placeholder="Price"
                              onChange={(event) =>
                                this.setState({
                                  price: event.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "price",
                                this.state.price,
                                "required|integer"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label className="col-form-label">
                              Description
                            </Label>
                            <RichTextEditor
                              value={this.state.serviceInfoDescription}
                              editorClassName="editor-height"
                              onChange={(value) =>
                                this.setState({
                                  serviceInfoDescription: value,
                                })
                              }
                            />
                          </FormGroup>
                        </div>
                      </div>

                      <Row>
                        <div className="update ml-auto mr-auto">
                          <Button className="primary" type="submit">
                            {this.state.isUpdating ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </div>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      </div>
    );
  }
}

DesignerProjects.propTypes = {
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setAlert,
})(DesignerProjects);
